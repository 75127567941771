import {Injectable, NgZone} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import Swal from 'sweetalert2/src/sweetalert2.js';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    private _unsubscribeFromProfile = new Subject();

    public userProfileObservable: any;
    public userProfileSnapshot: any = null;

    public userAccessSnapshot: any;

    // This tracks whether the user profile snapshot is ready for use with a boolean
    public userProfileReady = new BehaviorSubject<boolean>(this.userProfileSnapshot != null);
    public userAccessReady = new BehaviorSubject<boolean>(this.userProfileSnapshot != null);

    constructor(
        public afs: AngularFirestore,   // Inject Firestore service
        // tslint:disable-next-line:no-shadowed-variable
        public auth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        public firestore: AngularFirestore,
        public ngZone: NgZone // NgZone service to remove outside scope warning
    ) {
        const self = this;
        this._unsubscribeFromProfile = new Subject();
        /* Saving user data in localstorage when
        logged in and setting up null when logged out */
        this.auth.authState.subscribe(user => {
            if (user) {
                firestore.doc<any>('users/' + user.uid).valueChanges()
                    .pipe(takeUntil(this._unsubscribeFromProfile))
                    .subscribe((userAccess) => {
                        if (userAccess) {
                            self.userAccessSnapshot = userAccess;
                            self.userAccessReady.next(true);
                            localStorage.setItem('user', JSON.stringify(self.userAccessSnapshot));
                        } else {
                            self.userAccessReady.next(false);
                        }
                    });

                this.userProfileObservable = firestore.doc<any>('profiles/' + user.uid).valueChanges();
                this.userProfileObservable
                    .pipe(takeUntil(this._unsubscribeFromProfile))
                    .subscribe((profileSnapshot) => {
                        if (profileSnapshot && profileSnapshot.uid) {
                            self.userProfileSnapshot = profileSnapshot;
                            self.userProfileReady.next(true);
                        } else {
                            self.userProfileReady.next(false);
                        }
                    });
            } else {
                localStorage.setItem('user', null);
                this._unsubscribeFromProfile.next();
                this._unsubscribeFromProfile.complete();
                this._unsubscribeFromProfile = new Subject();
                self.userProfileReady.next(false);
                self.userAccessReady.next(false);
                self.userAccessSnapshot = null;
                self.userProfileSnapshot = null;
            }
        });
    }

    isUserProfileReady(): Observable<boolean> {
        return this.userProfileReady.asObservable();
    }

    isUserAccessReady(): Observable<boolean> {
        return this.userAccessReady.asObservable();
    }

    showError(message): void {
        Swal.fire('Oops...', message, 'error');
    }

    // Sign in with email/password
    login(email, password): any {
        const self = this;
        return this.auth.signInWithEmailAndPassword(email, password)
            .then((result) => {
                this.ngZone.run(() => {
                    this.router.navigate(['dashboard']);
                });
                this.setUserData(result.user);
            }).catch(error => {
                // Handle Errors here.
                const errorCode = error.code;
                if (errorCode === 'auth/wrong-password') {
                    self.showError('Invalid login credentials.');
                } else if (errorCode === 'auth/invalid-email') {
                    self.showError('That email address is not valid.');
                } else if (errorCode === 'auth/user-not-found') {
                    self.showError('Invalid login credentials.');
                } else if (errorCode === 'auth/wrong-password') {
                    self.showError('Invalid login credentials.');
                }
            });
    }

    // Verify access for this email
    async verifyAccess(email, password): Promise<any> {
        const self = this;
        return await firebase.functions().httpsCallable('profiles-verifyRegistrationAccess')({email});
    }

    async changeLoginEmail(oldEmail, newEmail, password): Promise<boolean> {
        return new Promise(async (resolve) => {
            const self = this;
            this.auth.signInWithEmailAndPassword(oldEmail, password)
                .then(async (userCredential) => {
                    await userCredential.user.updateEmail(newEmail);
                    this.setUserData(userCredential.user);
                    resolve(true);
                }).catch(error => {
                // Handle Errors here.
                const errorCode = error.code;
                if (errorCode === 'auth/wrong-password') {
                    self.showError('Invalid login credentials.');
                } else if (errorCode === 'auth/invalid-email') {
                    self.showError('That email address is not valid.');
                } else if (errorCode === 'auth/user-not-found') {
                    self.showError('Invalid login credentials.');
                } else if (errorCode === 'auth/wrong-password') {
                    self.showError('Invalid login credentials.');
                }
                resolve(false);
            });
        });
    }

    // Sign up with email/password
    register(email, password): Promise<void> {
        return new Promise(async (resolve) => {
            const self = this;
            return this.auth.createUserWithEmailAndPassword(email, password)
                .then((result) => {
                    this.sendVerificationMail(email);
                    this.setUserData(result.user);
                    resolve();
                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    if (errorCode === 'auth/email-already-in-use') {
                        self.showError('This email address is already in use.');
                    } else if (errorCode === 'auth/invalid-email') {
                        self.showError('That email address is not valid.');
                    } else if (errorCode === 'auth/operation-not-allowed') {
                        self.showError('Registration is not currently enabled. Please try again later.');
                    } else if (errorCode === 'auth/weak-password') {
                        self.showError('That password is not strong enough.');
                    }
                    resolve();
                });
        });
    }

    // Send email verification when a new user signs up
    sendVerificationMail(email): any {
        return this.auth.currentUser.then(user => user.sendEmailVerification()
            .then(() => {
                this.router.navigate(['auth/mail-confirm/' + email]);
            }));
    }

    // Reset forgotten password
    forgotPassword(passwordResetEmail): any {
        return this.auth.sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                Swal.fire('Success', 'Password reset email sent, check your inbox.', 'success');
            }).catch((error) => {
                Swal.fire('Oops...', 'That doesn\'t appear to be an email that has been granted access.', 'error');
            });
    }

    // Returns true when user is logged in and email is verified
    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return (user !== null) ? true : false; // && user.emailVerified !== false
    }


    /* Setting up user data when sign in with username/password,
    sign up with username/password */
    setUserData(user): any {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        const userData = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified
        };
        return userRef.set(userData, {
            merge: true
        });
    }

    // Sign out
    logout(): any {
        return this.auth.signOut().then(() => {
            localStorage.removeItem('user');
            this.userProfileObservable = null;
            this.userProfileSnapshot = null;
            this.userAccessSnapshot = null;
            this.router.navigate(['auth/login']);
        });
    }
}
