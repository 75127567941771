import { Injectable } from '@angular/core';  
import { HttpClient  } from '@angular/common/http';  
  
@Injectable({  
  providedIn: 'root'  
})  
export class IpLookupService  {  

  private readonly locationStorageKey = 'userCity';
  
  constructor(private http:HttpClient) { }  
  
  public cacheLocation(location: { city: string, timestamp: number }) {
    if (location.city !== undefined) {
      localStorage.setItem(this.locationStorageKey, JSON.stringify(location));
    }
  }

  public isLocationStale() {
    const locationString = localStorage.getItem(this.locationStorageKey);
    if (locationString && locationString !== 'undefined' && locationString !== 'null' && locationString !== undefined && locationString !== null) {
      const location = JSON.parse(locationString);
      return (Date.now() - location.timestamp) > 24 * 60 * 60 * 1000; // 24 hours
    }
    return true;
  }

  public getCity() {
    const locationString = localStorage.getItem(this.locationStorageKey);
    return locationString ? (JSON.parse(locationString)).city : this.getLocationInfo();
  }

  public getLocationInfo() {
    return this.http.get("https://ipgeolocation.abstractapi.com/v1/?api_key=f6d7c9ea894d4ef0bcb3372378ede2bc");
  }  
}  