import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {HelperService} from '../helper.service';
import * as firebase from 'firebase';
import 'firebase/firestore';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from '../auth/auth.service';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationComponent {
    notification;

    constructor(
        private _helper: HelperService,
        private _firestore: AngularFirestore,
        private _auth: AuthService,
        private snackBarRef: MatSnackBarRef<NotificationComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {
        this.notification = data;
    }

    clearAndNavigate(): void {
        this.clearNotification();
        this.snackBarRef.dismiss();
        this._helper.navigateToPath(this.notification.notification.click_action);
    }

    clearNotification(): void {
        this._firestore.doc<any>('notifications/' + this._auth.userAccessSnapshot.uid).update({
            [this.notification._id]: firebase.firestore.FieldValue.delete()
        });
        this.snackBarRef.dismiss();
    }
}
