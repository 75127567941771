// services/admin-guard.service.ts
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }
    canActivate(): Promise<boolean> {
        return new Promise(resolve => {
            if (!this.authService.isLoggedIn) {
                resolve(false);
            } else {
                this.authService.isUserProfileReady()
                    .subscribe((isReady) => {
                        if (isReady) {
                            if (['Admin'].indexOf(this.authService.userProfileSnapshot.programInfo.role) !== -1) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        }
                    });
            }
        });
    }
}
