<div class="navbar-top">

    <div class="logo">
        <img class="logo-icon">
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user" fxLayout="column">

        <div class="avatar-container" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"  *ngIf="_auth.userProfileSnapshot">
            <img *ngIf="_auth.userProfileSnapshot?.avatar" alt="'assets/images/avatars/profile.png'" [ngClass]="{'loaded': _auth.userProfileSnapshot}"
                 src="{{_auth.userProfileSnapshot?.avatar}}" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                 class="avatar"/>
        </div>

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
