import {Component, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../@fuse/animations';

@Component({
  selector: 'powerups',
  templateUrl: './powerups.component.html',
  styleUrls: ['./powerups.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations

})
export class PowerupsComponent {

  constructor() { }

}
