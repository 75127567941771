<quill-editor fxLayout="column-reverse" placeholder="{{placeholder}}" [id]="myId.toString()"
              #quillContainer (resized)="onQuillResized()"
              (onFocus)="editorService.setCurrentEditor(this); focused = true"
              (onBlur)="focused = false"
              [ngClass]="{
              'show-no-tools': visibleToolCount < 1,
              'show-1-tool': visibleToolCount == 1,
              'show-2-tools': visibleToolCount == 2,
              'show-3-tools': visibleToolCount == 3,
              'show-4-tools': visibleToolCount == 4,
              'show-5-tools': visibleToolCount == 5,
              'show-6-tools': visibleToolCount == 6 || visibleToolCount == 7}"
              [modules]="editorService.modules" [formats]="editorService.formats"
              (onEditorCreated)="created($event)"
              (onContentChanged)="onMessageChange($event)"
              (keydown.enter)="handleQuillKeys($event)"
              (keydown.arrowUp)="handleQuillKeys($event)"
              (keydown.arrowDown)="handleQuillKeys($event)"
              (keydown.escape)="handleQuillKeys($event)">
</quill-editor>

<div class="button-container" #buttonContainer>
    <button class="emoji-button" mat-icon-button [matMenuTriggerFor]="emojiMenu" *ngIf="!_helper.isMobile()">
        <mat-icon>insert_emoticon</mat-icon>
    </button>

    <button *ngIf="!textSubmitButton && !noSubmit" class="submit-button" mat-icon-button [disabled]="!this.html" (click)="submit($event)">
        <mat-icon>send</mat-icon>
    </button>

    <button *ngIf="textSubmitButton && !noSubmit" class="raised-submit-button" mat-raised-button color="accent" [disabled]="!this.html" (click)="submit($event)">
        {{textSubmitButton}}
    </button>
</div>


<mat-menu #emojiMenu class="emoji-picker custom-menu">
    <emoji-mart title="Pick an emoji"
                emoji="slightly-smiling-face"
                *ngIf="_auth.userProfileSnapshot"
                [darkMode]="_auth.userProfileSnapshot.theme.indexOf('dark') !== -1"
                [set]="editorService.set"
                (emojiClick)="editorService.insertEmoji(this, $event.emoji.native)"
                (click)="$event.stopPropagation()">
    </emoji-mart>
</mat-menu>
