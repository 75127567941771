<div class="profile-viewer"
     [class.director]="_auth.userProfileSnapshot.programInfo?.role === 'Director' || _auth.userProfileSnapshot.programInfo?.role === 'Admin'">
    <div class="avatar-container">
        <div class="hover-container">
            <div class="hover-text">
                Click to go to profile
            </div>
        </div>

        <img (click)="closeHandlerThenNavigateToUser()" class="avatar" [src]="profile.avatar || '/assets/images/avatars/profile.png'"/>
    </div>

    <div class="main-info-container">
        <div class="name" (click)="closeHandlerThenNavigateToUser()">
            {{profile.name}}
            <mat-icon class="s-16 status" [ngClass]="(_helper.getMemberStatus(profile.uid) | async)?.status?.state"></mat-icon>
        </div>
        <div class="about">
            {{(profile.programInfo?.program ? profile.programInfo?.program + (profile.programInfo?.role ? ' ' + profile.programInfo?.role : '')
            + ' | ' : (profile.programInfo?.role ? profile.programInfo?.role + ' | ' : '')) +
            (profile.programInfo?.city ? profile.programInfo?.city : '')}}
        </div>
    </div>

    <div class="buttons-container section">
        <div class="button-container">
            <button class="button" [disabled]="profile.uid === _auth.userProfileSnapshot.uid || profile.programInfo?.role === 'Foundations'" mat-button (click)="message()"> <!-- TODO: Needs to be disabled for system profile -->
                <mat-icon>chat</mat-icon>
                <span class="button-name">Message</span>
            </button>

        </div>
        <div class="button-container">
            <button class="button" [disabled]="profile.uid === _auth.userProfileSnapshot.uid || profile.programInfo?.role === 'Foundations'" (click)="requestBraindate()" mat-button>
                <mat-icon>people</mat-icon>
                <span class="button-name">Braindate</span>
            </button>

        </div>
    </div>

    <div class="director-button-container section"
         *ngIf="_auth.userProfileSnapshot.programInfo?.role === 'Director' || _auth.userProfileSnapshot.programInfo?.role === 'Admin'">
        <button class="button" (click)="goToStudentDashboard(profile.uid)"
                [disabled]="profile.uid === _auth.userProfileSnapshot.uid" mat-button>
            <mat-icon>dashboard</mat-icon>
            <span class="button-name">View Dashboard</span>
        </button>
    </div>

    <div class="extra-info-container section">
        <p class="time" fxLayout="column">
            Local time: <span>{{_helper.getLocalTimeForProfile(profile.programInfo?.city)}}</span>
        </p>

        <a class="email" *ngIf="profile.email" [href]="'mailto:' + profile.email">
            {{profile.email}}
        </a>
    </div>
</div>