interface StringConstants {
    TAGS: string[];
    Notifications: object;
    IMPROVEMENTTAGS: string[];
    Cities: string[];
    Programs: object;
    TKSPlaybooks: object[];
    ContentSkillsPlaybooks: object[];
    NetworkingPlaybooks: object[];
    ProductivityPlaybooks: object[];
    PeopleSkillsPlaybooks: object[];
    TechnicalSkillsPlaybooks: object[];
    LifestylePlaybooks: object[];
    VelocityPlaybooks: object[];
    ChallengePlaybooks: object[];
}

export const CONSTANTS: StringConstants = {
    TAGS: [
        'Virtual Reality',
        'Mobile Apps',
        'Genomics',
        'Robotics 2',
        'Ultra Poverty',
        'Synthetic Biology',
        'Food Crisis',
        'Internet Connectivity',
        'International Politics',
        'Financial Markets',
        'Media Influence',
        'Cancer Treatments',
        'GMOs',
        'Alternative Energy',
        'Stem Cell Applications',
        'Space Exploration',
        'Internet of Things (IoT)',
        'Artificial Intelligence',
        'Cryptocurrency',
        'Unicorn People',
        'Startups',
        'Entrepreneurship',
        'Stem Cells',
        'Biomimetics',
        '3D & 4D Printing',
        'Gene Editing',
        'Phage Therapy',
        'Augmented Reality',
        'Genetic Engineering',
        'Programmable Materials',
        'Quantum Computing',
        'Wireless Energy',
        'T-Cells',
        'Autonomous Vehicles',
        'Nanotechnology',
        'Cellular Agriculture',
        'Blockchain',
        'Robotics',
        'SpaceTech',
        'Solar Energy',
        'Neurology',
        'Smart Cities',
        'Bionics',
        'Wireless Electricity',
        'Space Technologies',
        '3D Printing',
        'Edge Computing',
        'Cryptocurrencies',
        'Metaverse',
        'Advanced Transportation',
        'Haptic Technology',
        'Regenerative Medicine',
        'Human Longevity',
        'Metabolomics',
        'Connectomics',
        'Autonomous Aerial Vehicles',
        'Self-Driving Cars (Autonomous Vehicles)',
        'Personalized Medicine',
        'Brain Computer Interfaces',
        'Longevity',
        'Biometrics'
    ],
    IMPROVEMENTTAGS: [
        'Fitness',
        'Health',
        'Life',
        'Motivation',
        'School',
        'Personality',
        'Emotions',
        'Productivity',
        'Strategies',
        'Competition',
        'Friendships',
        'Relationships',
        'Collaboration',
        'Ideation',
        'Feedback'
    ],
    Cities: [
        'Boston',
        'Las Vegas',
        'Los Angeles',
        'New York',
        'Ottawa',
        'San Francisco',
        'Seattle',
        'Toronto',
        'Virtual',
        'Vancouver'
    ],
    TKSPlaybooks: [
        {
            title: 'TL;DR: TKS',
            url: 'https://www.notion.so/TL-DR-TKS-9a777d4fe7a54319a614a9c0e4e594cc'
        },
        {
            title: 'Traits of an Ideal TKS Student',
            url: 'https://www.notion.so/Playbook-Traits-of-an-ideal-TKS-student-bd6be02045b4470b900d99734664e9c7'
        },
        {
            title: 'Success in Innovate ',
            url: 'https://www.notion.so/Playbook-Success-in-Innovate-47db3e157a524910a64a460f9082eb4b'
        },
        {
            title: 'Success in Innovate Part 2',
            url: 'https://www.notion.so/Playbook-Success-in-Innovate-Part-2-5bb9a6dadbf747d6a855912cb38cf726'
        },
        // {
        //     title: 'Success in Activate',
        //     url: 'https://www.evernote.com/l/ASSC507THiFJ96DoEeM2Z8YCRWORovNnk1c'
        // },
        {
            title: '"What Advice Would You Give To A New TKS Student?"',
            url: 'https://www.notion.so/Playbook-What-advice-would-you-give-to-a-new-TKS-student-2683ecc9a51e42fda00997c1d6523052'
        },
        // {
        //     title: 'Internship Prep',
        //     url: 'https://www.evernote.com/l/ASSRpSZ4AodBoJc7yr7MjqMjx-GVFyoNDYM'
        // },
        // {
        //     title: 'How To Complete Focus',
        //     url: 'https://slite.com/api/s/EDvWXSc2WzpQ5wmdxPn1pE/Playbook%3A%20Focus'
        // },
        // {
        //     title: 'Daily Updates',
        //     url: 'https://tksnotes.slite.com/api/s/note/Na7fssCL5kWcGSE9MovtKc/Playbook-Daily-Updates'
        // }
    ],
    TechnicalSkillsPlaybooks: [
        {
            title: 'Learning How to Code',
            url: 'https://www.notion.so/Playbook-Learning-How-to-Code-6b1597ef47a949afa3e97d5462aa1802'
        },
        {
            title: 'Learn Python',
            url: 'https://www.notion.so/Playbook-Learn-Python-dfcd9c1f51d842c3955825931fa84ca4'
        },
        {
            title: 'AI',
            url: 'https://www.notion.so/Playbook-AI-86835f82b5ec4d59b32de37d9bf97af9'
        },
        {
            title: 'Quantum Machine Learning',
            url: 'https://www.notion.so/Playbook-Quantum-Machine-Learning-269a5720939641da857960f420d2d365'
        },
        {
            title: 'Genetic Engineering Focus',
            url: 'https://www.notion.so/Playbook-Genetic-Engineering-Focus-30f82172c69e43d4bd7c559d364df274'
        },
        {
            title: 'Building Explore Topics',
            url: 'https://www.notion.so/Playbook-Building-Explore-Modules-b9f81725b6db4c0d9bf2c6c4022a18bd'
        }
    ],
    ContentSkillsPlaybooks: [
        {
            title: 'Focus',
            url: 'https://www.notion.so/Playbook-Focus-1c8556674af041929d8e2a0fe1d1bb76'
        },
        {
            title: 'Picture Editing',
            url: 'https://www.notion.so/Playbook-Picture-Editing-e15bc17a194d4f7f98711cb087d5d72b'
        },
        {
            title: 'Presentations',
            url: 'https://www.notion.so/Playbook-Presentations-3bc4aebeec43414b8b5bebf67139740a'
        },
        {
            title: 'Storytelling',
            url: 'https://www.notion.so/Playbook-Storytelling-7b9eeafa0eeb4eb49dd9b955f0988274'
        },
        {
            title: 'Writing',
            url: 'https://www.notion.so/Playbook-Writing-7daa6b6c460249ba8a8e62c7aaa5d447'
        },
        {
            title: 'One-Pagers',
            url: 'https://www.notion.so/Playbook-One-Pagers-5be222439bf049629c60363cfa5eeca7'
        },
        {
            title: 'Websites',
            url: 'https://www.notion.so/Playbook-Websites-de00ecbee37945129a5113335365b917'
        },
        {
            title: 'Making High Quality Videos',
            url: 'https://www.notion.so/Playbook-Making-High-Quality-Videos-a40f3e8cb0b44acd92d6c34a50b537bd'
        },
        {
            title: 'Personal Website',
            url: 'https://www.notion.so/Playbook-Personal-Website-d6b37546c911435c84ab359b6b42ec76'
        },
        {
            title: 'Newsletters',
            url: 'https://www.notion.so/Playbook-Newsletters-6753c80d38004f48995de4fd46dea371'
        }
    ],
    NetworkingPlaybooks: [
        {
            title: 'Networking',
            url: 'https://www.notion.so/Playbook-Networking-d3bf4f9b43a2432fa85484c67dda5e20'
        },
        {
            title: 'Cold Emails',
            url: 'https://www.notion.so/Playbook-Cold-Emails-e9bcd94ee68f4ac59694cbed2f7b040f'
        },
        {
            title: 'Pre-meeting Emails',
            url: 'https://www.notion.so/Playbook-Pre-meeting-email-b249702d003848a7b15285d6df676f06'
        },
        {
            title: 'Follow-up Emails',
            url: 'https://www.notion.so/Playbook-Follow-up-Emails-d27bd4b9f0074e8abb0267940420e2c7'
        },
        {
            title: 'Resumes',
            url: 'https://www.notion.so/Playbook-Resumes-8a460ee863cb438eaabee9f581fd8d95'
        },
        {
            title: 'Internships',
            url: 'https://www.notion.so/Playbook-Internships-b10f379f003345f8871404ad46d722ec'
        },
        {
            title: 'Internships (Remote)',
            url: 'https://www.notion.so/Playbook-Internships-Remote-9a048d4db2274b62bc0ddb64529ab3ce'
        }
    ],
    ProductivityPlaybooks: [
        {
            title: 'How to Learn',
            url: 'https://www.notion.so/Playbook-How-to-Learn-e73bc1febce84030b8c4c6f8415aca16'
        },
        {
            title: 'Productivity Hacks',
            url: 'https://www.notion.so/Playbook-Productivity-Hacks-d2c97f672bca4ffb81d496807fee30af'
        },
        {
            title: 'Daily Updates',
            url: 'https://www.notion.so/Playbook-Daily-Updates-74f67440fe3a4079819a3b347ee572b6'
        }
    ],
    PeopleSkillsPlaybooks: [
        {
            title: 'How To Manage Teams (PM)',
            url: 'https://www.notion.so/Playbook-How-To-Manage-Teams-PM-d7113c70a67e4f3487a0dab3c4c24b93'
        },
        {
            title: 'Building Trust With Parents',
            url: 'https://www.notion.so/theksociety/Playbook-Building-Trust-w-Parents-137b438e39d6477b9155741f80b8b90b'
        },
        {
            title: 'Braindates',
            url: 'https://www.notion.so/Playbook-Braindates-a2ee2c018e884bfcaa9a94f116d383fe'
        }
    ],
    LifestylePlaybooks: [
        {
            title: 'Diet and Health',
            url: 'https://www.notion.so/Playbook-Diet-and-Health-dfc96011827549b18237989a21b172a7'
        },
        {
            title: 'Fitness',
            url: 'https://www.notion.so/Playbook-Fitness-fff9b885cf0c43069815236497db3ac3'
        }
    ],
    VelocityPlaybooks: [
        {
            title: 'Velocity 101',
            url: 'https://www.notion.so/Velocity-101-e2b86f91aeea498f988608364f77a0c4'
        },
        {
            title: 'Introducing Velocity',
            url: 'https://www.notion.so/Introducing-Velocity-fbb66d4c24b24020884fcee80376addd'
        }
    ],
    ChallengePlaybooks: [
        {
            title: 'Challenge Recommendations',
            url: 'https://www.notion.so/Playbook-Challenge-Recommendations-a53fcacb2c344eb0a1e6e2553cf8ee70'
        },
        {
            title: 'Challenge Decks',
            url: 'https://www.notion.so/Playbook-Challenge-Deck-Structure-25ebfd02ddb848b194ee0cda655eb791'
        },
        {
            title: 'Stand-Alone Decks',
            url: 'https://www.notion.so/Playbook-Stand-alone-decks-03032e64ff5d40e3981c3bf5bb09f83b'
        }
    ],
    Notifications: {
        BraindateInterest: 'braindate-interest',
        BraindateRequest: 'braindate-request',
        BraindateSign: 'braindate-sign',
        BraindateMeetup: 'braindate-meetup',
        BraindateRequestReply: 'braindate-request-reply',
        BraindateProfileRequest: 'braindate-profile-request',
    },
    Programs: {
        'Toronto': {
            id: 0,
            cohorts: [1, 2, 3, 4, 5],
            cohortnames: [
                'Cohort 1',
                'Cohort 2',
                'Cohort 3',
                'Activate',
                'Disrupt'
            ]
        },
        'Las Vegas': {
            id: 1,
            cohorts: [1, 2],
            cohortnames: [
                'Cohort 1',
                'Cohort 2'
            ]
        },
        'New York': {
            id: 2,
            cohorts: [1, 2],
            cohortnames: [
                'Cohort 1',
                'Cohort 2'
            ]
        },
        'Boston': {
            id: 3,
            cohorts: [1],
            cohortnames: [
                'Cohort 1'
            ]
        },
        'Ottawa': {
            id: 4,
            cohorts: [1, 2],
            cohortnames: [
                'Cohort 1',
                'Cohort 2'
            ]
        }
    },
};

