import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';

import {fuseAnimations} from '@fuse/animations';

import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {DashboardService} from '../dashboard.service';
import {AuthService} from '../../auth/auth.service';
import {HelperService} from '../../helper.service';
import {Router} from '@angular/router';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import {AngularFirestore} from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import {take, takeUntil} from 'rxjs/operators';
import {ChatService} from '../../chat/chat.service';
import {Subject} from 'rxjs';


@Component({
    selector: 'my-dashboard',
    templateUrl: './my-dashboard.component.html',
    styleUrls: ['./my-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class MyDashboardComponent implements OnInit, OnDestroy {
    projects: any[];
    loaded = false;
    noChartData = false;

    todos = {
        braindates: {
            requests: [],
            active: []
        },
        chats: [],
        todos: [],
        completedFeedback: [],
        completedExplores: [],
        completedBraindates: [],
    };

    dashboard = {
        explore: {
            topics: []
        },
        focus: {
            topics: []
        },
        profile: {
            items: []
        },
        braindates: {
            completed: []
        },
        feedback: {
            completed: []
        },
        stats: {
            explores: 0,
            focuses: 0,
            // focusPresentations: 0,
            feedback: 0,
            newsletters: 0,
            braindates: 0
        },
        progress: {
            current: 'first',
            next: 'second',
            first: {
                percentage: 0
            },
            second: {
                percentage: 0
            },
            third: {
                percentage: 0
            }
        }
    };

    encouragement = {
        first: [
            'We all have to start somewhere. You\'re starting at that blue circle thing.',
            'I see you\'re starting to do things. Nice. Keep doing that.',
            'Someone\'s getting motivated!',
            'You\'re so close to getting that shirt there! Just a bit more...'
        ],
        second: [
            'Don\'t forget to ask your director to give you your TKS shirt. They\'re soft.',
            'Now you\'re getting into the swing of things. Someday soon you\'ll even get a BACKPACK!',
            'This is called gamification. Are you feeling motivated yet???',
            'Think of how awesome it would be to have a TKS backpack. You just need to fill in this last little part here...'
        ],
        third: [
            'Don\'t get cocky, now. Time to push hard and get that sick hoodie.',
            'I bet it feels nice to have this much of your progress bar filled.',
            'In the home stretch now!',
            'Look at that hoodie icon taunting you. So close.',
            'Dreams really do come true.'
        ]
    };

    goals = {
        first: {
            explores: 5,
            focuses: 0,
            // focusPresentations: 0,
            feedback: 10,
            braindates: 10,
            newsletters: 2
        },
        second: {
            explores: 10,
            focuses: 1,
            // focusPresentations: 1,
            feedback: 20,
            braindates: 20,
            newsletters: 3
        },
        third: {
            explores: 20,
            focuses: 2,
            // focusPresentations: 3,
            feedback: 50,
            braindates: 30,
            newsletters: 5
        }
    };

    displayCharts = false;
    statTypes = [
        {type: 'explores', title: 'Explore topics completed'},
        {type: 'focuses', title: 'Focuses completed'},
        // {type: 'focusPresentations', title: 'Focus presentations given'},
        {type: 'feedback', title: 'Feedback given'},
        {type: 'newsletters', title: 'Newsletters written'},
        {type: 'braindates', title: 'Braindates completed'}
    ];

    lists = [
        'media',
        'decks',
        'articles',
        'onepagers',
        'threads',
        'newsletters',
        'videos',
        'projects'
    ];

    itemLineChartData: any[];
    itemLineChartLabels: any[];
    lineChartOptions = {
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    precision: 0
                }
            }],
        },
    };
    lineChartColors: any[] = [
        {
            borderColor: 'rgb(38, 156, 163)',
            backgroundColor: 'rgb(38, 156, 163, 0.33)',
        },
        {
            borderColor: 'rgb(10, 70, 233)',
            backgroundColor: 'rgb(10, 70, 233, 0.33)',
        },
        {
            borderColor: 'rgb(201, 89, 89)',
            backgroundColor: 'rgb(201, 89, 89, 0.66)',
        },
        {
            borderColor: 'rgb(212, 142, 106)',
            backgroundColor: 'rgb(212, 142, 106, 0.33)',
        },
        {
            borderColor: 'rgb(110, 77, 143)',
            backgroundColor: 'rgb(110, 77, 143, 0.33)',
        }
    ];
    lineChartLegend = true;
    lineChartType = 'line';

    prevSaturday = null;


    private _unsubscribeAll: Subject<any>;

    private recommendedProfilesStorageKey = 'recommendedProfiles';

    recommendedProfiles: any[] = [];

    /**
     * Constructor
     *
     * @param {FuseSidebarService} _fuseSidebarService
     * @param _dashboardService
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _dashboardService: DashboardService,
        public _auth: AuthService,
        public _helper: HelperService,
        private _router: Router,
        private _firestore: AngularFirestore,
        public _chat: ChatService
    ) {
        this._unsubscribeAll = new Subject();

        this.prevSaturday = new Date();
        this.prevSaturday = getLastSaturday(this.prevSaturday);

        function getLastSaturday(theDate): Date {
            const dateToUse = new Date(theDate);
            const start = dateToUse.getDay() === 0 ? 7 : dateToUse.getDay();
            let target = 6; // Saturday

            if (target >= start) {
                target -= 7;
            }

            dateToUse.setDate(dateToUse.getDate() + (target - start));
            return dateToUse;
        }

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._auth.isUserProfileReady().pipe(takeUntil(this._unsubscribeAll))
            .subscribe(async (ready) => {
                if (ready) {
                    if (!this.loaded) {
                        this._firestore.doc('profiles/' + this._auth.userProfileSnapshot.uid + '/braindates/braindates1').get()
                            .pipe(take(1))
                            .subscribe(braindates => {
                                setTimeout(() => {
                                    this._firestore.doc('profiles/' + this._auth.userProfileSnapshot.uid + '/subfields/feedback').get()
                                        .pipe(take(1))
                                        .subscribe(feedback => {
                                            if (feedback && feedback.data()) {
                                                this.prepareFeedback(feedback.data());
                                            }

                                            this.init(braindates.data());

                                            this._firestore.collection('todos', ref => ref
                                                .where('city', '==', this._auth.userProfileSnapshot.programInfo.city)
                                                .orderBy('timestamp', 'desc')
                                                .limit(1))
                                                .get()
                                                .pipe(take(1))
                                                .subscribe(todos => {
                                                    todos.forEach(todo => {
                                                        this.prepareToDos(todo.data());
                                                    });

                                                });
                                        });
                                });
                                this.loaded = true;
                            });
                    }
                    this.loadRecommendedProfiles()
                }
            });

        this._chat.chatServiceReady
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((isReady) => {
                if (isReady) {
                    this.prepareUnreadChats();
                }
            });

        this._chat.dialogsUpdated.pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {
            this.prepareUnreadChats();
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    prepareFeedback(feedback): void {
        const feedbackKeys = Object.keys(feedback);
        this.todos.completedFeedback = [];
        this.dashboard.feedback.completed = [];

        for (const key of feedbackKeys) {
            if (feedback[key].rating) {
                this.dashboard.stats.feedback += 1;
                this.dashboard.feedback.completed.push(feedback[key]);
            }

            if ((feedback[key].timestamp?.seconds * 1000) > this.prevSaturday.getTime()) {
                this.todos.completedFeedback.push(feedback[key]);
            }
        }
    }

    completedNumberOfExploresThisWeek(num): boolean {
        for (const explore of this.dashboard.explore.topics) {
            if (explore.starttime && explore.endtime && explore.isComplete) {
                if (this._auth.userProfileSnapshot.exploreProgress?.topics[explore.name]?.topicTimer?.starttime?.seconds * 1000 > this.prevSaturday.getTime()) {
                    this.todos.completedExplores.push(explore);
                }
            }
        }

        if (this.todos.completedExplores.length >= num) {
            return true;
        } else {
            return false;
        }
    }

    completedNumberOfBraindatesThisWeek(num): boolean {
        for (const braindate of this.dashboard.braindates.completed) {
            if (braindate.timestamp?.seconds * 1000 > this.prevSaturday.getTime()) {
                this.todos.completedBraindates.push(braindate);
            }
        }

        if (this.todos.completedBraindates.length >= num) {
            return true;
        } else {
            return false;
        }
    }

    prepareToDos(todos): void {
        for (const todo of todos.todos) {
            switch (todo.type) {
                case 'explore': {
                    this.todos.todos.push({
                        ctaUrl: '/explore/' + todo.topic,
                        ...todo,
                        isComplete: this._auth.userProfileSnapshot.exploreProgress?.topics[todo.topic]?.topicProgress?.IsComplete,
                        description: 'Complete the ' + todo.topic + ' Explore topic'
                    });
                    break;
                }
                case 'explores': {
                    this.todos.todos.push({
                        ctaUrl: '/explore',
                        ...todo,
                        isComplete: this.completedNumberOfExploresThisWeek(todo.amount),
                        description: 'Start and complete ' + todo.amount + ' Explore topics' +
                            ' (' + this.todos.completedExplores.length + '/' + todo.amount + ')'
                    });
                    break;
                }
                case 'braindates': {
                    this.todos.todos.push({
                        ctaUrl: '/braindates',
                        ...todo,
                        isComplete: this.completedNumberOfBraindatesThisWeek(todo.amount),
                        description: 'Complete ' + todo.amount + ' braindates' +
                            ' (' + this.todos.completedBraindates.length + '/' + todo.amount + ')'
                    });
                    break;
                }
                case 'feedback': {
                    this.todos.todos.push({
                        ctaUrl: '/community/activity',
                        ...todo,
                        isComplete: this.todos.completedFeedback.length >= todo.amount,
                        description: 'Give at least ' + todo.amount + ' pieces of feedback' +
                            ' (' + this.todos.completedFeedback.length + '/' + todo.amount + ')'
                    });
                    break;
                }
                case 'weekly-tracker': {
                    this.todos.todos.push({
                        ctaUrl: '/updates/tracker',
                        ...todo,
                        description: 'Complete your weekly tracker'
                    });
                    break;
                }
            }
        }
    }

    prepareUnreadChats(): void {
        const chatIds = Object.keys(this._chat.spaces[this._chat.spaceId].dialogs);
        this.todos.chats = [];

        for (const key of chatIds) {
            if (this._chat.spaces[this._chat.spaceId].dialogs[key].participantInfo[this._auth.userProfileSnapshot.uid].unreads) {
                let chatPartner = null;
                for (const participant of this._chat.spaces[this._chat.spaceId].dialogs[key].participants) {
                    if (participant !== this._auth.userProfileSnapshot.uid) {
                        chatPartner = participant;
                    }
                }
                this.todos.chats.push({chatPartner: chatPartner, ...this._chat.spaces[this._chat.spaceId].dialogs[key]});
            }
        }
    }

    prepareActiveBraindates(): void {
        const activeBraindateIds = Object.keys(this._auth.userProfileSnapshot.activeBraindates);
        const braindateRequestIds = Object.keys(this._auth.userProfileSnapshot.braindateRequests);

        for (const id of braindateRequestIds) {
            let braindatePartner = null;
            for (const participant of this._auth.userProfileSnapshot.braindateRequests[id].members) {
                if (participant !== this._auth.userProfileSnapshot.uid) {
                    braindatePartner = participant;
                }
            }
            this.todos.braindates.requests.push({
                braindatePartner: braindatePartner, ...this._auth.userProfileSnapshot.braindateRequests[id],
                groupAvatar: 'https://firebasestorage.googleapis.com/v0/b/tks-life-prod.appspot.com/o/avatars%2Fgroup.png?alt=media&token=b99194f5-e8b7-478d-afed-ba9c1d2a4127'
            });
        }
        this.todos.braindates.requests.sort((a, b) => {
            const dateA = a.timestamp?.seconds;
            const dateB = b.timestamp?.seconds;
            return dateA > dateB ? -1 : 1;
        });

        for (const id of activeBraindateIds) {
            let braindatePartner = null;
            for (const participant of this._auth.userProfileSnapshot.activeBraindates[id].members) {
                if (participant !== this._auth.userProfileSnapshot.uid) {
                    braindatePartner = participant;
                }
            }
            this.todos.braindates.active.push({
                braindatePartner: braindatePartner, ...this._auth.userProfileSnapshot.activeBraindates[id],
                groupAvatar: 'https://firebasestorage.googleapis.com/v0/b/tks-life-prod.appspot.com/o/avatars%2Fgroup.png?alt=media&token=b99194f5-e8b7-478d-afed-ba9c1d2a4127'
            });
        }
        this.todos.braindates.active.sort((a, b) => {
            const dateA = a.timestamp?.seconds;
            const dateB = b.timestamp?.seconds;
            return dateA > dateB ? -1 : 1;
        });
    }

    init(completedBraindates): void {
        this.prepareActiveBraindates();

        // Prepare dashboard stat lists
        this.dashboard.stats.feedback += this._auth.userProfileSnapshot.feedback.length || 0;
        this.dashboard.stats.newsletters = Object.keys(this._auth.userProfileSnapshot.newsletters).length || 0;

        // const focusKeys = Object.keys(this._auth.userProfileSnapshot.items);
        // for (const focusKey of focusKeys) {
        //     if (this._auth.userProfileSnapshot.items[focusKey].isFocusPresentation) {
        //         this.dashboard.stats.focusPresentations++;
        //     }
        // }

        if (completedBraindates) {
            this.dashboard.stats.braindates = Object.keys(completedBraindates).length;

            const dateKeys = Object.keys(completedBraindates);
            for (const key of dateKeys) {
                this.dashboard.braindates.completed.push(completedBraindates[key]);
            }
        } else {
            this.dashboard.stats.braindates = 0;
        }


        // Prepare focuses
        const focuses = HelperService.addPercentagesToFocusesAndReturnArray(this._auth.userProfileSnapshot.focuses);

        this.dashboard.focus.topics = focuses.sort((a, b) => {
            const dateA = a.startDate?.seconds;
            const dateB = b.startDate?.seconds;
            return dateA > dateB ? -1 : 1;
        });

        for (const topic of this.dashboard.focus.topics) {
            topic.startDateAsDate = new Date(Number(topic.startDate));
            if (topic.isComplete && topic.finishDate) {
                topic.finishDateAsDate = new Date(topic.finishDate.seconds * 1000);
                this.dashboard.stats.focuses++;
            } else {
                topic.finishDateAsDate = '';
            }
        }

        this.dashboard.profile.items = [];
        for (const item of Object.keys(this._auth.userProfileSnapshot.items)) {
            this.dashboard.profile.items.push(this._auth.userProfileSnapshot.items[item]);
        }

        this.prepareAllDashboardData();
    }

    prepareExploreProgress(): void {
        const self = this;

        const topicNames = Object.keys(self._auth.userProfileSnapshot.exploreProgress.topics);
        topicNames.forEach((topicName) => {
            if (topicName !== 'easterEgg') {
                if (self._auth.userProfileSnapshot.exploreProgress.topics[topicName].topicProgress &&
                    self._auth.userProfileSnapshot.exploreProgress.topics[topicName].topicTimer &&
                    Object.keys(self._auth.userProfileSnapshot.exploreProgress.topics[topicName].topicProgress).length) {
                    let hasFocus = false;

                    for (const topic of self.dashboard.focus.topics) {
                        if (topic.topic === topicName) {
                            hasFocus = true;
                            break;
                        }
                    }

                    let startTime = null;
                    let endTime = null;
                    if (self._auth.userProfileSnapshot.exploreProgress.topics[topicName].topicTimer) {
                        startTime = this._helper.printDateString(self._auth.userProfileSnapshot.exploreProgress.topics[topicName].topicTimer.starttime);
                        endTime = this._helper.printDateString(self._auth.userProfileSnapshot.exploreProgress.topics[topicName].topicTimer.endtime);
                    }

                    if (self._auth.userProfileSnapshot.exploreProgress.topics[topicName].topicProgress.IsComplete) {
                        self.dashboard.stats.explores++;
                    }

                    self.dashboard.explore.topics.push({
                        name: topicName,
                        isComplete: self._auth.userProfileSnapshot.exploreProgress.topics[topicName].topicProgress.IsComplete,
                        starttime: startTime,
                        endtime: endTime,
                        hasFocus: hasFocus
                    });

                    self.dashboard.explore.topics.sort((a, b) => (a['name'] > b['name']) ? 1 : -1);

                }
            }
        });
    }

    setFirstGoalCurrentProgress(): void {
        const self = this;
        const goal = self.goals.first;
        const goalList = Object.keys(goal);
        let totalNumNeeded = 0;
        let totalValued = 0;
        for (const goalItem of goalList) {
            let subPercentage = 0;
            if (goal[goalItem] > 0) {
                totalNumNeeded += 100;
                subPercentage = Math.min(Math.round((self.dashboard.stats[goalItem] / goal[goalItem]) * 100), 100);
            }

            totalValued += subPercentage;

        }
        const percentageComplete = Math.round((totalValued / totalNumNeeded) * 100);

        self.dashboard.progress.first.percentage = percentageComplete;
    }

    setSecondGoalCurrentProgress(): void {
        const self = this;
        const firstGoal = self.goals.first;
        const secondGoal = self.goals.second;
        const goalList = Object.keys(secondGoal);
        let totalNumNeeded = 0;
        let totalValued = 0;
        for (const goalItem of goalList) {
            let subPercentage = 0;
            if (secondGoal[goalItem] > 0) {
                totalNumNeeded += 100;
                subPercentage = Math.min(Math.round(((self.dashboard.stats[goalItem] - firstGoal[goalItem]) /
                    (secondGoal[goalItem] - firstGoal[goalItem])) * 100), 100);
            }

            totalValued += subPercentage;

        }
        const percentageComplete = Math.round((totalValued / totalNumNeeded) * 100);

        self.dashboard.progress.second.percentage = percentageComplete;
    }

    setThirdGoalCurrentProgress(): void {
        const self = this;
        const secondGoal = self.goals.second;
        const thirdGoal = self.goals.third;
        const goalList = Object.keys(secondGoal);
        let totalNumNeeded = 0;
        let totalValued = 0;
        for (const goalItem of goalList) {
            let subPercentage = 0;
            if (thirdGoal[goalItem] > 0) {
                totalNumNeeded += 100;
                subPercentage = Math.min(Math.round(((self.dashboard.stats[goalItem] - secondGoal[goalItem]) /
                    (thirdGoal[goalItem] - secondGoal[goalItem])) * 100), 100);
            }

            totalValued += subPercentage;

        }
        const percentageComplete = Math.round((totalValued / totalNumNeeded) * 100);

        self.dashboard.progress.third.percentage = percentageComplete;
    }

    prepareAllDashboardData(): void {
        const self = this;
        self.prepareExploreProgress();

        self.setFirstGoalCurrentProgress();

        if (self.dashboard.progress.first.percentage === 100) {
            self.dashboard.progress.current = 'second';
            self.dashboard.progress.next = 'third';
            self.setSecondGoalCurrentProgress();

            if (self.dashboard.progress.second.percentage === 100) {
                self.dashboard.progress.current = 'third';
                self.dashboard.progress.next = null;
                self.setThirdGoalCurrentProgress();
            }
        }

        function sortByCompletedFunction(a, b): any {
            if (a.completed?.seconds && b.completed?.seconds) {
                const dateA = a.completed.seconds;
                const dateB = b.completed.seconds;
                return dateA > dateB ? 1 : -1;
            } else {
                return -1;
            }
        }

        function sortByTimestampFunction(a, b): any {
            if (a.timestamp?.seconds && b.timestamp?.seconds) {
                const dateA = a.timestamp.seconds;
                const dateB = b.timestamp.seconds;
                return dateA > dateB ? 1 : -1;
            } else {
                return -1;
            }
        }

        function sortByCreatedFunction(a, b): any {
            if (a.created?.seconds && b.created?.seconds) {
                const dateA = a.created.seconds;
                const dateB = b.created.seconds;
                return dateA > dateB ? 1 : -1;
            } else {
                return -1;
            }
        }

        const exploreTopicKeys = Object.keys(self._auth.userProfileSnapshot.exploreProgress.topics);
        const focusTopicKeys = Object.keys(self._auth.userProfileSnapshot.focuses);
        if (self.dashboard.profile.items.length > 0) {

            // Sort all items by date of creation
            self.dashboard.profile.items.sort(sortByCreatedFunction);

            console.log(self.dashboard.profile.items);
            const exploreCompletions = [];
            for (const key of exploreTopicKeys) {
                if (self._auth.userProfileSnapshot.exploreProgress.topics[key].topicProgress.IsComplete) {
                    exploreCompletions.push({
                        topic: key,
                        completed: self._auth.userProfileSnapshot.exploreProgress.topics[key].topicTimer.endtime || {seconds: Date.now() / 1000}
                    });
                }
            }
            exploreCompletions.sort(sortByCompletedFunction);

            const focusCompletions = [];
            for (const key of focusTopicKeys) {
                if (self._auth.userProfileSnapshot.focuses[key].isComplete) {
                    focusCompletions.push({
                        topic: key,
                        completed: self._auth.userProfileSnapshot.focuses[key].finishDate || {seconds: Date.now() / 1000}
                    });
                }
            }
            focusCompletions.sort(sortByCompletedFunction);

            this.dashboard.braindates.completed.sort(sortByCompletedFunction);
            this.dashboard.feedback.completed.sort(sortByTimestampFunction);

            let startDate;
            if (exploreCompletions.length && self.dashboard.profile?.items[0]?.created?.seconds && exploreCompletions[0]?.created?.seconds) {
                startDate = Math.min(self.dashboard.profile.items[0].created.seconds * 1000, exploreCompletions[0].created.seconds * 1000);
            } else if (self.dashboard.profile?.items[0]?.created?.seconds) {
                startDate = self.dashboard.profile.items[0].created.seconds * 1000;
            } else {
                startDate = Date.now();
            }

            const endDate = Date.now();
            const daysDiff = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));

            if (daysDiff > 7) {
                self.displayCharts = true;
                const oneDay = 86400000;
                const oneWeek = oneDay * 7;
                let currentDate = startDate;
                const itemSeries = [];
                const exploreSeries = [];
                const focusSeries = [];
                const braindateSeries = [];
                const feedbackSeries = [];
                const labels = [];
                while (currentDate < endDate) {
                    let itemCount = 0;
                    for (const item of self.dashboard.profile.items) {
                        if (item.created?.seconds * 1000 <= currentDate) {
                            itemCount++;
                        } else {
                            break;
                        }
                    }

                    let exploreCount = 0;
                    for (const explore of exploreCompletions) {
                        if (explore.completed?.seconds * 1000 <= currentDate) {
                            exploreCount++;
                        } else {
                            break;
                        }
                    }

                    let focusCount = 0;
                    for (const focus of focusCompletions) {
                        if (focus.completed?.seconds * 1000 <= currentDate) {
                            focusCount++;
                        } else {
                            break;
                        }
                    }

                    let braindateCount = 0;
                    for (const braindate of this.dashboard.braindates.completed) {
                        if (braindate.completed?.seconds * 1000 <= currentDate) {
                            braindateCount++;
                        } else {
                            break;
                        }
                    }

                    let feedbackCount = 0;
                    for (const feedback of this.dashboard.feedback.completed) {
                        if (feedback.timestamp?.seconds * 1000 <= currentDate) {
                            feedbackCount++;
                        } else {
                            break;
                        }
                    }

                    itemSeries.push(itemCount);
                    exploreSeries.push(exploreCount);
                    focusSeries.push(focusCount);
                    braindateSeries.push(braindateCount);
                    feedbackSeries.push(feedbackCount);
                    labels.push(new Date(currentDate).toLocaleDateString());
                    currentDate += oneWeek;
                }
                itemSeries.push(self.dashboard.profile.items.length);
                exploreSeries.push(exploreCompletions.length);
                focusSeries.push(focusCompletions.length);
                braindateSeries.push(this.dashboard.braindates.completed.length);
                feedbackSeries.push(this.dashboard.feedback.completed.length);
                labels.push(new Date().toLocaleDateString());

                this.itemLineChartLabels = labels;
                this.itemLineChartData = [{
                    data: itemSeries, label: 'Item uploads'
                }, {
                    data: exploreSeries, label: 'Explore topics completed'
                }, {
                    data: focusSeries, label: 'Focuses completed'
                }, {
                    data: braindateSeries, label: 'Braindates completed'
                }, {
                    data: feedbackSeries, label: 'Rated feedback'
                }
                ];

            } else {
                this.noChartData = true;
            }
        } else {
            this.noChartData = true;
        }
    }

    async loadRecommendedProfiles(): Promise<any> {
        return new Promise<any[]>(async (resolve, reject) => {
            const savedRecommendations = localStorage.getItem(this.recommendedProfilesStorageKey);

            const parsedRecommendations = savedRecommendations ? JSON.parse(savedRecommendations) : undefined;

            // time limit for recommendations is a week (24 hrs * 7 days)
            if (parsedRecommendations !== undefined && (Date.now() - parsedRecommendations.timestamp < 24 * 60 * 60 * 1000 * 7)) {
                this.recommendedProfiles = parsedRecommendations.recommendedProfiles;
                return parsedRecommendations.recommendedProfiles;
            } else {
                const currentUserProgramInfo = this._auth.userProfileSnapshot.programInfo;
                
                const query = this._firestore.collection('profiles', ref => ref
                    // .where('programInfo.year', 'in', [currentUserProgramInfo.year - 1, currentUserProgramInfo.year, currentUserProgramInfo.year + 1])
                    .where('programInfo.year', 'in', [currentUserProgramInfo.year])
                );
                const snapshot = await query.get().toPromise();
    
                const dataArray = await Promise.all(snapshot.docs.map(async doc => {
                    const data = doc.data();
    
                    let commonExplores = 0;
                    const currentUserTopics = new Set(Object.keys(this._auth.userProfileSnapshot.exploreProgress.topics));
                    const profileTopics = data.exploreProgress ? new Set(Object.keys(data.exploreProgress.topics)) : null;
                    if (profileTopics) for (const topic of currentUserTopics) {
                        if (profileTopics.has(topic)) {
                            commonExplores++;
                        }
                    }

                    const itemsUploaded = data.decks?.length + data.articles?.length + data.newsletters?.length + data.onepagers?.length + data.projects?.length

                    const age = data.birthday ? Math.floor((Date.now() - data.birthday.toDate()) / (1000 * 60 * 60 * 24 * 365)) : 16
    
                    return ({
                    key: data.uid,
                    value: data,
                    commonExplores: commonExplores,
                    age: age,
                    itemsUploaded: itemsUploaded
                    })
        
                }));

                const currentUserIndex = dataArray.findIndex(profile => profile.key === this._auth.userProfileSnapshot.uid);
                const currentUserInfo = dataArray[currentUserIndex];
                console.log(currentUserInfo)
                // make sure the current user is not in the recommended profiles
                if (currentUserIndex !== -1) {
                    dataArray.splice(currentUserIndex, 1);
                }
    
                dataArray.sort((a, b) => {
                    if (a.commonExplores > b.commonExplores) {
                      return -1;
                    } else if (a.commonExplores < b.commonExplores) {
                      return 1;
                    } else {
                      const ageDiffA = Math.abs(a.age - currentUserInfo.age);
                      const ageDiffB = Math.abs(b.age - currentUserInfo.age);
                      if (ageDiffA < ageDiffB) {
                        return -1;
                      } else if (ageDiffA > ageDiffB) {
                        return 1;
                      } else {
                        const itemsDiffA = Math.abs(a.itemsUploaded - currentUserInfo.itemsUploaded);
                        const itemsDiffB = Math.abs(b.itemsUploaded - currentUserInfo.itemsUploaded);
                        if (itemsDiffA < itemsDiffB) {
                          return -1;
                        } else if (itemsDiffA > itemsDiffB) {
                          return 1;
                        } else {
                          return 0;
                        }
                      }
                    }
                  });
    
                this.recommendedProfiles = dataArray.slice(0, 3);

                // save to localStorage
                localStorage.setItem(this.recommendedProfilesStorageKey, JSON.stringify({
                    recommendedProfiles: this.recommendedProfiles,
                    timestamp: Date.now()
                }));
                ;

                console.log(this.recommendedProfiles)
                return dataArray.slice(0, 3);
            }
        });
    }

    reloadRecommendedProfiles(): void {
        // clear localStorage of recommended profiles, and call loadRecommendedProfiles again
        localStorage.removeItem(this.recommendedProfilesStorageKey);
        this.recommendedProfiles = [];
        this.loadRecommendedProfiles();
    }

    navigateToExploreTopic(topic): void {
        this._router.navigate(['/explore/' + topic]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle the sidebar
     *
     * @param name
     */
    toggleSidebar(name): void {
        this._fuseSidebarService.getSidebar(name).toggleOpen();
    }

    startFocus(topic: any): void {
        this._helper.showProgressSpinner();
        const newFocus = firebase.functions().httpsCallable('focus-startNewFocus');
        const newFocusId = this._firestore.createId();
        newFocus({
            uid: this._auth.userProfileSnapshot.uid,
            topic: topic.name,
            focusId: newFocusId
        }).then((ret) => {
            // Read result of the Cloud Function.
            const result = ret as any;
            if (result.data && result.data.data) {
                if (result.data.data.error) {
                    this.displayNewFocusError(result.data.data.message);
                } else {
                    this._helper.hideProgressSpinner();
                    this._auth.userProfileSnapshot = result.data.data.profile;
                    this._router.navigate(['/focus/' + newFocusId]);
                }
            } else {
                this.displayNewFocusError();
            }
        }).catch(() => {
            this.displayNewFocusError();
        });
    }

    displayNewFocusError(message = null): void {
        this._helper.hideProgressSpinner();
        if (message) {
            Swal.fire('Error creating new focus', message,
                'error');
        } else {
            Swal.fire('Error creating new focus', 'There was an error processing your request. Let Will know at will@theksociety.com.',
                'error');
        }
    }

    // callCohorts(): void {
    //     const synthesizeCohorts = firebase.functions().httpsCallable('profiles-synthesizeCohorts');
    //     synthesizeCohorts(null).then((result) => {
    //         // Read result of the Cloud Function.
    //         console.log(result);
    //     });
    // }
}

