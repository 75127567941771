<div class="notification-snack" fxFlex fxFill fxLayout="row" *ngIf="notification">
    <div class="avatar-container">
        <img class="avatar" src="{{notification.notification.icon}}" alt="">
    </div>
    <div class="notification-details clickable" (click)="clearAndNavigate()" fxLayout="column" fxLayoutAlign="start left">
        <h3 matLine>{{notification.notification.title}}</h3>
        <html-viewer matLine [content]="notification.notification.body"></html-viewer>
    </div>
    <div class="notification-actions" fxLayout="row" fxLayoutAlign="start left">
        <button mat-icon-button (click)="clearNotification()">
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
</div>