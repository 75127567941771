import {NgModule} from '@angular/core';

import {FuseSharedModule} from '@fuse/shared.module';
import {MatMenuModule} from '@angular/material/menu';
import {EditorService} from './editor/editor.service';
import {EditorComponent} from './editor/editor.component';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {HtmlViewerComponent} from './html-viewer/html-viewer.component';
import {MatListModule} from '@angular/material/list';
import {ReplaceEmojisPipe} from './pipes/emojis/emoji.pipe';
import {QuillModule} from 'ngx-quill';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {AngularResizedEventModule} from 'angular-resize-event';
import {NgTippyModule} from 'angular-tippy';


@NgModule({
    declarations: [
        EditorComponent,
        ReplaceEmojisPipe,
        HtmlViewerComponent
    ],
    imports: [
        QuillModule.forRoot(),
        MatIconModule,
        MatButtonModule,
        AngularResizedEventModule,
        NgTippyModule,

        FuseSharedModule,
        MatListModule,
        MatMenuModule,
        PickerModule,
    ],
    exports: [
        EditorComponent,
        ReplaceEmojisPipe,
        HtmlViewerComponent
    ],
    providers: [ReplaceEmojisPipe, EditorService]
})

export class HtmlModule {
}
