import {Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {map, startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {fuseAnimations} from '@fuse/animations';
import {CONSTANTS} from '../../../data/string-constants';

@Component({
    selector: 'topic-tags-selector',
    templateUrl: './topic-tags-selector.component.html',
    styleUrls: ['./topic-tags-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class TopicTagsSelectorComponent {
    @Input()
    itemForm;
    @Input()
    tagInputString = 'tags';
    @Input()
    protectedTag = null;
    @Input()
    placeholder = 'Enter topic...';
    @Input()
    possibleTags = CONSTANTS.TAGS;
    @Input()
    label = 'Tags';

    topicCtrl = new FormControl();
    filteredTopicTags: Observable<string[]>;
    separatorKeysCodes: number[] = [ENTER];

    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;

    constructor() {

        this.filteredTopicTags = this.topicCtrl.valueChanges.pipe(
            startWith(null),
            map((tag: string | null) => tag ? this._filterTags(tag) : this._basicFilterTags()));
    }

    addTag(event: MatChipInputEvent): void {
        const input = event.input;

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.topicCtrl.setValue(null);
    }

    removeTag(tag: string): void {
        const index = this.itemForm.controls[this.tagInputString].value.indexOf(tag);

        if (index >= 0) {
            this.itemForm.controls[this.tagInputString].value.splice(index, 1);
        }
    }

    tagSelected(event: MatAutocompleteSelectedEvent): void {
        if (this.itemForm.controls[this.tagInputString].value.indexOf(event.option.viewValue) === -1) {
            this.itemForm.controls[this.tagInputString].value.push(event.option.viewValue);
            this.tagInput.nativeElement.value = '';
            this.topicCtrl.setValue(null);
        }
    }

    private _basicFilterTags(): string[] {
        const self = this;
        return this.possibleTags.filter(tag => {
            return (self.itemForm.controls[this.tagInputString].value.indexOf(tag) === -1);
        });
    }

    private _filterTags(value: string): string[] {
        const self = this;
        const filterValue = value.toLowerCase();
        return this.possibleTags.filter(tag => {
            return (tag.toLowerCase().indexOf(filterValue) === 0 && self.itemForm.controls[this.tagInputString].value.indexOf(tag) === -1);
        });
    }
}
