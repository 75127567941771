import {Component, ElementRef, Injectable} from '@angular/core';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {BehaviorSubject, merge as rxjsMerge, Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from './auth/auth.service';
import {EmojiService} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {NgTippyService} from 'angular-tippy';
import {AngularFireDatabase} from '@angular/fire/database';
import {filter, map, take, takeUntil} from 'rxjs/operators';

const today = (new Date()).getTime();
const msInMinute = 60 * 1000;
const msInHour = 60 * 60 * 1000;
const msInDay = 24 * 60 * 60 * 1000;
const unnecessaryKeys = ['type', 'status', 'created'];

const shortNameExceptionLookup = {
    '8ball': 'n8ball',
    '100': 'n100',
    '1234': 'n1234',
    '+1': 'plus1',
    '-1': 'minus1'
};

const PROFILELOOKUP = 'tks-life-profile-lookup';

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    public viewContainer: ElementRef;

    public profileLookupSnapshot;
    //@ts-ignore
    public profileLookupReady = new BehaviorSubject<boolean>(this.profileLookupSnapshot != null);
    public profileLookupObservable;

    private profileStatuses = {};

    private routerEvents = new BehaviorSubject<any>(null);

    public topicLookupSnapshot;
    public topicLookupObservable;
    //@ts-ignore
    public topicLookupReady = new BehaviorSubject<boolean>(this.topicLookupSnapshot != null);

    private dialogRef = null;

    // Emoji helpers
    public emojiPickerContainer = null;
    private emojiPickerTrigger = null;
    private currentEmojiReactionContainer = null;

    // Profile viewer helpers
    public profileViewerHelper;
    public profileViewerProfile;
    public currentProfileViewerTippy;

    public matIconTranslator = {
        video: 'play_circle_outline',
        article: 'library_books',
        paper: 'description',
        course: 'school',
        website: 'web_asset'
    };

    public timeZoneHelper = {
        'Toronto': -5,
        'Ottawa': -5,
        'Boston': -5,
        'New York': -5,
        'Las Vegas': -8,
        'San Francisco': -8,
        'Vancouver': -8,
        'Seattle': -8,
        'Los Angeles': -8,
        'Virtual': -5,
    };

    constructor(
        private domSanitizer: DomSanitizer,
        private _firestore: AngularFirestore,
        private _database: AngularFireDatabase,
        private _router: Router,
        public _tippy: NgTippyService,
        private _dialog: MatDialog,
        private _auth: AuthService,
        private emojiService: EmojiService
    ) {
        const self = this;
        try {
            localStorage.getItem(PROFILELOOKUP);
            self.profileLookupSnapshot = JSON.parse(localStorage.getItem(PROFILELOOKUP));
            self.profileLookupReady.next(true);
        } catch {
            console.log('Couldn\'t get profiles from local storage.');
        }

        this.checkAPIVersion();

        this.profileLookupObservable = this._firestore.collection('profileLookupCollection').snapshotChanges().pipe(
                map(actions => actions.map(queryResult => {
                    const lookupData = queryResult.payload.doc.data() as any;
                    return lookupData;
                })))
            .subscribe((profileLookup) => {
                self.profileLookupSnapshot = {};
                for (const sublist of profileLookup) {
                    const subKeys = Object.keys(sublist);
                    for (const key of subKeys) {
                        self.profileLookupSnapshot[key] = sublist[key];
                    }
                }
                localStorage.setItem(PROFILELOOKUP, JSON.stringify(self.profileLookupSnapshot));
                self.profileLookupReady.next(true);
            });

        this.topicLookupObservable = this._firestore.doc('helpers/topicsLookup').valueChanges();
        this.topicLookupObservable.subscribe(topicsLookup => {
            self.topicLookupSnapshot = topicsLookup;
            self.topicLookupReady.next(true);
        });

        this._router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationStart)
            )
            .subscribe(event => {
                this.profileStatuses = {};
                this.routerEvents.next(event);
            });
    }

    ////////////// STATIC HELPERS ///////////////////

    static getYouTubeEmbedLink(url): any {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);

        if (match && match[2].length === 11) {
            return 'https://www.youtube.com/embed/' + match[2];
        } else {
            return 'error';
        }
    }

    static calculateFocusPercentageComplete(thisFocus): number {
        let totalCount = 0;
        let completedCount = 0;
        for (let j = 0; j < 4; ++j) {
            let keys = Object.keys(thisFocus.progress[j]);
            for (const unnecessaryKey of unnecessaryKeys) {
                keys = keys.filter(e => e !== unnecessaryKey);
            }

            thisFocus.progress[j].completedCount = 0;
            for (const key of keys) {
                totalCount++;

                if (thisFocus.progress[j][key] !== '') {
                    completedCount++;
                    thisFocus.progress[j].completedCount++;
                }
            }
        }
        return Math.ceil((completedCount / totalCount) * 100);
    }

    static addPercentagesToFocusesAndReturnArray(focuses): any[] {
        const focusTopicKeys = Object.keys(focuses);
        const focusList = [];
        for (const focusTopicKey of focusTopicKeys) {
            const thisFocus = focuses[focusTopicKey];
            thisFocus['percentageComplete'] = HelperService.calculateFocusPercentageComplete(thisFocus);
            focusList.push(thisFocus);
        }
        return focusList;
    }

    emojiBackgroundSheet = (set: string, sheetSize: number) => `./assets/google.5.0.1.png`;

    ////////////// VERSION HELPERS ///////////////////

    async checkAPIVersion(): Promise<void> {
        this._firestore.doc('version/global').valueChanges().subscribe(versionRef => {
            if (versionRef['version'] !== '1.0.3') {
                this.dialogRef = this._dialog.open(UpgradeDialogComponent, {
                    disableClose: true
                });
            }
        });
    }

    ////////////// PROFILE HELPERS ///////////////////
    isProfileLookupReady(): Observable<boolean> {
        return this.profileLookupReady.asObservable();
    }

    isTopicLookupReady(): Observable<boolean> {
        return this.topicLookupReady.asObservable();
    }

    encodeUri(url): string {
        return encodeURI(url.replace(/'/g, '%27'));
    }

    ////////////// EMOJI HELPERS ///////////////////
    setEmojiPicker(emojiPickerContainer): void {
        this.emojiPickerContainer = emojiPickerContainer;
    }

    callEmojiPickerCallback(ev): void {
        if (this.emojiPickerTrigger) {
            this.emojiPickerTrigger.closeMenu();
        }
        this.currentEmojiReactionContainer.addReactionToContainer(ev.emoji.shortName);
    }

    setEmojiPickerCallback(reactionContainer, trigger = null): void {
        this.currentEmojiReactionContainer = reactionContainer;
        this.emojiPickerTrigger = trigger;
    }

    ////////////// PROFILE VIEWER HELPERS /////////////
    setProfileViewerTrigger(ref, show): void {
        const tippy = this._tippy.init(ref, {
            content: this.profileViewerHelper.nativeElement,
            interactive: true,
            trigger: 'click',
            theme: 'transparent',
            animateFill: false,
            onShow: (instance) => {
                this.profileViewerHelper.nativeElement.style.display = 'block';
                this.currentProfileViewerTippy = instance;
                function closeCurrentTippy(): void {
                    instance.hide();
                    document.removeEventListener('scroll', closeCurrentTippy, true);
                }
                document.addEventListener('scroll', closeCurrentTippy, true);
            },
            onHidden: (instance) => {
                if (this.currentProfileViewerTippy === instance) {
                    this.setProfileViewerProfile(null);
                }
                tippy.destroy();
            }
        });

        if (tippy && show) {
            tippy.show();
        }
    }

    closeCurrentProfileViewerTippy(): void {
        this.currentProfileViewerTippy.hide();
    }

    setProfileViewerHelper(profileViewerHelper): void {
        this.profileViewerHelper = profileViewerHelper;
    }

    setProfileViewerProfile(profileViewerProfile): void {
        this.profileViewerProfile = profileViewerProfile;
    }

    ////////////// ACTIVITY HELPERS ///////////////////

    getCreationTimeString(created, onlyDays = false): string {
        let retString = '';
        const theDate = this.formatDate(created);
        if (theDate == null) {
            return 'Just now';
        }

        const dateDifference = today - theDate.getTime();
        if (dateDifference > msInHour) {
            if (dateDifference > msInDay) {
                const numDays = Math.floor(dateDifference / msInDay);
                if (!onlyDays && numDays > 7) {
                    return theDate.toDateString();
                }
                if (numDays === 1) {
                    retString = '1 day ago';
                } else {
                    retString = numDays + ' days ago';
                }
            } else {
                const numHours = Math.floor(dateDifference / msInHour);
                if (numHours === 1) {
                    retString = '1 hour ago';
                } else {
                    retString = numHours + ' hours ago';
                }
            }
        } else if (dateDifference > msInMinute) {
            const numMins = Math.floor(dateDifference / msInMinute);
            if (numMins === 1) {
                retString = '1 minute ago';
            } else {
                retString = numMins + ' minutes ago';
            }
        } else {
            retString = 'Just now';
        }
        return retString;
    }

    getFirstNameInitials(name): string {
        if (name) {
            return name.match(/\b(\w)/g).join('');
        } else {
            return ''; // Just something to save to
        }
    }

    getMemberStatus(id): any {
        // console.log('called with id: ' + id)
        if (!this.profileStatuses[id]) {
            this.profileStatuses[id] = this._firestore.doc(`statuses/${id}`).valueChanges();
        }

        return this.profileStatuses[id];
    }

    getMemberInfo(id): any {
        if (id === 'system') {
            return {
                avatar: 'assets/images/avatars/tks-helper.png',
                bio: 'I\'m like the most helpful parts of Tommy and Will, but worse.',
                email: null,
                firstname: null,
                lastname: null,
                name: 'TKS Helper',
                processInfo: {},
                programInfo: {
                    role: 'Admin',
                    city: 'Virtual'
                },
                searchstring: null,
                status: {
                    state: 'online'
                },
                uid: 'system'
            };
        } else if (this.profileLookupSnapshot[id]) {
            return this.profileLookupSnapshot[id];
        } else {
            return {
                avatar: 'https://tks.life/assets/images/avatars/profile.png',
                name: 'Deleted Profile',
                programInfo: {
                    city: 'Unknown',
                    program: '',
                    role: 'Unknown'
                },
                status: {
                    state: 'offline'
                }
            };
        }
    }

    getMemberInitials(id): string {
        return this.getFirstNameInitials(this.getMemberInfo(id).name);
    }

    getContactName(uid): any {
        if (uid === this._auth.userProfileSnapshot.uid) {
            return 'You';
        }
        else {
            return this.getMemberInfo(uid).name;
        }
    }

    getReactionList(list: any, reaction): string {
        let nameList = '';
        for (let i = 0; i < list.length; ++i) {
            if (i !== list.length - 1) {
                nameList += list[i].name + ', ';
            } else {
                if (list.length > 1) {
                    nameList += ' and ';
                }
                nameList += list[i].name;
            }
        }
        return nameList + ' reacted with :' + reaction + ':';
    }

    getNumCompletedFocuses(): number {
        if (this._auth.userProfileSnapshot.focuses) {
            return Object.keys(this._auth.userProfileSnapshot.focuses).filter(topicName => {
                const focus = this._auth.userProfileSnapshot.focuses[topicName];
                return focus.isComplete && focus.finishDate;
            }).length;
        } else {
            return 0;
        }
    }

    getNativeEmojiFromShortname(shortName): any {
        const data = this.emojiService.getSanitizedData(shortName);
        if (data) {
            return data.native;
        } else {
            const legacyReactionTranslator = {
                fire: '🔥',
                thumbsup: '👍',
                smile: '😁',
                unicorn: '🦄',
                love: '❤️',
                handsup: '🙌'
            };
            return legacyReactionTranslator[shortName];
        }
    }

    getEmojiShortnameFromUnicode(unicode): any {
        const data = this.emojiService.getSanitizedData(unicode);
        if (data) {
            return data.shortName;
        } else {
            return 'unknown';
        }
    }

    getEmojiShortName(shortName): string {
        if (Object.keys(shortNameExceptionLookup).indexOf(shortName) === -1) {
            return shortName;
        } else {
            return shortNameExceptionLookup[shortName];
        }
    }

    validateYouTubeUrl(c: any): any {
        let isValid = false;
        const url = c.value;

        if (url !== undefined && url != null && url !== '') {

            // YouTube link regex
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            const match = url.match(regExp);

            if (match && match[2].length === 11) {
                isValid = true;
            }
        }
        return isValid ? null : {
            validateYouTubeUrl: {
                valid: false
            }
        };
    }

    isMobile(): boolean {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    sanitizeYouTubeUrlOrNull(url): any {
        if (!this.validateYouTubeUrl({value: url})) {
            return this.sanitizeYouTubeUrl(url);
        } else {
            return null;
        }
    }

    sanitizeYouTubeUrl(url): any {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(HelperService.getYouTubeEmbedLink(url));
    }

    sanitizeUrlGeneral(url): any {
        return this.domSanitizer.bypassSecurityTrustUrl(url);
    }

    sanitizeUrlResource(url): any {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }

    dataURLtoFile(dataurl, filename): File {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }

    // TODO: Suggested: rename to avoid confusion with angular's formatDate(), used in getDateTimeString()
    formatDate(date, failsafe = false): Date {
        try {
            if (typeof date === 'object') {
                return date.toDate();
            } else {
                return new Date(Number(date));
            }
        } catch {
            if (failsafe) {
                return new Date(Date.now());
            } else {
                return null;
            }
        }
    }

    printDateString(date): string {
        const dateObj = this.formatDate(date);
        if (dateObj) {
            return dateObj.toLocaleDateString();
        } else {
            return 'N/A';
        }
    }

    trackByKey(index, item): number {
        return item.key;
    }

    navigateToUser(uid): void {
        if (this.profileLookupSnapshot && this.profileLookupSnapshot[uid]) {
            this._router.navigate(['/profile/' + this.profileLookupSnapshot[uid].searchstring], {fragment: 'about'});
        }
    }

    navigateToChat(id): void {
        this._router.navigate(['/chat/chats/' + id]);
    }

    navigateToActivity(id): void {
        this._router.navigate(['/community/activity/' + id]);
    }

    navigateToPath(path): void {
        this._router.navigate([path]);
    }

    navigateToFocusObject(focusId, view = false): void {
        if (view) {
            this._router.navigate(['/focus/view/' + focusId]);
        } else {
            this._router.navigate(['/focus/' + focusId]);
        }
    }

    compareArrayEquality(arr1, arr2): boolean {
        // if the other array is a falsy value, return
        if (!arr2) {
            return false;
        }

        // compare lengths - can save a lot of time
        if (arr1.length !== arr2.length) {
            return false;
        }


        for (let i = 0, l = arr1.length; i < l; i++) {
            // Check if we have nested arrays
            if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
                // recurse into the nested arrays
                if (!arr1[i].equals(arr2[i])) {
                    return false;
                }
            } else if (arr1[i] !== arr2[i]) {
                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false;
            }
        }
        return true;
    }

    getProgressColour(percentage): string {
        let colour = 'warn';
        if (percentage > 33) {
            colour = 'primary';
        }
        if (percentage > 75) {
            colour = 'accent';
        }
        return colour;
    }

    showProgressSpinner(): void {
        this.dialogRef = this._dialog.open(TKSLoadingOverlayComponent, {
            panelClass: 'transparent',
            disableClose: true
        });
    }

    hideProgressSpinner(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }

    getLocalTimeForProfile(city: string): string {
        if (city === 'Virtual') {
            return 'Coming someday';
        }
        const date = new Date();
        return this.getFancyDate(date.getTime() + (date.getTimezoneOffset() * 60 * 1000) + (this.timeZoneHelper[city] * 3600000));
    }

    getFancyDate(timestamp: number): string {
        const date = new Date(timestamp);
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let hours = date.getHours();
        let timePeriod = 'am';
        if (hours > 12) {
            hours -= 12;
            timePeriod = 'pm';
        } else if (hours === 0) {
            hours = 12;
        }

        let minutes = date.getMinutes().toString();
        if (date.getMinutes() < 10) {
            minutes = '0' + minutes;
        }
        return days[date.getDay()] + ' ' + date.getDate() + ', ' + date.getFullYear() + ' at ' + hours + ':' + minutes + timePeriod;
    }
}

@Component({
    selector: 'tks-loading-overlay',
    template:
        '<div class="logo">\n' +
        '   <img style="filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2))" class="dropshadow" width="128" src="./assets/images/logos/TKSGIFDark.gif">\n' +
        '</div>'
})
export class TKSLoadingOverlayComponent {
    constructor() {
    }
}

@Component({
    selector: 'upgrade-dialog',
    template:
        '<h1 mat-dialog-title>Update required</h1>' +
        '<div mat-dialog-content>Click below to load a necessary update for TKS Life.</div>' +
        '<div mat-dialog-actions>' +
        '  <button mat-button (click)="upgradeVersion()">Update</button>' +
        '</div>'
})
export class UpgradeDialogComponent {
    constructor() {
    }

    upgradeVersion(): void {
        //@ts-ignore
        location.reload(true);
    }
}
