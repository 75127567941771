<div fxLayout="column" fxFlex fusePerfectScrollbar>

    <mat-list class="date" cdkFocusRegionStart>

        <h3 matSubheader cdkFocusInit>
            <span>Today</span>
        </h3>

        <div class="secondary-text mat-display-1 mb-0 p-16">
            <div class="mb-12">
                {{date | date:'EEEE'}}, {{date | date:'d'}} {{date | date:'MMMM'}}
            </div>
<!--            <div fxLayout="row" fxLayoutAlign="start start">-->
<!--                <span> {{date | date:'d'}}</span>-->
<!--                <span class="mat-subheading-1">th</span>-->
<!--                <span> {{date | date:'MMMM'}}</span>-->
<!--            </div>-->
        </div>
    </mat-list>

    <mat-divider cdkFocusRegionEnd></mat-divider>

    <mat-list class="notification-list" fxLayout="column">
        <h3 matSubheader fxFlex fxLayoutAlign="space-between center">
            <span>Notifications</span>
            <button mat-icon-button [matTooltip]="'Clear all notifications'" (click)="clearAllNotifications()">
                <mat-icon class="secondary-text">clear_all</mat-icon>
            </button>
        </h3>

        <p *ngIf="!notificationCount" class="no-notifications secondary-text">No new notifications.</p>

        <mat-list-item *ngFor="let notification of notifications | keyvalue | orderBy: 'value.timestamp':true; trackBy: _helper.trackByKey">
            <div class="notification" fxFlex fxFill fxLayout="row">
                <div class="avatar-container">
                    <img class="avatar" src="{{notification.value.notification.icon}}" alt="">
                </div>
                <div class="notification-details clickable" (click)="clearAndNavigate(notification.key, notification.value)" fxLayout="column" fxLayoutAlign="start left">
                    <h3 matLine>{{notification.value.notification.title}} <span class="smaller secondary-text">{{_helper.getCreationTimeString(notification.value.timestamp)}}</span></h3>
                    <html-viewer matLine [content]="notification.value.notification.body"></html-viewer>
                </div>
                <div class="notification-actions" fxLayout="row" fxLayoutAlign="start left">
                    <button mat-icon-button [matTooltip]="'Remove notification'" (click)="clearNotification(notification.key)">
                        <mat-icon class="secondary-text">cancel</mat-icon>
                    </button>
                </div>
            </div>
        </mat-list-item>
    </mat-list>


<!--    <mat-list>-->
<!--        <h3 matSubheader>-->
<!--            <span>Notes</span>-->
<!--        </h3>-->

<!--        <mat-list-item *ngFor="let note of notes">-->
<!--            <h3 matLine>{{note.title}}</h3>-->
<!--            <p matLine class="secondary-text">{{note.detail}}</p>-->
<!--        </mat-list-item>-->
<!--    </mat-list>-->

<!--    <mat-divider></mat-divider>-->

<!--    <mat-list>-->
<!--        <h3 matSubheader>-->
<!--            <span>Quick Settings</span>-->
<!--        </h3>-->

<!--        <mat-list-item>-->
<!--            <mat-slide-toggle fxFlex class="mat-primary" [(ngModel)]="settings.notify" aria-label="Notifications"-->
<!--                              labelPosition="before">-->
<!--                <h3>Notifications</h3>-->
<!--            </mat-slide-toggle>-->
<!--        </mat-list-item>-->

<!--        <mat-list-item>-->
<!--            <mat-slide-toggle fxFlex class="mat-accent" [(ngModel)]="settings.cloud" aria-label="Cloud"-->
<!--                              labelPosition="before">-->
<!--                <h3>Cloud Sync</h3>-->
<!--            </mat-slide-toggle>-->
<!--        </mat-list-item>-->

<!--        <mat-list-item>-->
<!--            <mat-slide-toggle fxFlex class="mat-warn" [(ngModel)]="settings.retro" aria-label="Retro Thrusters"-->
<!--                              labelPosition="before">-->
<!--                <h3>Retro Thrusters</h3>-->
<!--            </mat-slide-toggle>-->
<!--        </mat-list-item>-->
<!--    </mat-list>-->

</div>