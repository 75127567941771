export const locale = {
    lang: 'en',
    data: {
        'NAV':
            {
                'PROGRESS': 'Your Progress',
                'PROFILE': {
                    'TITLE': 'Profile',
                    'BADGE': '0'
                },
                'DASHBOARD': {
                    'TITLE': 'Dashboard',
                    'BADGE': '0'
                },
                'GROWTH': 'Growth',
                'EXPLORE': {
                    'TITLE': 'Explore',
                    'BADGE': '0'
                },
                'FOCUS': {
                    'TITLE': 'Focus',
                    'BADGE': '0'
                },
                'FOCUS_RESOURCES': {
                    'TITLE': 'Focus Resources',
                    'BADGE': '0'
                },
                'POWERUPS': {
                    'TITLE': 'Power Ups',
                    'BADGE': '0'
                },
                'MENTORS': {
                    'TITLE': 'Mentors',
                    'BADGE': '0'
                },
                'INTERNSHIPS': {
                    'TITLE': 'Internships',
                    'BADGE': '0'
                },
                'COMMUNITY': 'Community',
                'RESOURCES': 'Resources',
                'ACTIVITY': {
                    'TITLE': 'Activity',
                    'BADGE': '0'
                },
                'TKS_MAPS': {
                    'TITLE': 'TKS Maps',
                    'BADGE': '0'
                },
                'PLAYBOOKS': {
                    'TITLE': 'Playbooks',
                    'BADGE': '0'
                },
                'EVENTS': {
                    'TITLE': 'Events',
                    'BADGE': '0'
                },
                'COHORTS': {
                    'TITLE': 'Cohorts',
                    'BADGE': '0'
                },
                'UPDATES': {
                    'TITLE': 'Updates',
                    'BADGE': '0'
                },
                'APPLICATIONS': 'Applications',
                'DIRECTORS': 'Directors',
                'DIRECTOR-DASHBOARD': {
                    'TITLE': 'Dashboard',
                    'BADGE': '0'
                },
                'ACTION-ITEMS': {
                    'TITLE': 'Action Items',
                    'BADGE': '0'
                },
                'ADMIN': 'Admin',
                'ADMIN-DASHBOARD': {
                    'TITLE': 'Dashboard',
                    'BADGE': '0'
                },
                'BRAINDATES': {
                    'TITLE': 'Braindates',
                    'BADGE': '0'
                },
                'CHAT': {
                    'TITLE': 'Chat',
                    'BADGE': '0'
                },
                'SEARCH': {
                    'TITLE': 'Search',
                    'BADGE': '0'
                }
            }
    }
};
