<mat-form-field appearance="outline">
    <mat-label>{{label}}</mat-label>
    <mat-chip-list #chipList aria-label="Topic Tag Selection">
        <mat-chip
            *ngFor="let tag of itemForm.controls[tagInputString].value"
            [selectable]="true"
            [removable]="protectedTag ? tag !== protectedTag : true"
            (removed)="removeTag(tag)">
            {{tag}}
            <mat-icon matChipRemove *ngIf="tag !== protectedTag">cancel</mat-icon>
        </mat-chip>
        <input
            placeholder="{{placeholder}}"
            #tagInput
            [formControl]="topicCtrl"
            [matAutocomplete]="tagAutocomplete"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addTag($event)">
    </mat-chip-list>
    <mat-autocomplete #tagAutocomplete="matAutocomplete" [autoActiveFirstOption]="true"
                      (optionSelected)="tagSelected($event)">
        <mat-option *ngFor="let autoTag of filteredTopicTags | async" [value]="autoTag">
            {{autoTag}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

