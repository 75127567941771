import {Injectable} from '@angular/core';

import * as Quill from 'quill';
import '../../../../assets/libraries/quill-mention/quill.mention.esm';
import BlotFormatter from 'quill-blot-formatter';
import {EmojiSearch} from '@ctrl/ngx-emoji-mart';
import {AuthService} from '../../../auth/auth.service';
import {HelperService} from '../../../helper.service';
import {ReplaceEmojisPipe} from '../pipes/emojis/emoji.pipe';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import MagicUrl from 'quill-magic-url';
import {EmojiService} from '@ctrl/ngx-emoji-mart/ngx-emoji';
const Base = Quill.import('blots/embed');

const icons = Quill.import('ui/icons');

icons['bold'] = `<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">format_bold</mat-icon>`;
icons['italic'] = `<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">format_italic</mat-icon>`;
icons['strike'] = `<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">strikethrough_s</mat-icon>`;
icons['code'] = `<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">code</mat-icon>`;
icons['code-block'] = `<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">short_text</mat-icon>`;
icons['blockquote'] = `<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">format_quote</mat-icon>`;
icons['link'] = `<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">insert_link</mat-icon>`;
icons['list']['bullet'] = `<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">format_list_bulleted</mat-icon>`;
icons['list']['ordered'] = `<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">format_list_numbered</mat-icon>`;
icons['image'] = `<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">image</mat-icon>`;

const Delta = Quill.import('delta');
const featureRegex = [
    {
        search: /<img src="data:image\/gif;base64,R0lGODlhAQABAIAAAAAAAP\/\/\/yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="emoji [\w]+">/ig,
        replace: ' '
    }, // Emoji
    // {search: /(?<!^)(<p><br><\/p>)/ig, replace: '\n'}, // Empty line not at start
    // {search: /(?<!^)(<p>|<blockquote>)/ig, replace: '\n'}, // New paragraph not at start
    {search: /^(<[uo]l><li><br><\/li>)/ig, replace: ''}, // Empty list item at start
    {search: /^(<[uo]l><li>)/ig, replace: ''}, // List at start
    {search: /^(<p><br><\/p>)/ig, replace: ''}, // Empty line at start
    {search: /<li><br><\/li>/ig, replace: '\n'}, // Empty list item
    {search: /<(br|li)>/ig, replace: '\n'}, // Line breaks and list items
    {search: /<([^>]+>)/ig, replace: ''} // Any remaining HTML tags
];

class EmojiBlot extends Base {
    static blotName = 'emoji';
    static tagName = 'span';
    static className = 'quill-emoji-blot';

    static create(data): any {
        return data;
    }

    static value(domNode): any {
        return domNode;
    }

}

Quill.register({
    'formats/emoji': EmojiBlot
});

Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register('modules/magicUrl', MagicUrl);

@Injectable()
export class EditorService {

    formats = [
        'bold',
        'italic',
        'strike',
        'code',
        'code-block',
        'blockquote',
        'list',
        'image',
        'mention',
        'span',
        'tag',
        'link',
        'emoji'
    ];
    set = 'google' as 'google';
    atValues = [];
    currentEditor = null;
    modules = {
        clipboard: {
            matchVisual: false
        },
        toolbar: [
            ['bold', 'italic', 'strike'],
            [{list: 'ordered'}, {list: 'bullet'}],
            ['code', 'code-block', 'blockquote', 'link', 'image'],
        ],
        mention: {
            allowedChars: /^[^\s]([-+\wÅÄÖåäö]*\s?[-+\wÅÄÖåäö]*){0,2}$/,
            mentionDenotationChars: ['@', ':'],
            blotName: {'@': 'mention', ':': 'emoji'},
            source: (searchTerm, renderList, mentionChar) => {
                let values;

                if (mentionChar === '@') {
                    values = this.atValues;

                    if (searchTerm.length === 0) {
                        renderList(values.slice(0, 15), mentionChar);
                    } else {
                        const matches = [];
                        // tslint:disable-next-line:prefer-for-of
                        for (let i = 0; i < values.length; i++) {
                            if (
                                // tslint:disable-next-line:no-bitwise
                                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
                            ) {
                                matches.push(values[i]);
                            }
                            if (matches.length >= 15) {
                                break;
                            }
                        }
                        matches.sort((a, b) => (a['value'].toLowerCase().indexOf(searchTerm.toLowerCase()) > b['value'].toLowerCase().indexOf(searchTerm.toLowerCase()) ? 1 : -1));
                        renderList(matches, mentionChar);
                    }
                } else if (mentionChar === ':') {
                    let emojis = this.emojiSearch.search(searchTerm, () => true, 5);
                    if (emojis && emojis.length) {
                        emojis = emojis.filter(emoji => emoji.shortName.indexOf('flag') === -1);
                    }
                    renderList(emojis, mentionChar);
                }


            },
            onSelect: (item, insertItem) => {
                this.currentEditor.preventNextSubmission();
                if (item.denotationChar === '@') {
                    insertItem({renderItem: item, denotationChar: item.denotationChar});
                } else if (item.denotationChar === ':') {
                    insertItem({renderItem: this.emojiPipe.replaceEmojiWithSpan(this.emojiService.getSanitizedData(item.id).native,
                            'google', 22, this._helper.isMobile()), denotationChar: item.denotationChar});
                }
            },
            renderItem: (item, mentionChar) => {
                if (mentionChar === '@') {
                    return '<div class="mention-list-item"><img class="avatar" src="' + (item.avatar || '/assets/images/avatars/profile.png') +
                        '"/><div class="name text-truncate">' + item.value + '</div><div class="info secondary-text text-truncate">' +
                        (item.programInfo && item.programInfo.program ? item.programInfo.program + (item.programInfo.role ? ' ' + item.programInfo.role : '')
                            + ' | ' : (item.programInfo && item.programInfo.role ? item.programInfo.role + ' | ' : '')) +
                        (item.programInfo && item.programInfo.city ? item.programInfo.city : '') + '</div></div>';
                } else if (mentionChar === ':') {
                    return '<span contenteditable="false" class="emoji-pipe-image" data-id="grinning" style="display: inline-block; ' +
                        'background-image: url(/assets/icons/emoji-png/22/emoji_u' +
                        item.unified.toLowerCase().split('-').filter(entry => entry !== 'fe0f').join('_') + '.png); ' +
                        'vertical-align: text-bottom; width: 21px; height: 21px; background-size: 100%;" tabindex="0">' +
                        '<span style="display: inline-block; overflow: hidden; text-indent: -100%; transform: scale(0); vertical-align: text-top; ' +
                        'white-space: nowrap; width: 1em;"><span>😀</span></span></span>' +
                        '<span style="margin-left: 7px;">:' + item.shortName + ':</span>';
                }

            }
        },
        history: {
            delay: 0
        },
        blotFormatter: {},
        magicUrl: true
    };

    _unsubscribeAll;

    constructor(public _auth: AuthService,
                public _helper: HelperService,
                private emojiService: EmojiService,
                private emojiSearch: EmojiSearch,
                private emojiPipe: ReplaceEmojisPipe) {
        this._unsubscribeAll = new Subject();
        this._helper.profileLookupReady.asObservable().pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((isReady) => {
            if (isReady) {
                this.atValues = Object.keys(this._helper.profileLookupSnapshot).filter(key => {
                    return (this._helper.getMemberInfo(key) && this._helper.getMemberInfo(key).name);
                }).map(key => {
                    return {
                        id: key,
                        value: this._helper.getMemberInfo(key).name,
                        ...this._helper.getMemberInfo(key)
                    };
                }).sort((a, b) => (a['value'] > b['value']) ? 1 : -1);
            }
        });

    }

    setCurrentEditor(editor): void {
        this.currentEditor = editor;
    }

    convertHtmlToText(html): string {
        if (!html) {
            return '';
        }

        let text = html;

        featureRegex.map((feature) => {
            while (text.match(feature.search)) {
                text = text.replace(feature.search, feature.replace);
            }
        });

        return text;
    }


    insertEmoji(component, unicodeEmoji): void {
        if (component.editor.isEnabled()) {
            const selection = component.editor.getSelection(true);
            const insert = this.emojiPipe.replaceEmojiWithSpan(unicodeEmoji, 'google', 22, this._helper.isMobile());

            const emojiDelta = new Delta()
                .retain(selection.index)
                .delete(selection.length)
                .insert({emoji: insert})
                .insert(' ');

            component.editor.updateContents(emojiDelta, Quill.sources.USER);
            component.editor.setSelection(selection.index + 2, 0, Quill.sources.SILENT);
            component.emojiAutoCompleteOptions = [];
        }
    }

}
