// services/auth-guard.service.ts
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

import { IpLookupService } from 'app/shared/services/ip-lookup.service';

@Injectable()
export class AuthGuard implements CanActivate {

    ipLocation: string;
    cityCoordinates: any;
    increaseCityName: string = undefined;
    decreaseCityName: string = undefined;

    constructor(
        private router: Router, 
        private authService: AuthService,
        private ipLookupService: IpLookupService,
        private _firestore: AngularFirestore
    ) { }
    canActivate(): boolean {
        // check user location when they move around the site. Only check if it's stale (cached location is too old) 
        try {
            if (this.ipLookupService.isLocationStale()) {
                // this definitely needs some refactoring. Built for Beta version of TKS Maps
                this.ipLookupService.getLocationInfo().subscribe(async (res: any) => {
                    this.ipLocation = res.city
                    if (res.city !== undefined) {
                        console.log('Saving city')
                        this.cityCoordinates = [res.longitude, res.latitude];
                        this.increaseCityName = res.city;

                        const profileDoc = this._firestore.doc(`profiles/${this.authService.userProfileSnapshot?.uid}`);
                        const tksMapsLocationsDoc = this._firestore.doc('helpers/tksMapsLocations');

                        await this._firestore.firestore.runTransaction(async (transaction) => {
                            const profileRef = profileDoc.ref;
                            const profileSnapshot = await transaction.get(profileRef);
                            if (profileSnapshot.exists) {
                                const lastLoginCity = profileSnapshot.get('lastLoginCity') || [];
                                const decreaseCityName = lastLoginCity[0] || undefined;

                                if (this.increaseCityName !== decreaseCityName) {
                                    console.log('Updating TKS Maps')
                                    const tksMapsLocationsRef = tksMapsLocationsDoc.ref;
                                    const tksMapsLocationsSnapshot = await transaction.get(tksMapsLocationsRef);
                                    let newTksMapsLocationsData = tksMapsLocationsSnapshot.data();
                                    const increaseCityFeature = newTksMapsLocationsData.features.findIndex(
                                        (feature, index) => feature.properties.name === this.increaseCityName
                                    );

                                    if (increaseCityFeature !== -1) {
                                        // console.log(newTksMapsLocationsData.features[increaseCityFeature])
                                        newTksMapsLocationsData.features[increaseCityFeature].properties.tksPopulation += 1;
                                        // console.log(newTksMapsLocationsData.features[increaseCityFeature])
                                        // console.log('Increased population of ', this.increaseCityName)
                                    } else {
                                        // city doesn't exist in tksMaps, add it
                                        newTksMapsLocationsData.features.push({
                                            type: 'Feature',
                                            properties: {
                                                name: this.increaseCityName,
                                                tksPopulation: 1,
                                            },
                                            geometry: {
                                                type: 'Point',
                                                coordinates: this.cityCoordinates
                                            }
                                        });
                                        console.log('Added ', this.increaseCityName)
                                    }
    
                                    if (decreaseCityName !== undefined) {
                                        newTksMapsLocationsData.features.find((feature) => {
                                            if (feature.properties.name === decreaseCityName) {
                                                feature.properties.tksPopulation -= 1;
                                                // console.log('Decreased population of ', decreaseCityName)
                                            }
                                        })
                                    }
    
                                    transaction.update(tksMapsLocationsRef, newTksMapsLocationsData);
                                    transaction.update(profileRef, {
                                        lastLoginCity: [this.ipLocation]
                                    })
    
                                    this.ipLookupService.cacheLocation({ city: this.ipLocation, timestamp: Date.now() });
                                    console.log('Updated TKS Maps')
                                } else {
                                    console.log('No change in city');
                                }
                            } else {
                                console.log('no profile found')
                            }
                        }).catch(error => {
                            console.log('Error running transaction document:', error);
                        });
                    }
                });
            } else {
                this.ipLocation = this.ipLookupService.getCity();
            }
            console.log("Stale location: ", this.ipLookupService.isLocationStale(), " Location: ", this.ipLocation)
        } catch (error) {
            console.log(error)
        }
        if ( this.authService.isLoggedIn ) {
            return true;
        }
        this.router.navigate(['/auth/login']);
        return false;
    }
}
