import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {fuseAnimations} from '../../../../@fuse/animations';

import * as Quill from 'quill';
import {AuthService} from '../../../auth/auth.service';
import {HelperService} from '../../../helper.service';
import {EditorService} from './editor.service';

@Component({
    selector: 'editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class EditorComponent implements OnChanges, OnDestroy {
    editor;
    text;
    html;

    visibleToolCount = 10;
    suppressSubmission = false;
    focused;

    myId = Date.now();

    @Input() bottomText: string = null;
    @Input() noSubmit = false;
    @Input() setInitialFocus = false;
    @Input() placeholder = 'Write a message...';
    @Input() existingHtml = null;
    @Input() textSubmitButton: string = null;
    @Input() customButtons: {
        matIconName: string,
        hoverText: string,
        callbackFn: (event, editor) => {}
    }[] = [];
    @Output() editorCreated = new EventEmitter();
    @Output() contentsChanged = new EventEmitter();
    @Output() submitContents = new EventEmitter();
    @Output() editorFocusedOn = new EventEmitter();

    @ViewChild('quillContainer') quillContainer: ElementRef;
    @ViewChild('buttonContainer') buttonContainer: ElementRef;

    constructor(
        public _auth: AuthService,
        public _helper: HelperService,
        public editorService: EditorService) {
        this.focused = this.setInitialFocus;
    }

    ngOnDestroy(): void {
    }

    created(editor): void {
        this.editor = editor;

        this.setOnFocusHandler();


        this.insertExistingTextIntoEditor(this.existingHtml);

        this.editorCreated.emit(this.editor);
        if (this.setInitialFocus) {
            this.editor.focus();
        }

    }

    setOnFocusHandler(): void {
        const self = this;
        const domEditor = document.getElementById(this.myId.toString()).getElementsByClassName('ql-editor');
        if (domEditor[0]) {
            domEditor[0].addEventListener('focus', () => {
                self.editorFocusedOn.emit();
            });
        }
    }

    insertExistingTextIntoEditor(existingHtml): void {
        if (this.editor && existingHtml) {
            const domEditor = document.getElementById(this.myId.toString()).getElementsByClassName('ql-editor');
            if (domEditor[0]) {
                domEditor[0].innerHTML = existingHtml;
                setTimeout(() => {
                    this.editor.setSelection(this.editor.getLength(), 0);
                }, 25);
            }
        }
    }

    ngOnChanges(changes): void {
        if (changes.existingHtml) {
            this.insertExistingTextIntoEditor(changes.existingHtml.currentValue);
        }
    }

    submit(event): void {
        this.submitContents.emit({
            text: this.text,
            html: this.html,
            ...event
        });
    }

    clearContents(): void {
        this.html = null;
        this.text = null;
        this.editor.setText('', Quill.sources.SILENT);
    }

    onQuillResized(): void {
        if (this.quillContainer && this.quillContainer['elementRef'] && this.quillContainer['elementRef'].nativeElement &&
            this.buttonContainer && this.buttonContainer.nativeElement) {
            this.visibleToolCount = Math.floor((this.quillContainer['elementRef'].nativeElement.scrollWidth -
                this.buttonContainer.nativeElement.scrollWidth) / 30);
            this.checkDOMMinHeight();
        }
    }

    // TODO: There is probably a more computationally light way to accomplish this, so if we are looking for performance improvements, revisit this
    checkDOMMinHeight(): void {
        if (this.placeholder.length > 30) {
            const domEditor = document.getElementById(this.myId.toString()).getElementsByClassName('ql-editor');
            if (domEditor[0]) {
                if (!this.html) {
                    // tslint:disable-next-line:radix
                    domEditor[0]['style'].minHeight = parseInt(window.getComputedStyle(domEditor[0], ':before').height) + 20 + 'px';
                } else {
                    domEditor[0]['style'].minHeight = '0px';
                }
            }
        }
    }

    onMessageChange(event): void {
        this.html = event.html;
        this.text = this.editorService.convertHtmlToText(this.html);

        // 5ms timeout to give the quill editor time to update the selection
        setTimeout(() => {
            this.contentsChanged.emit(this.html);
            this.checkDOMMinHeight();
        }, 5);
    }

    preventNextSubmission(): void {
        this.suppressSubmission = true;
    }

    handleQuillKeys(event): void {
        if (event.key === 'Enter' && !this.suppressSubmission) {
            // If this is a "submitting" editor, then strip enter key newlines
            // Otherwise, leave newline additions as they are
            if (!this.noSubmit) {
                this.editor.history.undo();
            }

            this.submit(event);
        } else if (this.suppressSubmission) {
            this.suppressSubmission = false;
        }
    }
}
