import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../@fuse/animations';

@Component({
  selector: 'hall-of-fame',
  templateUrl: './hall-of-fame.component.html',
  styleUrls: ['./hall-of-fame.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class HallOfFameComponent {

  constructor() { }

}
