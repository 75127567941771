<mat-toolbar *ngIf="!hideAll && (showInstallAppAlert || showAllowNotifications)">
    <div fxLayout="row" fxFlex *ngIf="!showAreYouSurePrompt">

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>

            <div class="install-prompt" *ngIf="showInstallAppAlert" (click)="installTKSLife()">
                <button mat-raised-button color="accent">
                    <mat-icon>get_app</mat-icon>
                    Install TKS.life
                </button>

            </div>

            <div class="notification-prompt" *ngIf="showAllowNotifications" fxHide.xs>
                TKS.life needs your permission to <a class="clickable" (click)="getNotificationPermission()"><b>enable
                push notifications</b></a> on this device.
            </div>

            <div class="notification-prompt"
                 *ngIf="showAllowNotifications && !showInstallAppAlert" fxHide.gt-xs>
                <a class="clickable" (click)="getNotificationPermission()"><b>Enable push notifications</b></a> on this
                device.
            </div>

        </div>

        <div class="exit-button">
            <button mat-icon-button (click)="closeAttempt()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row" fxFlex *ngIf="showAreYouSurePrompt">

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>


            <div class="notification-prompt" *ngIf="showInstallAppAlert || showAllowNotifications" fxHide.xs>
                <div class="inline-options">
                    <button mat-button class="clickable" (click)="askLater()"><mat-icon>event</mat-icon><b>Show me this later</b></button>
                    <button mat-button class="clickable" (click)="neverAsk()"><mat-icon>event_busy</mat-icon><b>Never show this again on this device</b></button>
                </div>
            </div>

            <div class="notification-prompt" *ngIf="showInstallAppAlert || showAllowNotifications" fxHide.gt-xs>
                <div class="inline-options">
                    <button mat-button class="clickable" (click)="askLater()"><mat-icon>event</mat-icon><b>Show this later</b></button>
                    <button mat-button class="clickable" (click)="neverAsk()"><mat-icon>event_busy</mat-icon><b>Never show this again</b></button>
                </div>
            </div>

        </div>

        <div class="exit-button">
            <button mat-icon-button (click)="undoCloseAttempt()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>
