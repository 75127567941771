import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from '../../../auth/auth.service';
import {CONSTANTS} from '../../../data/string-constants';
import {fuseAnimations} from '../../../../@fuse/animations';

@Component({
    selector: 'request-braindate',
    templateUrl: './request-braindate.component.html',
    styleUrls: ['./request-braindate.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class RequestBraindateDialogComponent {
    newBraindateForm: FormGroup;
    constants = CONSTANTS;

    loading = false;

    constructor(
        public fb: FormBuilder,
        private _auth: AuthService,
        private _firestore: AngularFirestore,
        public matDialogRef: MatDialogRef<RequestBraindateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {
        // Set the defaults
        this.newBraindateForm = this.fb.group({
            description: [null, Validators.required],
            tags: [[]],
            topics: [[]],
            type: ['meet', Validators.required],
            city: [this._auth.userProfileSnapshot.programInfo.city || 'Virtual', Validators.required],
            requesterMessage: ['']
        });
    }

    syncDetails(ev): void {
        this.newBraindateForm.controls.requesterMessage.setValue(ev);
    }

    createNewBraindate(form): void {
        this.loading = true;
        const newDate = {
            _id: this._firestore.createId(),
            isGroupDate: false,
            hostId: this._auth.userProfileSnapshot.uid,
            description: form.controls.description.value,
            tags: form.controls.type.value === 'growth' ? form.controls.tags.value :
                form.controls.type.value === 'focus' ? form.controls.topics.value : [],
            city: form.controls.city.value,
            type: form.controls.type.value,
            requesterMessage: form.controls.requesterMessage.value,
            state: 'request'
        };

        const requestSoloBraindate = firebase.functions().httpsCallable('braindates-requestSoloBraindate');
        requestSoloBraindate({
            profile: {
                uid: this._auth.userProfileSnapshot.uid,
                name: this._auth.userProfileSnapshot.name,
                avatar: this._auth.userProfileSnapshot.avatar,
                role: this._auth.userProfileSnapshot.programInfo.role
            },
            requestee: this._data.requestee,
            braindate: newDate
        }).then((result) => {
            // Read result of the Cloud Function.
            Swal.fire('Success!', 'Your braindate request has been sent to ' + this._data.requestee.name + '.',
                'success');
            this.loading = false;
            this.matDialogRef.close();
        });
    }

    setDescriptionOnTypeChange(type): void {
        if (type !== 'meet') {
            if (this.newBraindateForm.controls.description.value === 'Group Hangout' ||
                this.newBraindateForm.controls.description.value === '1-on-1 Hangout') {
                this.newBraindateForm.controls.description.setValue(null);
            }
        }
    }

    closeDialog(): void {
        // Check if anything has changed
        if (this.newBraindateForm.dirty) {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You have unsaved changes',
                icon: 'info',
                confirmButtonText: 'Discard Changes',
                cancelButtonText: 'Cancel',
                showCancelButton: true
            }).then((result) => {
                if (result.value) {
                    this.matDialogRef.close();
                }
            });
        } else {
            // Close the modal
            this.matDialogRef.close();
        }
    }

    getLoading(): boolean {
        return this.loading;
    }

}
