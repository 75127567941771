import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {NotificationComponent} from './notification.component';
import {HelperService} from '../helper.service';
import {omit} from 'lodash';
import {AngularFireDatabase} from '@angular/fire/database';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Location} from '@angular/common';

const isOfflineForDatabase = {
    state: 'offline',
    last_changed: firebase.database.ServerValue.TIMESTAMP,
};
const isOnlineForDatabase = {
    state: 'online',
    last_changed: firebase.database.ServerValue.TIMESTAMP,
};
const isOfflineForFirestore = {
    state: 'offline',
    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
};
const isOnlineForFirestore = {
    state: 'online',
    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
};

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
    private messageSource = new Subject();
    currentMessage = this.messageSource.asObservable();

    private notificationsReady = new BehaviorSubject<boolean>(false);
    public notificationsObservable: any;
    public notificationsSnapshot: any;

    public windowInFocus = true;
    public windowFocusChange = new BehaviorSubject<boolean>(true);

    private loggedInUid = null;

    private _unsubscribeFromProfile: Subject<any>;

    constructor(
        private _firestore: AngularFirestore,
        private afAuth: AngularFireAuth,
        private _auth: AuthService,
        private _database: AngularFireDatabase,
        private _helper: HelperService,
        private _snackBar: MatSnackBar,
        private _idle: Idle,
        private _router: Router,
        private _location: Location
    ) {
        const self = this;
        this._unsubscribeFromProfile = new Subject();

        _idle.setIdle(300);
        _idle.setTimeout(600);
        _idle.setIdleName('tks-life-idle');
        _idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        _idle.onIdleEnd.subscribe(() => {
            self.setStatus('online');
        });

        _idle.onTimeout.subscribe(() => {
            self.setStatus('offline');
        });

        _idle.onIdleStart.subscribe(() => {
            self.setStatus('away');
        });

        this.resetIdleWatchers();

        function windowInFocus(): void {
            self.resetIdleWatchers();
            self.setStatus('online');
            self.windowInFocus = true;
            self.windowFocusChange.next(true);
        }

        function windowOutOfFocus(): void {
            self.windowInFocus = false;
            self.windowFocusChange.next(false);
        }

        // Active
        window.addEventListener('focus', windowInFocus);

        // Inactive
        window.addEventListener('blur', windowOutOfFocus);

        this._auth.isUserAccessReady().subscribe(isReady => {
            if (isReady) {
                this.loggedInUid = this._auth.userAccessSnapshot.uid;
                this.notificationsObservable = _firestore.doc<any>('notifications/' + this._auth.userAccessSnapshot.uid).valueChanges();
                this.notificationsObservable.subscribe((notifications) => {
                    if (notifications) {
                        if (self.notificationsSnapshot) {
                            const oldNotificationsKeys = Object.keys(self.notificationsSnapshot);
                            if (oldNotificationsKeys.length < Object.keys(notifications).length) {
                                const newNotification = omit(notifications, oldNotificationsKeys);
                                const message = newNotification[Object.keys(newNotification)[0]];
                                // console.log(message);
                                if ((message['notification']['click_action'] !== location.href.replace(location.origin, ''))
                                && (!this.isAlreadyViewingChat(message['notification']['click_action'], location.href.replace(location.origin, '')))) {
                                    this._snackBar.openFromComponent(NotificationComponent, {
                                        duration: 5000,
                                        horizontalPosition: 'right',
                                        verticalPosition: 'bottom',
                                        data: message
                                    });

                                    if (self.windowInFocus) {
                                        const audio = new Audio('/assets/sounds/for-sure.mp3');
                                        audio.play();
                                    }
                                } else if (!self.windowInFocus) {
                                    const audio = new Audio('/assets/sounds/for-sure.mp3');
                                    audio.play();
                                }
                            }
                        }

                        self.notificationsSnapshot = notifications || {};
                        this.clearOutNotificationsForPage(location.href.replace(location.origin, ''));

                        if (navigator['setAppBadge']) {
                            navigator['setAppBadge'](Object.keys(this.notificationsSnapshot).length);
                        }

                        self.notificationsReady.next(true);
                    }
                });
                this.watchOnlineStatus();
            } else {
                this.stopWatchingOnlineStatus();
                this._unsubscribeFromProfile.next();
                this._unsubscribeFromProfile.complete();
                this._unsubscribeFromProfile = new Subject();
                self.notificationsSnapshot = null;
                self.notificationsObservable = null;
                self.notificationsReady.next(false);
                const loggedInUid = this.loggedInUid;
                this.loggedInUid = null;
                self.stopNotificationsForProfileOnThisDevice(loggedInUid);


            }
        });


        // This listens for all router events and will remove any notifications that correspond to the current page
        this._router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationStart)
            )
            .subscribe(event => {
                if (this.notificationsSnapshot) {
                    this.clearOutNotificationsForPage(event.url);
                }
            });

        this._location.onUrlChange((url) => {
            if (this.notificationsSnapshot) {
                this.clearOutNotificationsForPage(url);
            }
        });
    }

    isAlreadyViewingChat(notificationUrl, currentUrl): boolean {
        if (notificationUrl.indexOf('/chat') === 0) {
            const urlComponents = notificationUrl.split('/');
            const chatId = urlComponents[urlComponents.length - 1];
            if (currentUrl.indexOf(chatId) !== -1) {
                return true;
            }
        }
        return false;
    }

    resetIdleWatchers(): void {
        this._idle.watch();
    }

    stopWatchingOnlineStatus(): void {
        this._database.database.ref('.info/connected').off('value');
    }

    setStatus(status): void {
        if (this._auth.userAccessSnapshot?.uid) {
            const userStatusFirestoreRef = firebase.firestore().doc(`statuses/${this._auth.userAccessSnapshot.uid}`);
            userStatusFirestoreRef.set({
                status: {
                    state: status,
                    last_changed: firebase.firestore.FieldValue.serverTimestamp()
                }
            });
        }
    }

    watchOnlineStatus(): void {
        const userStatusDatabaseRef = this._database.database.ref('/status/' + this._auth.userAccessSnapshot.uid);
        const userStatusFirestoreRef = firebase.firestore().doc(`statuses/${this._auth.userAccessSnapshot.uid}`);

        this._database.database.ref('.info/connected').on('value', snapshot => {
            if (!snapshot.val()) {
                userStatusFirestoreRef.set({status: isOfflineForFirestore});
                return;
            }

            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(() => {
                userStatusDatabaseRef.set(isOnlineForDatabase);
                userStatusFirestoreRef.set({status: isOnlineForFirestore});
            });
        });
    }

    stopNotificationsForProfileOnThisDevice(uid: string): void {
        const fcmToken = localStorage.getItem('browser-fcm-token');
        if (uid && fcmToken) {
            this._firestore.collection('users').doc(uid).update({
                ['fcmTokens.' + fcmToken + '.enabled']: false
            });
        }
    }

    clearOutNotificationsForPage(urlToRemove: string): void {
        const updateObject = {};
        if (this.notificationsSnapshot) {
            for (const key of Object.keys(this.notificationsSnapshot)) {
                if (this.notificationsSnapshot[key].notification.click_action === urlToRemove ||
                    this.isAlreadyViewingChat(this.notificationsSnapshot[key].notification.click_action, urlToRemove)) {
                    updateObject[key] = firebase.firestore.FieldValue.delete();
                }
            }

            if (Object.keys(updateObject).length) {
                this._firestore.doc<any>('notifications/' + this._auth.userAccessSnapshot.uid).update(updateObject);
            }
        }

    }

    areNotificationsReady(): Observable<boolean> {
        return this.notificationsReady.asObservable();
    }

    getPermission(): void {
        if (!this.messaging) {
            return;
        }
        this.messaging.requestPermission()
            .then(() => {
                console.log('permission granted');
                return this.messaging.getToken();
            })
            .then(token => {
                this.saveToken(this._auth.userAccessSnapshot, token);
                this.monitorRefresh(this._auth.userAccessSnapshot);
                this.receiveMessages();
            })
            .catch(err => {
                console.log(err, 'Unable to get permission');
            });
    }

    monitorRefresh(user): void {
        if (!this.messaging) {
            return;
        }
        this.messaging.onTokenRefresh(() => {
            this.messaging.getToken()
                .then(refreshedToken => {
                    this.saveToken(user, refreshedToken);
                })
                .catch(err => {
                    console.log(err, 'Unable to retrieve new token');
                });
        });
    }


    receiveMessages(): void {
        if (!this.messaging) {
            return;
        }
        this.messaging.onMessage(payload => {
            // console.log('message received: ', payload);
            this.messageSource.next(payload);
        });
    }

    private saveToken(user, token): any {
        const currentTokens = user.fcmTokens || {};

        localStorage.setItem('browser-fcm-token', token);
        if (!currentTokens[token] || !currentTokens[token].enabled) {
            const userRef = this._firestore.collection('users').doc(user.uid);
            const newToken = {
                enabled: true,
                mobile: this._helper.isMobile()
            };

            userRef.update({
                ['fcmTokens.' + token]: newToken
            });
        }
    }
}
