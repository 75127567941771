<div id="my-dashboard" class="page-layout simple right-sidebar" fxLayout="row" *ngIf="_auth.userProfileSnapshot">

    <!-- CENTER -->
    <div class="center" fusePerfectScrollbar>

        <!-- HEADER -->
        <div class="header accent p-24 pb-0" fxLayout="column" fxLayoutAlign="space-between">

            <div fxLayout="row" fxLayoutAlign="space-between start" [@animateStagger]="{value:'100'}">
                <div class="hero-text">
                    <span class="mat-display-1 welcome-message"
                          [@animate]="{value:'*',params:{y:'100%'}}">Hey, {{_auth.userProfileSnapshot.firstname}}!
                    </span>
                    <mat-icon class="hero-icon noselect" [@animate]="{value:'*',params:{y:'100%'}}">dashboard</mat-icon>

                </div>


<!--                <button mat-icon-button class="sidebar-toggle mr-8" fxHide.gt-md-->
<!--                        (click)="toggleSidebar('director-right-sidebar')">-->
<!--                    <mat-icon>menu</mat-icon>-->
<!--                </button>-->

<!--                                                <button mat-button class="accent" (click)="callCohorts()">-->
<!--                                                    Populate Cohorts-->
<!--                                                </button>-->
            </div>

        </div>
        <!-- / HEADER -->

        <!-- CONTENT -->
        <div class="content" *ngIf="loaded">

            <mat-tab-group [animationDuration]="350" dynamicHeight>

                <mat-tab label="Home">

                    <div fxLayout="row wrap" [@animateStagger]="{value:'50'}" *fuseIfOnDom>
                        <div class="row progress-bar-area" [@animate]="{value:'*',params:{y:'100%'}}">
                            <div class="encouragement" *ngIf="dashboard.progress.current == 'first'">
                                <p *ngIf="dashboard.progress.first.percentage <= 25">{{encouragement.first[0]}}</p>
                                <p *ngIf="dashboard.progress.first.percentage > 25 && dashboard.progress.first.percentage <= 50">{{encouragement.first[1]}}</p>
                                <p *ngIf="dashboard.progress.first.percentage > 50 && dashboard.progress.first.percentage <= 75">{{encouragement.first[2]}}</p>
                                <p *ngIf="dashboard.progress.first.percentage > 75 && dashboard.progress.first.percentage <= 100">{{encouragement.first[3]}}</p>
                            </div>
                            <div class="encouragement" *ngIf="dashboard.progress.current == 'second'">
                                <p *ngIf="dashboard.progress.second.percentage <= 25">{{encouragement.second[0]}}</p>
                                <p *ngIf="dashboard.progress.second.percentage > 25 && dashboard.progress.second.percentage <= 50">{{encouragement.second[1]}}</p>
                                <p *ngIf="dashboard.progress.second.percentage > 50 && dashboard.progress.second.percentage <= 75">{{encouragement.second[2]}}</p>
                                <p *ngIf="dashboard.progress.second.percentage > 75 && dashboard.progress.second.percentage <= 100">{{encouragement.second[3]}}</p>
                            </div>
                            <div class="encouragement" *ngIf="dashboard.progress.current == 'third'">
                                <p *ngIf="dashboard.progress.third.percentage <= 25">{{encouragement.third[0]}}</p>
                                <p *ngIf="dashboard.progress.third.percentage > 25 && dashboard.progress.third.percentage <= 50">{{encouragement.third[1]}}</p>
                                <p *ngIf="dashboard.progress.third.percentage > 50 && dashboard.progress.third.percentage <= 75">{{encouragement.third[2]}}</p>
                                <p *ngIf="dashboard.progress.third.percentage > 75 && dashboard.progress.third.percentage < 100">{{encouragement.third[3]}}</p>
                                <p *ngIf="dashboard.progress.third.percentage == 100">{{encouragement.third[4]}}</p>
                            </div>
                            <div class="progress-line-full">
                                <div class="row">
                                    <div class="column one">
                                        <div class="progress-bar-container">
                                            <div class="progress-bar"
                                                 [ngStyle]="{'width': dashboard.progress.first.percentage + '%'}"
                                                 [ngClass]="{'no-transition': dashboard.progress.first.percentage == 100}"></div>
                                        </div>

                                        <div class="progress-start">

                                        </div>

                                        <div class="progress-goal"
                                             [ngClass]="{'unlocked': dashboard.progress.first.percentage >= 100}">
                                            <div class="award first">1</div>
                                        </div>
                                    </div>
                                    <div class="column two">
                                        <div class="progress-bar-container">
                                            <div class="progress-bar"
                                                 [ngStyle]="{'width': dashboard.progress.second.percentage + '%'}"></div>
                                        </div>

                                        <div class="progress-goal"
                                             [ngClass]="{'unlocked': dashboard.progress.second.percentage >= 100}">
                                            <div class="award second">2</div>
                                        </div>
                                    </div>
                                    <div class="column three">
                                        <div class="progress-bar-container">
                                            <div class="progress-bar"
                                                 [ngStyle]="{'width': dashboard.progress.third.percentage + '%'}"></div>
                                        </div>

                                        <div class="progress-goal"
                                             [ngClass]="{'unlocked': dashboard.progress.third.percentage >= 100}">
                                            <div class="award third">3</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="todo-container content-container" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50"
                             fxFlex.gt-sm="33" fxFlex.gt-md="33">

                            <div class="dashboard-box groups" fxLayout="column"
                                 [@animate]="{value:'*',params:{y:'100%'}}">

                                <header class="" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div class="title">
                                        <mat-icon>list</mat-icon>
                                        People You Should Meet
                                    </div>
                                </header>

                                <div class="content recommended-profiles-list uniform-height">
                                    <p *ngIf="recommendedProfiles === null || recommendedProfiles === undefined || recommendedProfiles.length === 0">Loading...</p>
                                    <mat-list *ngIf="recommendedProfiles.length > 0">
                                        <mat-list-item *ngFor="let profile of recommendedProfiles">
                                            <img class="avatar clickable" profileViewerTrigger [profile]="profile.value"
                                            alt="{{profile.value.name}}"
                                            src="{{profile.value.avatar}}"
                                            [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                                            >
                                            <span class="username clickable" profileViewerTrigger [profile]="profile.value">{{profile.value.name}}</span>
                                        </mat-list-item>
                                    </mat-list>
                                    <button mat-button (click)="reloadRecommendedProfiles()">
                                        Refresh
                                    </button>
                                </div>

                                <!-- DELETE OLD CODE TAG -->
                                <!-- <div class="content uniform-height">
                                    <div class="todo-list button-list" *ngIf="todos.todos.length">
                                        <button mat-button class="chat" *ngFor="let todo of todos.todos; let i = index"
                                                [@animate]="{value:'*',params:{delay: (i * 25) + 'ms', y:'100%'}}"
                                                (click)="_helper.navigateToPath(todo.ctaUrl)">
                                            <div class="todo-container">
                                                <div *ngIf="todo.isComplete"
                                                     class="f-modal-icon f-modal-success animate small chart-icons">
                                                    <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
                                                    <span class="f-modal-line f-modal-long animateSuccessLong"></span>
                                                    <div class="f-modal-placeholder"></div>
                                                    <div class="f-modal-fix"></div>
                                                </div>
                                                <div *ngIf="!todo.isComplete"
                                                     class="f-modal-icon f-modal-warning scaleWarning small chart-icons">
                                                    <span class="f-modal-body pulseWarningIns"></span>
                                                    <span class="f-modal-dot pulseWarningIns"></span>
                                                </div>

                                                <div class="description">
                                                    {{todo.description}}
                                                </div>
                                            </div>
                                        </button>
                                    </div>

                                    <div class="no-todo" *ngIf="!todos.todos.length">
                                        <div class="f-modal-icon f-modal-success animate small chart-icons">
                                            <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
                                            <span
                                                class="f-modal-line f-modal-long animateSuccessLong"></span>
                                            <div class="f-modal-placeholder"></div>
                                            <div class="f-modal-fix"></div>
                                        </div>
                                        <p class="secondary-text"> No unfinished to do's</p>
                                    </div>
                                </div> -->

                            </div>

                        </div>

                        <div class="chat-todo-container content-container" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50"
                             fxFlex.gt-sm="33" fxFlex.gt-md="33">

                            <div class="dashboard-box groups" fxLayout="column"
                                 [@animate]="{value:'*',params:{y:'100%'}}">

                                <header class="" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div class="title">
                                        <mat-icon>chat</mat-icon>
                                        Chat
                                    </div>
                                </header>

                                <div class="content uniform-height">
                                    <div class="unread-chats button-list" *ngIf="todos.chats.length">
                                        <button mat-button class="chat" *ngFor="let chat of todos.chats; let i = index"
                                                [@animate]="{value:'*',params:{delay: (i * 25) + 'ms', y:'100%'}}"
                                                (click)="_helper.navigateToChat(chat._path[chat._path.length - 1])">
                                            <div class="chat-container">
                                                <img alt="{{_helper.getFirstNameInitials(chat.chatPartner)}}"
                                                     src="{{_helper.getMemberInfo(chat.chatPartner).avatar}}"
                                                     class="avatar clickable"/>
                                                <h3>{{_helper.getMemberInfo(chat.chatPartner).name}}</h3>
                                                <span class="nav-link-badge accent">
                                                        {{chat.participantInfo[this._auth.userProfileSnapshot.uid].unreads}}
                                                </span>
                                            </div>
                                        </button>
                                    </div>

                                    <div class="no-todo" *ngIf="!todos.chats.length">
                                        <div class="f-modal-icon f-modal-success animate small chart-icons">
                                            <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
                                            <span
                                                class="f-modal-line f-modal-long animateSuccessLong"></span>
                                            <div class="f-modal-placeholder"></div>
                                            <div class="f-modal-fix"></div>
                                        </div>
                                        <p class="secondary-text"> No unread messages</p>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="braindate-todo-container content-container" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="33" fxFlex.gt-md="33">

                            <div class="dashboard-box info-box work" fxLayout="column"
                                 [@animate]="{value:'*',params:{y:'100%'}}">

                                <header class="">
                                    <div class="title">
                                        <mat-icon>people</mat-icon>
                                        Braindates
                                    </div>
                                </header>

                                <div class="content uniform-height" >
                                    <div class="braindate-requests button-list" *ngIf="todos.braindates.requests.length">
                                        <h3 class="sub-header secondary-text">Requests</h3>
                                        <button mat-button class="braindate" *ngFor="let braindate of todos.braindates.requests; let i = index"
                                                [@animate]="{value:'*',params:{delay: (i * 25) + 'ms', y:'100%'}}"
                                                (click)="_helper.navigateToPath('/braindates/requests/' + braindate._id)">
                                            <div class="chat-container" *ngIf="!braindate.isGroupDate">
                                                <img alt="{{_helper.getFirstNameInitials(braindate.braindatePartner)}}"
                                                     src="{{_helper.getMemberInfo(braindate.braindatePartner).avatar}}"
                                                     class="avatar clickable"/>
                                                <h3>{{_helper.getMemberInfo(braindate.braindatePartner).name}}</h3>
                                                <span class="nav-link-badge">
                                                        {{_helper.printDateString(braindate.timestamp)}}
                                                </span>
                                            </div>

                                            <div class="chat-container" *ngIf="braindate.isGroupDate">
                                                <img src="{{braindate.groupAvatar}}"
                                                     class="avatar clickable"/>
                                                <h3>{{braindate.description}}</h3>
                                                <span class="nav-link-badge">
                                                        {{_helper.printDateString(braindate.timestamp)}}
                                                </span>
                                            </div>
                                        </button>
                                    </div>

                                    <div class="active-braindates button-list" *ngIf="todos.braindates.active.length">
                                        <h3 class="sub-header secondary-text">Active</h3>
                                        <button mat-button class="braindate" *ngFor="let braindate of todos.braindates.active; let i = index"
                                                [@animate]="{value:'*',params:{delay: (i * 25) + 'ms', y:'100%'}}"
                                                (click)="_helper.navigateToPath('/braindates/active/' + braindate._id)">
                                            <div class="chat-container" *ngIf="!braindate.isGroupDate">
                                                <img alt="{{_helper.getFirstNameInitials(braindate.braindatePartner)}}"
                                                     src="{{_helper.getMemberInfo(braindate.braindatePartner).avatar}}"
                                                     class="avatar clickable"/>
                                                <h3>{{_helper.getMemberInfo(braindate.braindatePartner).name}}</h3>
                                                <span class="nav-link-badge">
                                                        {{_helper.printDateString(braindate.timestamp)}}
                                                </span>
                                            </div>

                                            <div class="chat-container" *ngIf="braindate.isGroupDate">
                                                <img src="{{braindate.groupAvatar}}"
                                                     class="avatar clickable"/>
                                                <h3>{{braindate.description}}</h3>
                                                <span class="nav-link-badge">
                                                        {{_helper.printDateString(braindate.timestamp)}}
                                                </span>
                                            </div>
                                        </button>
                                    </div>

                                    <div class="no-todo" *ngIf="!todos.braindates.requests.length && !todos.braindates.active.length">
                                        <div class="f-modal-icon f-modal-success animate small chart-icons">
                                            <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
                                            <span
                                                class="f-modal-line f-modal-long animateSuccessLong"></span>
                                            <div class="f-modal-placeholder"></div>
                                            <div class="f-modal-fix"></div>
                                        </div>
                                        <p class="secondary-text">No braindate requests</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="progress-container" fxFlex="100">
                            <div class="dashboard-box stats" fxFlex="100" fxLayout="column"
                                 [@animate]="{value:'*',params:{y:'100%'}}">

                                <header class="" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div class="title">
                                        <mat-icon>list</mat-icon>
                                        Stats
                                    </div>
                                </header>

                                <div class="content">


                                    <table class="simple">

                                        <thead>
                                        <tr>
                                            <th>Objective</th>
                                            <th>Current Goal</th>
                                            <th *ngIf="dashboard.progress.next">Next Goal</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr *ngFor="let type of statTypes; let i = index">
                                            <td>
                                                {{type.title}}
                                            </td>
                                            <td [ngClass]="{'green-fg': dashboard.stats[type.type] >= goals[dashboard.progress.current][type.type], 'orange-fg': dashboard.stats[type.type] < goals[dashboard.progress.current][type.type]}">
                                                {{dashboard.stats[type.type]}} /
                                                <b>{{goals[dashboard.progress.current][type.type]}}</b>
                                            </td>
                                            <td *ngIf="dashboard.progress.next"
                                                [ngClass]="{'green-fg': dashboard.stats[type.type] >= goals[dashboard.progress.next][type.type], 'orange-fg': dashboard.stats[type.type] < goals[dashboard.progress.next][type.type]}">
                                                {{dashboard.stats[type.type]}} /
                                                <b>{{goals[dashboard.progress.next][type.type]}}</b>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab label="My Progress">

                    <div class="widget-group" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                         [@animateStagger]="{value:'50'}">

                        <div class="progress-container" fxFlex="100">
                            <div class="dashboard-box growth" fxFlex="100" fxLayout="column"
                                 [@animate]="{value:'*',params:{y:'100%'}}">

                                <header class="" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div class="title">
                                        <mat-icon>timeline</mat-icon>
                                        Growth
                                    </div>
                                </header>

                                <div class="content">
                                    <div *ngIf="noChartData" class="secondary-text p-12">
                                        <h3>We'll show you a graph of your progress here once you've started to complete
                                            Explore topics and fill out your portfolio.</h3>
                                    </div>
                                    <div class="position-relative pb-16">
                                        <canvas baseChart *ngIf="itemLineChartData"
                                                [datasets]="itemLineChartData"
                                                [labels]="itemLineChartLabels"
                                                [options]="lineChartOptions"
                                                [colors]="lineChartColors"
                                                [legend]="lineChartLegend"
                                                [chartType]="lineChartType"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </mat-tab>

                <mat-tab label="The Process"
                         [disabled]="!dashboard.explore.topics.length && !dashboard.focus.topics.length">

                    <div fxLayout="row wrap" [@animateStagger]="{value:'50'}" *fuseIfOnDom>
                        <div class="explore-container" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50"
                             fxFlex.gt-sm="50">

                            <div class="dashboard-box groups" fxLayout="column"
                                 [@animate]="{value:'*',params:{y:'100%'}}"
                                 *ngIf="dashboard.explore.topics.length">

                                <header class="" fxLayout="row" fxLayoutAlign="space-between center">
                                    <div class="title">
                                        <mat-icon>explore</mat-icon>
                                        Explore
                                    </div>
                                </header>

                                <div class="content">

                                    <div class="topic-table-container" ms-responsive-table>
                                        <table class="simple">

                                            <tbody>
                                            <tr *ngFor="let topic of dashboard.explore.topics; let i = index">
                                                <td>
                                                    <div *ngIf="topic.isComplete"
                                                         class="f-modal-icon f-modal-success animate small chart-icons">
                                                        <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
                                                        <span
                                                            class="f-modal-line f-modal-long animateSuccessLong"></span>
                                                        <div class="f-modal-placeholder"></div>
                                                        <div class="f-modal-fix"></div>
                                                    </div>
                                                    <div *ngIf="!topic.isComplete"
                                                         class="f-modal-icon f-modal-warning scaleWarning small chart-icons">
                                                        <span class="f-modal-body pulseWarningIns"></span>
                                                        <span class="f-modal-dot pulseWarningIns"></span>
                                                    </div>
                                                </td>
                                                <td class="topic-title-container">
                                                    <h2>{{topic.name}}</h2>
                                                    <p>{{topic.isComplete == true ? 'Completed ' + topic.endtime : 'Started ' +
                                                        topic.starttime}}</p>
                                                </td>
                                                <td>
                                                    <button mat-button color="accent" aria-label="View"
                                                            (click)="navigateToExploreTopic(topic.name)">
                                                        Open
                                                    </button>
                                                </td>
                                                <td>
                                                    <button mat-button color="accent" aria-label="View"
                                                            *ngIf="topic.isComplete && !topic.hasFocus"
                                                            (click)="startFocus(topic)">
                                                        Start Focus
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div class="focus-container" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-sm="50">

                            <div class="dashboard-box info-box work" fxLayout="column"
                                 [@animate]="{value:'*',params:{y:'100%'}}"
                                 *ngIf="dashboard.focus.topics.length">

                                <header class="">
                                    <div class="title">
                                        <mat-icon>my_location</mat-icon>
                                        Focus
                                    </div>
                                </header>

                                <div class="content">
                                    <div class="topic-table-container" ms-responsive-table>
                                        <table class="simple">
                                            <tbody>
                                            <tr *ngFor="let topic of dashboard.focus.topics; let i = index">
                                                <td>
                                                    <div *ngIf="topic.isComplete"
                                                         class="f-modal-icon f-modal-success animate small chart-icons">
                                                        <span class="f-modal-line f-modal-tip animateSuccessTip"></span>
                                                        <span
                                                            class="f-modal-line f-modal-long animateSuccessLong"></span>
                                                        <div class="f-modal-placeholder"></div>
                                                        <div class="f-modal-fix"></div>
                                                    </div>
                                                    <div *ngIf="!topic.isComplete"
                                                         class="f-modal-icon f-modal-warning scaleWarning small chart-icons">
                                                        <span class="f-modal-body pulseWarningIns"></span>
                                                        <span class="f-modal-dot pulseWarningIns"></span>
                                                    </div>
                                                </td>
                                                <td class="topic-title-container">
                                                    <h2>{{topic.topic}}</h2>
                                                    <p>{{topic.isComplete == true ? 'Completed ' + _helper.printDateString(topic.finishDate) : 'Started ' +
                                                        _helper.printDateString(topic.startDate)}}</p>
                                                </td>
                                                <td class="no-small-column progress-column">
                                                    <mat-progress-bar mode="determinate"
                                                                      value="{{topic.percentageComplete}}"
                                                                      color="{{_helper.getProgressColour(topic.percentageComplete)}}"></mat-progress-bar>
                                                </td>
                                                <td>
                                                    <button mat-button color="accent" aria-label="View"
                                                            (click)="_helper.navigateToFocusObject(topic._id)">
                                                        Edit
                                                    </button>
                                                </td>
                                                <td>
                                                    <button mat-button color="accent" aria-label="View"
                                                            (click)="_helper.navigateToFocusObject(topic._id, true)">
                                                        View
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </mat-tab>
            </mat-tab-group>

        </div>
        <!-- / CONTENT -->

    </div>
    <!-- / CENTER -->

</div>
