<div class="dialog-content-wrapper" [class.loading]="getLoading()" (keydown.escape)="closeDialog()">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Request Braindate</span>
            <button mat-icon-button (click)="closeDialog()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content fxLayout="column" class="p-24 m-0" fusePerfectScrollbar *ngIf="newBraindateForm">
        <div class="requestee-info" fxLayout="column" fxLayoutAlign="start left" fxLayout.gt-xs="row"
             fxLayoutAlign.gt-xs="start center">
            <div class="image-container" fxLayout.xs="column" fxLayoutAlign.xs="start center"
                 fxLayout.gt-xs="row"
                 fxLayoutAlign.gt-xs="start center">
                <div *ngIf="_data.requestee.avatar" class="profile-image avatar huge"
                     [ngStyle]="{'background-image': 'url(' + (_data.requestee?.avatar ? _data.requestee?.avatar : 'white') + ')'}"
                     [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                </div>
            </div>
            <div class="text-container" fxLayout="column" fxLayoutAlign.xs="start center" *ngIf="_data.requestee?.name">
                <div fxLayout="row" fxLayoutAlign="start left"
                     fxLayoutAlign.xs="start center" class="name"
                     [@animate]="{value:'*',params:{delay:'150ms',x:'-25px'}}">
                    {{_data.requestee?.name}}
                </div>
                <div fxLayout="row" fxLayoutAlign="start left"
                     fxLayoutAlign.gt-xs="start center" class="description"
                     [@animate]="{value:'*',params:{delay:'200ms',x:'-25px'}}">
                    <p *ngIf="_data.requestee?.programInfo?.role == 'Student'">{{_data.requestee?.programInfo?.program}}
                        | {{_data.requestee?.programInfo?.city}}</p>
                    <p *ngIf="_data.requestee?.programInfo?.role == 'Director'">Director |
                        {{_data.requestee?.programInfo?.city}}</p>
                    <p *ngIf="_data.requestee?.programInfo?.role == 'Mentor'">Mentor</p>
                    <p *ngIf="_data.requestee?.programInfo?.role == 'Staff'">TKS Staff</p>
                    <p *ngIf="_data.requestee?.programInfo?.role == 'Alumni'">Alumni
                        | {{_data.requestee?.programInfo?.city}}</p>
                    <p *ngIf="_data.requestee?.programInfo?.role == 'Admin'">System Administrator</p>
                </div>
            </div>
        </div>

        <form name="requestForm" [formGroup]="newBraindateForm" class="request-form" fxLayout="column" fxFlex>

            <div class="group">
                <h2>Braindate Type</h2>
                <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="type">
                    <mat-radio-button class="mb-12" value="meet" (click)="setDescriptionOnTypeChange('meet')">Meet</mat-radio-button>
                    <mat-radio-button class="mb-12" value="focus" (click)="setDescriptionOnTypeChange('focus')">Focus</mat-radio-button>
                    <mat-radio-button class="mb-12" value="growth" (click)="setDescriptionOnTypeChange('growth')">Growth</mat-radio-button>
                </mat-radio-group>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>What would you like to discuss?</mat-label>
                <input matInput autocomplete="off" name="description"
                       formControlName="description"
                       type="text">
            </mat-form-field>

            <topic-tags-selector *ngIf="newBraindateForm.controls['type'].value == 'focus'"
                                 [itemForm]="newBraindateForm"
                                 [placeholder]="'Enter a focus topic'"
                                 [possibleTags]="constants.TAGS"
                                 [tagInputString]="'topics'"
                                 [label]="'Topics'"
            ></topic-tags-selector>

            <topic-tags-selector *ngIf="newBraindateForm.controls['type'].value == 'growth'"
                                 [itemForm]="newBraindateForm"
                                 [placeholder]="'Enter growth topic'"
                                 [possibleTags]="constants.IMPROVEMENTTAGS"
                                 [tagInputString]="'tags'"
                                 [label]="'Tags'"
            ></topic-tags-selector>

            <editor [placeholder]="'Write ' + _data.requestee?.firstname + ' an intro message'"
                    [noSubmit]="true" (contentsChanged)="syncDetails($event)"></editor>

        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <button mat-raised-button
                    color="accent"
                    (click)="createNewBraindate(newBraindateForm)"
                    class="save-button"
                    [disabled]="newBraindateForm.invalid"
                    aria-label="CREATE">
                CREATE
            </button>
        </div>

        <button mat-icon-button (click)="closeDialog()"
                aria-label="Delete"
                matTooltip="Delete">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</div>
<div class="loader" *ngIf="getLoading()">

</div>
