// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const prod = true;
export const environment = {
    production: true,
    hmr       : false,
    firebase: {
        apiKey: prod ? 'AIzaSyAyQX3lO86mTJ2PB05HkXrpaQMoQ7qjPAs' : 'AIzaSyBkbn2LbtbOJPZ4DybFSVifAKYgvb63Xb8',
        authDomain: prod ? 'tks-life-prod.firebaseapp.com' : 'tks-life.firebaseapp.com',
        databaseURL: prod ? 'https://tks-life-prod.firebaseio.com' : 'https://tks-life.firebaseio.com',
        projectId: prod ? 'tks-life-prod' : 'tks-life',
        storageBucket: prod ? 'tks-life-prod.appspot.com' : 'tks-life.appspot.com',
        messagingSenderId: prod ? '655232128983' : '590294051401',
        appId: prod ? '1:655232128983:web:5a0a77043798a52beb5d92' : '1:590294051401:web:83ae20f0325ee9756c2d98',
        measurementId: prod ? 'G-73JZGSWFV6' : 'G-669XN3KD10'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
