<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar || !_auth.isLoggedIn">
                <a [href]="_auth.isLoggedIn ? '/community/activity' : '/auth/login'" class="logo ml-16">
                    <img class="logo-icon">
                </a>
            </div>

            <!--            <div class="px-8 px-md-16">-->
            <!--                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>-->
            <!--            </div>-->

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-button [matMenuTriggerFor]="userMenu" *ngIf="_auth.isLoggedIn"
                    class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img *ngIf="_auth.userProfileSnapshot?.avatar" alt="'assets/images/avatars/profile.png'" [ngClass]="{'loaded': _auth.userProfileSnapshot}"
                         src="{{_auth.userProfileSnapshot?.avatar}}" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}"
                         class="avatar mr-0 mr-sm-16"/>
                    <span class="username mr-12" fxHide
                          fxShow.gt-sm>{{_auth.userProfileSnapshot?.name}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item (click)="_helperService.navigateToUser(_auth.userProfileSnapshot.uid)">
                    <mat-icon>person</mat-icon>
                    <span>View My Profile</span>
                </button>

                <button mat-menu-item (click)="_helperService.navigateToPath('/profile')">
                    <mat-icon>person_add</mat-icon>
                    <span>Edit My Profile</span>
                </button>

                <button mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>

            <div class="toolbar-separator"></div>

            <fuse-search-bar #fuseSearchBarComponent (input)="search($event)"></fuse-search-bar>
<!--            <mat-menu #searchMenu="matMenu" [overlapTrigger]="false">-->

<!--                <button mat-menu-item *ngFor="let result of searchResults"-->
<!--                        (click)="navigateTo('/profile/' + result.searchstring)">-->
<!--                    <span fxLayout="row" fxLayoutAlign="start center">-->
<!--                        <img class="flag mr-16" [src]="'https://tks.life/uploads/photos/' + result.avatar">-->
<!--                        <span class="iso">{{result.firstname + ' ' + result.lastname}}</span>-->
<!--                    </span>-->
<!--                </button>-->

<!--            </mat-menu>-->

            <div class="toolbar-separator"></div>

            <!--            <button mat-button fxHide fxShow.gt-xs-->
            <!--                    class="language-button"-->
            <!--                    [matMenuTriggerFor]="languageMenu">-->
            <!--                <div fxLayout="row" fxLayoutAlign="center center">-->
            <!--                    <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">-->
            <!--                    <span class="iso text-uppercase">{{selectedLanguage.id}}</span>-->
            <!--                </div>-->
            <!--            </button>-->

            <!--            <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">-->

            <!--                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">-->
            <!--                    <span fxLayout="row" fxLayoutAlign="start center">-->
            <!--                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">-->
            <!--                        <span class="iso">{{lang.title}}</span>-->
            <!--                    </span>-->
            <!--                </button>-->

            <!--            </mat-menu>-->

<!--            <button mat-icon-button fxHide.gt-lg *ngIf="_auth.isLoggedIn"-->
<!--                    class="chat-panel-toggle-button"-->
<!--                    (click)="toggleSidebarOpen('chatPanel')"-->
<!--                    aria-label="Toggle chat panel">-->
<!--                <mat-icon class="secondary-text">chat</mat-icon>-->
<!--            </button>-->

<!--            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>-->

            <button mat-icon-button *ngIf="_auth.isLoggedIn"
                    class="notification-panel-toggle-button"
                    (click)="toggleSidebarOpen('notificationPanel')"
                    aria-label="Toggle quick panel">
                <mat-icon class="secondary-text">notifications</mat-icon>
                <span class="notification-badge" *ngIf="notificationCount">
                    {{notificationCount}}
                </span>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button"
                    *ngIf="_auth.isLoggedIn && !hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

        </div>

    </div>

</mat-toolbar>
<mat-select style="visibility:hidden; height: 0px;" class="profile-search-selector"
            #searchSelect placeholder="Focus Selection">
    <mat-option class="capitalized centered m-4" *ngFor="let student of searchResults"
                (click)="navigateTo(student)">
            <span fxLayout="row" fxLayoutAlign="start center">
                <img class="avatar mr-16" [src]="student.avatar">
                <span class="iso">{{student.name}}</span>
            </span>
    </mat-option>
</mat-select>