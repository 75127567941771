import {
    AfterViewInit,
    ApplicationRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';

import {navigation} from 'app/navigation/navigation';
import {locale as navigationEnglish} from 'app/navigation/i18n/en';
import {locale as navigationTurkish} from 'app/navigation/i18n/tr';
import {AuthService} from './auth/auth.service';
import {ManualChangeDetection} from './change-detection.service';
import {HelperService} from './helper.service';
import {MatMenu} from '@angular/material/menu';
import {ChatService} from './chat/chat.service';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    fuseConfig: any;
    navigation: any;


    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    @ViewChild('emojiPickerContainer') emojiPickerContainer: MatMenu;
    @ViewChild('profileViewerHelper') profileViewerHelper: ElementRef;
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        public _auth: AuthService,
        public _chat: ChatService,
        private applicationRef: ApplicationRef,
        public _helper: HelperService,
        private _firestore: AngularFirestore
    ) {
        ManualChangeDetection.STATIC_APPLICATION_REF = applicationRef;

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish);

        // Use a language
        this._translateService.use('en');

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Set theme to last saved value (this will be overwritten by profile settings once a profile is loaded)
                this.fuseConfig.colorTheme = localStorage.getItem('tks-life-theme') || 'theme-default';

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for (let i = 0; i < this.document.body.classList.length; i++) {
                    const className = this.document.body.classList[i];

                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        this._auth.userProfileReady
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((isReady) => {
            if (isReady) {
                this.updateNavigationValues();
            }
        });

        this._chat.chatServiceReady
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((isReady) => {
            if (isReady) {
                this.updateNavigationValues();
            }
        });

        this._chat.onSpacesUpdated
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
            this.updateNavigationValues();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {
        this._helper.setEmojiPicker(this.emojiPickerContainer);
        this._helper.setProfileViewerHelper(this.profileViewerHelper);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    updateNavigationValues(): void {
        this._fuseNavigationService.updateNavigationItem('braindates', {
            badge: {
                title: Object.keys(this._auth.userProfileSnapshot.activeBraindates).length + Object.keys(this._auth.userProfileSnapshot.braindateRequests).length
            }
        });
        if (this._chat.isReady()) {
            this._fuseNavigationService.updateNavigationItem('chat', {
                badge: {
                    title: this._chat.spaces['chats'].participantInfo[this._auth.userProfileSnapshot.uid].unreads
                }
            });
        }

        if (Object.keys(this._auth.userProfileSnapshot.focuses).length === 0) {
            this._fuseNavigationService.updateNavigationItem('focus', {
                disabled: true
            });
        } else {
            this._fuseNavigationService.updateNavigationItem('focus', {
                disabled: false
            });
        }

        if (this._auth.userProfileSnapshot.programInfo?.role === 'Foundations') {
            this._fuseNavigationService.updateNavigationItem('chat', {
                disabled: true
            });
            this._fuseNavigationService.updateNavigationItem('braindates', {
                disabled: true
            });
            this._fuseNavigationService.updateNavigationItem('cohorts', {
                disabled: true
            });
        }

        if (this._auth.userProfileSnapshot.programInfo?.role === 'Admin' ||
            this._auth.userProfileSnapshot.programInfo?.role === 'Director') {
            this._fuseNavigationService.updateNavigationItem('directors', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('cohorts', {
                hidden: false
            })
        } else {
            this._fuseNavigationService.updateNavigationItem('directors', {
                hidden: true
            });
        }

        if (this._auth.userProfileSnapshot.programInfo?.role === 'Admin') {
            this._fuseNavigationService.updateNavigationItem('admin', {
                hidden: false
            });
        } else {
            this._fuseNavigationService.updateNavigationItem('admin', {
                hidden: true
            });
        }

        const explores = (this._auth.userProfileSnapshot.exploreProgress ? Object.keys(this._auth.userProfileSnapshot.exploreProgress.topics)
            .filter(topicName => this._auth.userProfileSnapshot.exploreProgress.topics[topicName].topicProgress.IsComplete) : []).length;
        const focuses = (Object.keys(this._auth.userProfileSnapshot.focuses)
            .filter(focusId => this._auth.userProfileSnapshot.focuses[focusId].isComplete).map(focusId => this._auth.userProfileSnapshot.focuses[focusId].topic)).length;
        const focusStarted = Object.keys(this._auth.userProfileSnapshot.focuses).length > 0;
        const isActivateStudent = this._auth.userProfileSnapshot.programInfo.program === 'Activate';
        const newsletters = this._auth.userProfileSnapshot.newsletters.length;
        let feedback = 0;
        let braindates = 0;
        this._firestore.doc('profiles/' + this._auth.userProfileSnapshot.uid + '/subfields/feedback').get()
            .pipe(take(1))
            .subscribe(feedbackRaw => {
                if (feedbackRaw && feedbackRaw.data()) {
                    const feedbackData = feedbackRaw.data();
                    feedback = Object.keys(feedbackData).filter((feedbackId: any) => !!feedbackData[feedbackId].rating).length;
                }

                this._firestore.doc('profiles/' + this._auth.userProfileSnapshot.uid + '/braindates/braindates1').get()
                    .pipe(take(1))
                    .subscribe(braindatesRaw => {
                        if (braindatesRaw && braindatesRaw.data()) {
                            braindates = Object.keys(braindatesRaw.data()).length;
                        }

                        if ((explores >= 5 &&
                            feedback >= 10 &&
                            braindates >= 10 &&
                            newsletters >= 2) || ['Admin', 'Staff', 'Director'].includes(this._auth.userProfileSnapshot.programInfo.role)) {
                            this._fuseNavigationService.updateNavigationItem('powerups', {
                                disabled: false
                            });
                            this._fuseNavigationService.updateNavigationItem('mentors', {
                                disabled: false
                            });
                        }

                        if (isActivateStudent) {
                            this._fuseNavigationService.updateNavigationItem('mentors', {
                                disabled: false
                            });
                        }

                        if (
                          (
                            explores >= 10 &&
                            // focuses >= 1 &&
                            focusStarted &&
                            feedback >= 20 &&
                            braindates >= 20 &&
                            newsletters >= 3
                          ) ||
                          isActivateStudent ||
                          ['Admin', 'Staff', 'Director'].includes(this._auth.userProfileSnapshot.programInfo.role)
                        ) {
                            this._fuseNavigationService.updateNavigationItem('internships', {
                                disabled: false
                            });
                        }
                    });
            });
    }
}
