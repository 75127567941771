// services/unlockable-guard.service.ts
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {take, takeUntil} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
@Injectable()
export class UnlockableGuardService implements CanActivate {
    constructor(private router: Router, private _auth: AuthService, private _firestore: AngularFirestore) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(resolve => {
            if ( this._auth.isLoggedIn ) {
                this._auth.userProfileReady
                    .pipe(take(5))
                    .subscribe((isReady) => {
                        if (isReady) {
                            if (['Admin', 'Staff', 'Director'].includes(this._auth.userProfileSnapshot.programInfo.role)) {
                                resolve(true);
                                return;
                            }

                            const explores = (this._auth.userProfileSnapshot.exploreProgress ? Object.keys(this._auth.userProfileSnapshot.exploreProgress.topics)
                                .filter(topicName => this._auth.userProfileSnapshot.exploreProgress.topics[topicName].topicProgress.IsComplete) : []).length;
                            const focuses = (Object.keys(this._auth.userProfileSnapshot.focuses)
                                // tslint:disable-next-line:max-line-length
                                .filter(focusId => this._auth.userProfileSnapshot.focuses[focusId].isComplete).map(focusId => this._auth.userProfileSnapshot.focuses[focusId].topic)).length;
                            const newsletters = this._auth.userProfileSnapshot.newsletters.length;
                            let feedback = 0;
                            let braindates = 0;
                            this._firestore.doc('profiles/' + this._auth.userProfileSnapshot.uid + '/subfields/feedback').get()
                                .pipe(take(1))
                                .subscribe(feedbackRaw => {

                                    if (feedbackRaw && feedbackRaw.data()) {
                                        const feedbackData = feedbackRaw.data();
                                        feedback = Object.keys(feedbackData).filter((feedbackId: any) => !!feedbackData[feedbackId].rating).length;

                                        this._firestore.doc('profiles/' + this._auth.userProfileSnapshot.uid + '/braindates/braindates1').get()
                                            .pipe(take(1))
                                            .subscribe(braindatesRaw => {

                                                if (braindatesRaw && braindatesRaw.data()) {
                                                    braindates = Object.keys(braindatesRaw.data()).length;

                                                    let accessGranted;
                                                    if (state.url === '/powerups' || state.url === '/mentors') {
                                                        accessGranted = (
                                                            explores >= 5 &&
                                                            feedback >= 10 &&
                                                            braindates >= 10 &&
                                                            newsletters >= 2
                                                        );
                                                    } else {
                                                        accessGranted = (
                                                            explores >= 10 &&
                                                            focuses >= 1 &&
                                                            feedback >= 20 &&
                                                            braindates >= 20 &&
                                                            newsletters >= 3
                                                        );
                                                    }

                                                    if (!accessGranted) {
                                                        this.router.navigate(['/dashboard']);
                                                        resolve(false);
                                                        return;
                                                    } else {
                                                        resolve(true);
                                                        return;
                                                    }

                                                }
                                            });
                                    }
                                });
                        }
                    });
            } else {
                resolve(false);
                return;
            }
        });
    }
}
