import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, merge as rxjsMerge, Observable, Subject} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {HelperService} from '../helper.service';
import {map, take, takeUntil} from 'rxjs/operators';
import {cloneDeep, get, has, merge as lodashMerge} from 'lodash';
import 'firebase/firestore';
import {firestore} from 'firebase';

@Injectable({
    providedIn: 'root',
})
export class ChatService implements OnDestroy {

    spaceId: string;
    spaces: any;

    refreshSpaces: Subject<any>;
    spaceSelected: Subject<any>;

    public onSpacesUpdated = new Subject();
    public chatServiceReady = new BehaviorSubject<boolean>(false);
    public dialogsUpdated = new Subject();

    private readonly _unsubscribeAll: Subject<any>;

    constructor(
        private _auth: AuthService,
        private _firestore: AngularFirestore,
        private _helper: HelperService,
        private _storage: AngularFireStorage
    ) {
        this.spaceId = 'chats';
        this.spaces = {_ids: ['chats'], chats: {_path: ['spaces', 'chats'], dialogs: {}}};

        this.refreshSpaces = new Subject();
        this.spaceSelected = new Subject();

        this.dialogsUpdated = new Subject();

        this._unsubscribeAll = new Subject();

        this._auth.isUserProfileReady()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((isReady) => {
                if (isReady && !this.chatServiceReady.getValue()) {
                    Promise.all([
                        this.getSpaces(),
                        this.getDialogs()
                    ]).then(() => {
                        this.chatServiceReady.next(true);
                    });
                }
            });
    }

    isReady(): boolean {
        return this.chatServiceReady.getValue();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Message functions
    // -----------------------------------------------------------------------------------------------------

    getSpaces(): Promise<any|void> {
        this.refreshSpaces.next();
        return new Promise((resolve) => {
            // Could have use 'array-contains-any' with userProfileSnapshot.spaceIds, but firebase limits array length
            // to 10, so this would limit the viewable spaces to 10 as well and directors/admins may need more.
            // this._firestore.collection('spaces/', ref => ref
            //     .where('participants', 'array-contains', this._auth.userProfileSnapshot.uid))
            //     .snapshotChanges()
            //     .pipe(
            //         takeUntil(
            //             rxjsMerge(this.refreshSpaces, this._unsubscribeAll)
            //         ),
            //         map(actions => actions.map(queryResult => {
            //             const spaceData = queryResult.payload.doc.data() as any;
            //             const spaceId = queryResult.payload.doc.id;
            //             return {spaceId: spaceId, spaceData: spaceData};
            //         })))
            //     .subscribe((spaces) => {
            //         // Spaces will only appear if they exist on BOTH the collection and the profile
            //         const ids = this._auth.userProfileSnapshot.spaceIds ?
            //             ['chats', ...this._auth.userProfileSnapshot.spaceIds] : ['chats'];
            //         this.spaces._ids = ids.filter((spaceId) => {
            //             const index = spaces.findIndex(space => space.spaceId === spaceId);
            //             if (index !== -1) {
            //                 if (Object.keys(this.spaces).indexOf(spaceId) !== -1) {
            //                     // Object.keys(spaces[index].spaceData).map(key => {
            //                     //     lodashMerge(this.spaces[spaceId][key], spaces[index].spaceData[key]);
            //                     // });
            //                     lodashMerge(this.spaces[spaceId], spaces[index].spaceData);
            //                 } else {
            //                     const userInfo = spaces[index].spaceData.participantInfo[this._auth.userProfileSnapshot.uid];
            //                     this.spaces[spaceId] = {
            //                         _path: [
            //                             'spaces',
            //                             spaceId
            //                         ],
            //                         _badge: userInfo.unreads + userInfo.threadUnreads,
            //                         dialogs: {},
            //                         ...spaces[index].spaceData
            //                     };
            //                 }
            //                 return true;
            //             }
            //             return false;
            //         });
            //
            //         // If the selected space no longer exists, revert to chats
            //         if (this.spaces._ids.indexOf(this.spaceId) === -1) {
            //             this.selectSpace('chats');
            //         }
            //
            //         this.onSpacesUpdated.next();
            //
            //         resolve();
            //     });
            this._firestore.doc('spaces/chats/participantInfo/' + this._auth.userProfileSnapshot.uid).snapshotChanges()
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(async chatMetadata => {
                    if (!chatMetadata.payload.exists) {
                        const newMetadata = {
                            starred: false,
                            threadUnreads: 0,
                            unreads: 0
                        };

                        await this._firestore.doc('spaces/chats/participantInfo/' + this._auth.userProfileSnapshot.uid).set(newMetadata);

                        this.spaces.chats = {
                            _path: [
                                'spaces',
                                'chats'
                            ],
                            participantInfo: {
                                [this._auth.userProfileSnapshot.uid]: newMetadata
                            },
                            _badge: 0,
                            dialogs: this.spaces?.chats?.dialogs || {}
                        };
                    } else {
                        const metadata = chatMetadata.payload.data() as any;
                        this.spaces.chats = {
                            _path: [
                                'spaces',
                                'chats'
                            ],
                            participantInfo: {
                              [this._auth.userProfileSnapshot.uid]: metadata
                            },
                            _badge: (metadata.unreads || 0) + (metadata.threadUnreads || 0),
                            dialogs: this.spaces?.chats?.dialogs || {}
                        };
                    }
                    this.selectSpace('chats');
                    this.onSpacesUpdated.next();

                    resolve(undefined);
                });
        });
    }

    selectSpace(spaceId: string): Promise<any|void> {
        if (this.spaceId !== spaceId) {
            this.spaceId = spaceId;
            this.spaceSelected.next();
            return this.getDialogs();
        } else {
            return new Promise(resolve => resolve(undefined));
        }
    }

    getDialogs(): Promise<any> {
        return new Promise((resolve) => {
            this._firestore.collection('spaces/' + this.spaceId + '/dialogs/', ref => ref
                .where('participants', 'array-contains', this._auth.userProfileSnapshot.uid))
                .snapshotChanges()
                .pipe(
                    takeUntil(
                        rxjsMerge(this.spaceSelected, this._unsubscribeAll)
                    ),
                    map(actions => actions.map(queryResult => {
                        const dialogData = queryResult.payload.doc.data() as any;
                        const dialogId = queryResult.payload.doc.id;
                        return {dialogId: dialogId, dialogData: dialogData};
                    })))
                .subscribe(async (dialogs) => {
                    let totalUnreadCount = 0;

                    // Filter out any dialog in the chat space that has no messages AND does not exist locally since empty local dialogs
                    // can only exist if they were created in this session (which means they're allowed to persist)
                    dialogs
                        .filter((dialog) => this.spaceId !== 'chats' || (!(dialog.dialogData.numMessages === 0 && !this.spaces[this.spaceId].dialogs[dialog.dialogId])))
                        .map((dialog) => {
                            if (this.spaces[this.spaceId].dialogs[dialog.dialogId]) {
                                Object.keys(dialog.dialogData).map(key => {
                                    lodashMerge(this.spaces[this.spaceId].dialogs[dialog.dialogId][key], dialog.dialogData[key]);
                                });
                                // lodashMerge(this.spaces[this.spaceId].dialogs[dialog.dialogId], dialog.dialogData);
                            } else {
                                this.spaces[this.spaceId].dialogs[dialog.dialogId] = {
                                    _numMessages: dialog.dialogData.numMessages,
                                    messages: {
                                        _items: new Map<number, any>(),
                                        _listeners: {},
                                        _lastLocalIndex: -1,
                                        _path: [
                                            'spaces',
                                            this.spaceId,
                                            'dialogs',
                                            dialog.dialogId,
                                            'messages'
                                        ]
                                    },
                                    _path: [
                                        'spaces',
                                        this.spaceId,
                                        'dialogs',
                                        dialog.dialogId,
                                    ],
                                    ...cloneDeep(dialog.dialogData)
                                };
                            }
                            totalUnreadCount += (dialog.dialogData.participantInfo[this._auth.userProfileSnapshot.uid].unreads || 0);
                        });

                    await this._firestore.doc(`spaces/${this.spaceId}/participantInfo/${this._auth.userProfileSnapshot.uid}`).update({
                        unreads: totalUnreadCount
                    });
                    this.dialogsUpdated.next();
                    resolve(undefined);
                });
        });
    }

    setUpMessageListeners(messagesPath, unsubscribers, firstIndex, secondIndex, component): void {
        const collection = get(this, messagesPath.join('.'));


        for (let index = firstIndex; index <= secondIndex; ++index) {
            if (!collection._listeners[index] && index < collection._lastLocalIndex) {
                const listenerUpdate = new Subject();
                collection._listeners[index] = {
                    firstGrab: true,
                    listener: listenerUpdate
                };

                this._firestore.collection(messagesPath.join('/'), ref => ref
                    .where('index', '==', index)
                ).snapshotChanges().pipe(
                    takeUntil(rxjsMerge(...unsubscribers, this._unsubscribeAll, collection._listeners[index].listener)),
                    map(actions => actions.map(queryResult => {
                        const messageData = queryResult.payload.doc.data() as any;
                        const messageDoc = queryResult.payload.doc;
                        messageData._path = [
                            ...messagesPath,
                            messageDoc.id
                        ];
                        return {messageDoc: messageDoc, messageData: messageData};
                    }))).subscribe(messages => {
                    const updateIndexes = [];
                    let scrollToBottom = true;
                    for (const message of messages) {
                        if (collection._listeners[message.messageData['index']]?.firstGrab) {
                            scrollToBottom = false;
                            collection._listeners[message.messageData['index']].firstGrab = false;
                        }
                        updateIndexes.push(message.messageData['index']);
                        this.persist(message.messageData['index'], message, collection);
                    }

                    component.refreshView(updateIndexes, scrollToBottom);
                });
            }
        }

        const listenerKeys = Object.keys(collection._listeners);
        for (const key of listenerKeys) {
            if (key < firstIndex || key > secondIndex) {
                collection._listeners[key].listener.next();
                collection._listeners[key].listener.complete();
                delete collection._listeners[key];
            }
        }
    }

    setUpFrontRunnerMessageListener(messagesPath, unsubscribers, component): void {
        const collection = get(this, messagesPath.join('.'));

        const frontRunnerMessagesObservable = this._firestore.collection(messagesPath.join('/'), ref => ref
            .orderBy('index', 'desc')
            .limit(1)
        ).snapshotChanges();

        frontRunnerMessagesObservable.pipe(
            takeUntil(rxjsMerge(...unsubscribers, this._unsubscribeAll)),
            map(actions => actions.map(queryResult => {
                const messageData = queryResult.payload.doc.data() as any;
                const messageDoc = queryResult.payload.doc;
                messageData._path = [
                    ...messagesPath,
                    messageDoc.id
                ];
                return {messageDoc: messageDoc, messageData: messageData};
            }))).subscribe(async messages => {
            const updateIndexes = [];
            for (const message of messages) {
                updateIndexes.push(message.messageData['index']);
                if (!collection._items.get(message.messageData['index'])) {
                    this.persist(message.messageData['index'], message, collection);
                    await component.processNewItems([message]);
                } else {
                    this.persist(message.messageData['index'], message, collection);
                }
            }
            component.refreshView(updateIndexes);
        });
    }

    persistLatestMessage(messagesPath): Promise<number> {
        return new Promise<number>((resolve) => {
            const collection = get(this, messagesPath.join('.'));

            const frontRunnerMessage = this._firestore.collection(messagesPath.join('/'), ref => ref
                .orderBy('index', 'desc')
                .limit(1)
            ).snapshotChanges();

            frontRunnerMessage.pipe(
                take(1),
                map(actions => actions.map(queryResult => {
                    const messageData = queryResult.payload.doc.data() as any;
                    const messageDoc = queryResult.payload.doc;
                    messageData._path = [
                        ...messagesPath,
                        messageDoc.id
                    ];
                    return {messageDoc: messageDoc, messageData: messageData};
                }))).subscribe(async messages => {
                const updateIndexes = [];
                for (const message of messages) {
                    updateIndexes.push(message.messageData['index']);
                    if (!collection._items.get(message.messageData['index'])) {
                        this.persist(message.messageData['index'], message, collection);
                    } else {
                        this.persist(message.messageData['index'], message, collection);
                    }
                }
                resolve(updateIndexes[0] || 0);
            });
        });
    }

    persistSpecificMessage(messagePath): Promise<any> {
        const collection = get(this, messagePath.slice(0, 5).join('.'));
        return new Promise(async resolve => {
            collection._listeners = {};

            this._firestore.doc(messagePath.join('/')).snapshotChanges().pipe(
                take(1),
            ).subscribe(retMessage => {
                const messageData = retMessage.payload.data() as any;
                const messageDoc = retMessage.payload;
                messageData._path = [
                    ...messagePath,
                    messageDoc.id
                ];
                const message = {messageDoc: messageDoc, messageData: messageData};
                this.persist(messageData['index'], message, collection);
                resolve(message);
            });
        });
    }

    initialDialogRequest(messagesPath, limit = 20): Promise<void> {
        const collection = get(this, messagesPath.join('.'));
        return new Promise(async resolve => {
            collection._listeners = {};

            this._firestore.collection(messagesPath.join('/'), ref => ref
                .orderBy('index', 'desc')
                .limit(limit)
            ).snapshotChanges().pipe(
                take(1),
                map(actions => actions.map(queryResult => {
                    const messageData = queryResult.payload.doc.data() as any;
                    const messageDoc = queryResult.payload.doc;
                    messageData._path = [
                        ...messagesPath,
                        messageDoc.id
                    ];
                    return {messageDoc: messageDoc, messageData: messageData};
                }))).subscribe(initialMessages => {
                for (const message of initialMessages) {
                    this.persist(message.messageData['index'], message, collection);
                }
                resolve();
            });
        });
    }

    request(index: number, count: number, path: string[]): Promise<any[]> {
        const parent = get(this, path.slice(0, -1).join('.'));
        const collection = get(this, path.join('.'));
        return new Promise(async resolve => {
            let cached = this.takeFromCache(index, count, collection, parent.numMessages);

            if (cached) {
                resolve(cached);
            } else {
                const upperLimit = index + count;

                const messageObservable = this._firestore.collection(path.join('/'), ref => ref
                    .where('index', '>=', index)
                    .where('index', '<=', upperLimit)
                ).snapshotChanges();

                messageObservable.pipe(
                    take(1),
                    map(actions => actions.map(queryResult => {
                        const messageData = queryResult.payload.doc.data() as any;
                        const messageDoc = queryResult.payload.doc;
                        messageData._path = [
                            ...path,
                            messageDoc.id
                        ];
                        return {messageDoc: messageDoc, messageData: messageData};
                    }))).subscribe(messages => {
                    for (const message of messages) {
                        this.persist(message.messageData['index'], message, collection);
                    }

                    cached = this.takeFromCache(index, count, collection, parent.numMessages);

                    if (cached) {
                        resolve(cached);
                    } else {
                        resolve([]);
                    }
                });
            }
        });
    }

    public persist(index: number, item: any, collection: any): void {
        collection._items.set(index, item);

        if (index > collection._lastLocalIndex) {
            collection._lastLocalIndex = index;
        }

        if (item.messageData.timestamp) {
            if (Object.keys(collection).indexOf(item.messageDoc.id) === -1) {
                collection[item.messageDoc.id] = {
                    _editing: false,
                    _path: [
                        ...collection._path,
                        item.messageDoc.id
                    ],
                    ...item.messageData
                };
                if (collection._path.length === 5) {
                    collection[item.messageDoc.id]['messages'] = {
                        _items: new Map<number, any>(),
                        _listeners: {},
                        _lastLocalIndex: -1,
                        _path: [
                            ...collection._path,
                            item.messageDoc.id,
                            'messages'
                        ]
                    };
                }
            } else {
                Object.keys(item.messageData).map(key => {
                    collection[item.messageDoc.id][key] = item.messageData[key];
                });
            }
        }
    }

    private takeFromCache(index: number, count: number, collection: any, total: number): null | any[] {
        if (index < 0) {
            const countDifference = Math.abs(index);
            if (count > countDifference) {
                index = 0;
                count = (count - countDifference);
            } else {
                return null;
            }
        }

        const cached: any[] = [];
        for (let i = index; i < index + count; i++) {
            const item = collection._items.get(i);
            if (item) {
                cached.push(item);
            }
        }

        if ((index + count) > total) {
            return cached;
        } else {
            return cached.length === count ? cached : null;
        }

    }

    createOrGetChat(contacts): Promise<any> {
        const participants = [...contacts];
        if (!participants.includes(this._auth.userProfileSnapshot.uid)) {
            // Participants includes the selected contacts and the current user
            participants.push(this._auth.userProfileSnapshot.uid);
        }

        const matches = Object.keys(this.spaces.chats.dialogs).filter(dialogId => {
            const arr = [...this.spaces.chats.dialogs[dialogId].participants];
            return this._helper.compareArrayEquality(arr.sort(), participants.sort());
        });

        return new Promise(resolve => {
            if (matches.length) {
                resolve(matches[0]);
            } else {
                // Generate id and metadata
                const chatId = this._firestore.createId();

                const newChat = {
                    subscribers: participants,
                    participants: participants,
                    participantInfo: {},
                    numMessages: 0,
                    numPinnedMessages: 0,
                    lastMessageTimestamp: firestore.FieldValue.serverTimestamp(),
                    messages: {
                        _items: new Map<number, any>(),
                        _listeners: {},
                        _lastLocalIndex: -1,
                        _path: [
                            'spaces',
                            'chats',
                            'dialogs',
                            chatId,
                            'messages'
                        ]
                    },
                    _path: [
                        'spaces',
                        'chats',
                        'dialogs',
                        chatId
                    ]
                };
                participants.map((participantEmail) => {
                    newChat.participantInfo[participantEmail] = {
                        starred: false,
                        lastReadIndex: -1,
                        typing: false
                    };
                });

                this.spaces.chats.dialogs[chatId] = newChat;

                // TODO: What should the dialogState be upon creation and how should it look?

                // TODO: If you create a dialog then end your session without sending any messages, it will linger in the database indefinitely

                // TODO: Figure out what to do if this fails
                // Write to database
                this._firestore.doc(newChat._path.join('/')).set(this.sanitizeDoc(newChat)).then(() => {
                    resolve(chatId);
                });
            }
        });
    }

    getAndUpdateCollectionUnreads(path: string[]): number {
        let usePath = [...path];
        if (path.length === 5 || path.length === 7) {
            usePath = usePath.slice(0, -1);
        }

        // If this is a thread of which the user is not a participant, they won't exist in the participantInfo obj
        if (!has(this, usePath.join('.') + `.participantInfo.${this._auth.userProfileSnapshot.uid}`)) {

            return 0;
        }

        let unreads = get(this, usePath.join('.')).participantInfo[this._auth.userProfileSnapshot.uid].unreads;
        const output = unreads;
        if (unreads > 0) {
            unreads = 0;
            const inThread = usePath.length === 6;
            const spaceIncrementKey = inThread ? 'threadUnreads' : 'unreads';
            this._firestore.doc(usePath.slice(0, 2).join('/')).update({
                [`participantInfo.${this._auth.userProfileSnapshot.uid}.${spaceIncrementKey}`]: unreads
            });
            this._firestore.doc(usePath.join('/')).update({
                [`participantInfo.${this._auth.userProfileSnapshot.uid}.unreads`]: unreads
            });
        }
        return output;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Helpers
    // -----------------------------------------------------------------------------------------------------

    sanitizeDoc(doc): any {
        const disallowedKeys = ['dialogs', 'messages'];

        const sanitized = cloneDeep(doc);
        Object.keys(sanitized).map(key => {
            if (key.startsWith('_') || disallowedKeys.indexOf(key) !== -1) {
                delete sanitized[key];
            }
        });
        return sanitized;
    }

    getContactsInCollection(path: string[]): any[] {
        let usePath = [...path];
        if (path.length === 5 || path.length === 7) {
            usePath = usePath.slice(0, -1);
        }
        return get(this, usePath.join('.')).participants.filter((participantEmail) => {
            return participantEmail !== this._auth.userProfileSnapshot.uid;
        });
    }

    getDialogTitle(path: string[]): string {
        let usePath = [...path];
        if (path.length === 5 || path.length === 7) {
            usePath = usePath.slice(0, -1);
        }
        if (get(this, usePath.join('.')).name) {
            return get(this, usePath.join('.')).name;
        } else {
            // Return string containing participant(s) of chat
            let dialogTitle = '';
            const contacts = this.getContactsInCollection(usePath);
            if (contacts.length) {
                contacts.map((contactEmail) => {
                    dialogTitle += (this._helper.getMemberInfo(contactEmail).name);
                    if (contactEmail !== contacts[contacts.length - 1]) {
                        dialogTitle += ', ';
                    }
                });
            } else {
                dialogTitle = this._auth.userProfileSnapshot.name + ' (You)';
            }

            return dialogTitle;
        }
    }

    getDialogIconName(path: string[]): string {
        if (path[1] === 'chats') {
            const contacts = this.getContactsInCollection(path);
            if (contacts.length <= 1) {
                return;
            } else if (contacts.length <= 9) {
                return 'filter_' + contacts.length;
            } else {
                return 'filter_9_plus';
            }
        } else {
            return '#';
        }
    }

    getStatusIconClass(uid): any {
        return this._helper.getMemberStatus(uid);
    }

    getDialogIconClass(path: string[]): any {
        const contacts = this.getContactsInCollection(path);
        if (this.spaceId === 'chats' && contacts.length <= 1) {
            if (contacts.length === 0) {
                return this.getStatusIconClass(this._auth.userProfileSnapshot.uid);
            } else if (contacts.length === 1) {
                return this.getStatusIconClass(contacts[0]);
            }
        } else {
            return null;
        }
    }
}
