import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from '../../../auth/auth.service';
import {fuseAnimations} from '../../../../@fuse/animations';

@Component({
    selector: 'update-email-dialog',
    templateUrl: './update-email-dialog.component.html',
    styleUrls: ['./update-email-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class UpdateEmailDialogComponent {
    newEmailForm: FormGroup;

    loading = false;

    constructor(
        public fb: FormBuilder,
        private _auth: AuthService,
        private _firestore: AngularFirestore,
        public matDialogRef: MatDialogRef<UpdateEmailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any
    ) {
        // Set the defaults
        this.newEmailForm = this.fb.group({
            oldEmail: [{value: this._auth.userAccessSnapshot.email, disabled: true}, Validators.required],
            newEmail: [null, Validators.required],
            oldPassword: [null, Validators.required],
        });
    }

    async updateEmail(): Promise<void> {
        if (this.newEmailForm.valid) {
            this.loading = true;
            const success = await this._auth.changeLoginEmail(this._auth.userAccessSnapshot.email,
                this.newEmailForm.controls.newEmail.value, this.newEmailForm.controls.oldPassword.value);

            this.loading = false;
            if (success) {
                Swal.fire('Success', 'Your login email has been updated.', 'success');
                this.closeDialog();
            }
        }
    }

    closeDialog(): void {
        this.matDialogRef.close(null);
    }

    getLoading(): boolean {
        return this.loading;
    }
}
