import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule, FuseWidgetModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAnalyticsModule} from '@angular/fire/analytics';
import {AngularFirestoreModule, SETTINGS} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {TKSLoadingOverlayComponent, UpgradeDialogComponent} from './helper.service';
import {AuthGuard} from './auth/auth-guard.service';
import {MatDialogModule} from '@angular/material/dialog';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NotificationComponent} from './notifications/notification.component';
import {MatMenuModule} from '@angular/material/menu';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {HtmlModule} from './shared/html/html.module';
import {ProfileViewerModule} from './shared/UI-elements/profile-viewer/profile-viewer.module';
import {NgIdleModule} from '@ng-idle/core';
import {DirectorGuard} from './auth/director-guard.service';
import {UnlockableGuardService} from './auth/unlockable-guard.service';
import {AdminGuard} from './auth/admin-guard.service';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {ActionItemsComponent} from './director/action-items/action-items.component';
import {PowerupsComponent} from './powerups/powerups.component';
import {MyDashboardComponent} from './dashboard/my-dashboard/my-dashboard.component';
import { HallOfFameComponent } from './hall-of-fame/hall-of-fame.component';
import { PlaybooksComponent } from './playbooks/playbooks.component';
// import { NgxTwitterWidgetsModule } from 'ngx-twitter-widgets';

const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'chat',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'search',
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'events',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'mentors',
        loadChildren: () => import('./mentors/mentors.module').then(m => m.MentorsModule),
        canActivate: [UnlockableGuardService]
    },
    {
        path: 'internships',
        loadChildren: () => import('./internships/internships.module').then(m => m.InternshipsModule),
        canActivate: [UnlockableGuardService]
    },
    {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'community',
        loadChildren: () => import('./community/community.module').then(m => m.CommunityModule),
        // canActivate: [AuthGuard]
    },
    {
        path: 'explore',
        loadChildren: () => import('./explore/explore.module').then(m => m.ExploreModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'explorepublic', 
        loadChildren: () => import('./explore-public/explore-public.module').then(m => m.PublicExploreModule),
    },
    {
        path: 'focus',
        loadChildren: () => import('./focus/focus.module').then(m => m.FocusModule),
    },
    {
        path: 'braindates',
        loadChildren: () => import('./braindates/braindates.module').then(m => m.BraindatesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'resources',
        loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'cohorts',
        loadChildren: () => import('./cohorts/cohorts.module').then(m => m.CohortsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'powerups',
        component: PowerupsComponent,
        canActivate: [UnlockableGuardService]
    },
    {
        path: 'halloffame',
        component: HallOfFameComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'updates',
        loadChildren: () => import('./updates/updates.module').then(m => m.UpdatesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'playbooks',
        component: PlaybooksComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'report',
        component: MyDashboardComponent,
        resolve: {
            url: 'externalUrlRedirectResolver'
        },
        data: {
            externalUrl: 'https://form.typeform.com/to/NRnjxcjH'
        }
    },
    {
        path: 'director',
        loadChildren: () => import('./director/director.module').then(m => m.DirectorModule),
        canActivate: [DirectorGuard]
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AdminGuard]
    },
    {
        path: '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        NotificationComponent,
        TKSLoadingOverlayComponent,
        UpgradeDialogComponent,
        ActionItemsComponent,
        PowerupsComponent,
        HallOfFameComponent,
        PlaybooksComponent
    ],
    imports: [
        BrowserModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAnalyticsModule,
        AngularFireAuthModule,
        AngularFirestoreModule,
        BrowserAnimationsModule,
        // NgxTwitterWidgetsModule,
        NgIdleModule.forRoot(),
        HttpClientModule,
        RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'}),
        MatDialogModule,
        MatButtonModule,
        MatSnackBarModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatNativeDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        MatMenuModule,
        PickerModule,
        HtmlModule,
        ProfileViewerModule,
        MatTableModule,
        FuseWidgetModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule
    ],
    providers: [AuthGuard, DirectorGuard, AdminGuard, UnlockableGuardService,
        {
            provide: SETTINGS,
            useValue: environment.production ? undefined : {
                host: 'localhost:8080',
                ssl: false
            }
        },
        {
            provide: 'externalUrlRedirectResolver',
            useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            {
                window.location.href = (route.data as any).externalUrl;
            }
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
