import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {RequestBraindateDialogComponent} from './request-braindate.component';
import {TopicTagsModule} from '../topic-tags-selector/topic-tags.module';
import {MatIconModule} from '@angular/material/icon';
import {NgModule} from '@angular/core';
import {HtmlModule} from '../../html/html.module';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

@NgModule({
    declarations: [
        RequestBraindateDialogComponent
    ],
    imports: [
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        TopicTagsModule,
        MatInputModule,
        HtmlModule,
        MatToolbarModule,
        MatFormFieldModule,
        FuseSharedModule
    ],
    exports: [
        RequestBraindateDialogComponent
    ],
    providers: [],
    entryComponents: [
        RequestBraindateDialogComponent
    ]
})

export class RequestBraindateModule {
}
