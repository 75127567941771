<div class="header top-bg accent p-24 pb-0" fxLayout="column" fxLayoutAlign="space-between">

    <div fxLayout="row" fxLayoutAlign="space-between start" [@animateStagger]="{value:'100'}" class="title-container">
        <div class="hero-text">
                    <span class="mat-display-1 welcome-message"
                          [@animate]="{value:'*',params:{y:'100%'}}">Knowledge Starter Kit
                    </span>
            <mat-icon class="hero-icon noselect" [@animate]="{value:'*',params:{y:'100%'}}">flash_on
            </mat-icon>
        </div>
    </div>
</div>

<iframe class="airtable-embed" src="https://tkslife.super.site/tks" onmousewheel="" width="100%" height="100%" style="background: transparent; border: 1px solid #ccc;"></iframe>
