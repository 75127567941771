import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../auth/auth.service';

@Injectable()
export class DashboardService implements Resolve<any> {

    constructor(
        private _auth: AuthService
    ) {
    }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise((resolve, reject) => {

            Promise.all([
                this._auth.isUserAccessReady()
            ]).then(
                (result) => {
                    if (result) {
                        //@ts-ignore
                        resolve();
                    }
                },
                reject
            );
        });
    }
}
