import {NgModule} from '@angular/core';
import {TopicTagsSelectorComponent} from './topic-tags-selector.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatChipsModule} from '@angular/material/chips';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';


@NgModule({
    declarations: [
        TopicTagsSelectorComponent
    ],
    imports: [
        MatFormFieldModule,
        MatChipsModule,
        FuseSharedModule,
        MatIconModule,
        MatAutocompleteModule

    ],
    exports: [
        TopicTagsSelectorComponent
    ],
    providers: []
})

export class TopicTagsModule {
}
