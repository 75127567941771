import {NgModule} from '@angular/core';

import {FuseSharedModule} from '@fuse/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NgTippyModule} from 'angular-tippy';
import {ProfileViewerComponent} from './profile-viewer.component';
import {ProfileViewerTriggerDirectiveDirective} from './directive/profile-viewer-trigger.directive';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TopicTagsModule} from '../topic-tags-selector/topic-tags.module';
import {MatToolbarModule} from '@angular/material/toolbar';
import {RequestBraindateModule} from '../request-braindate-dialog/request-braindate.module';


@NgModule({
    declarations: [
        ProfileViewerComponent,
        ProfileViewerTriggerDirectiveDirective
    ],
    imports: [
        MatIconModule,
        MatButtonModule,
        NgTippyModule,

        FuseSharedModule,
        MatRadioModule,
        MatFormFieldModule,
        TopicTagsModule,
        MatToolbarModule,
        RequestBraindateModule
    ],
    exports: [
        ProfileViewerComponent,
        ProfileViewerTriggerDirectiveDirective
    ],
    providers: []
})

export class ProfileViewerModule {
}
