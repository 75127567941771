<div class="theme-options-panel" fusePerfectScrollbar>

    <div class="header">

        <span class="title">Customization Options</span>

        <button mat-icon-button class="close-button" (click)="toggleSidebarOpen('themeOptionsPanel')">
            <mat-icon>close</mat-icon>
        </button>

    </div>

    <form [formGroup]="form">

        <!-- COLOR THEME -->
        <div class="group">

            <h2>Theme</h2>

            <mat-radio-group fxLayout="column" fxLayoutAlign="start start" formControlName="colorTheme">
                <mat-radio-button class="mb-12" value="theme-default">TKS Light</mat-radio-button>
<!--                <mat-radio-button class="mb-12" value="theme-tks-lighter">TKS Lighter</mat-radio-button>-->
<!--                <mat-radio-button class="mb-12" value="theme-blue-gray-dark">Blue-Gray Dark</mat-radio-button>-->
                <mat-radio-button class="mb-12" value="theme-tks-dark">TKS Dark</mat-radio-button>
                <mat-radio-button class="mb-12" value="theme-contrast-dark">TKS Darker</mat-radio-button>
            </mat-radio-group>

        </div>

        <div class="group">

            <h2>Custom scrollbars</h2>

            <mat-slide-toggle class="mb-12" formControlName="customScrollbars">
                Enable custom scrollbars
            </mat-slide-toggle>

        </div>

        <div class="group">

            <h2>Account</h2>

            <button mat-raised-button class="mb-12" color="primary"
                    aria-label="Change Login Email" (click)="changeLoginEmail()">
                Change Login Email
            </button>

        </div>

        <div class="group referral" *ngIf="referralCode && referralLink">

            <h2>Referral Code</h2>

            <h3>{{referralCode}}</h3>
            <input id="referral-code" type="text" class="hidden" [value]="referralLink">

            <button mat-button class="mb-12" color="primary"
                    aria-label="Copy Referral Link" (click)="copyToClipboardWithAlert(referralLink, $event.target)">
                Copy Referral Link
            </button>

        </div>
    </form>

</div>
