import {Component, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../@fuse/animations';

@Component({
  selector: 'playbooks',
  templateUrl: './playbooks.component.html',
  styleUrls: ['./playbooks.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class PlaybooksComponent {

  constructor() { }

}
