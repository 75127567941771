import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {range} from 'lodash';
import Swal from 'sweetalert2/src/sweetalert2.js';
import {AngularFirestore} from '@angular/fire/firestore';
import {firestore} from 'firebase';

@Component({
  selector: 'action-items',
  templateUrl: './action-items.component.html',
  styleUrls: ['./action-items.component.scss']
})
export class ActionItemsComponent implements OnInit {
    public nums = range(1, 21);
    public cities = [
        'Toronto',
        'Ottawa',
        'Waterloo',
        'Las Vegas',
        'New York',
        'Boston',
        'Vancouver',
        'Los Angeles',
        'Virtual'
    ];
    private types = [
        'Explores',
        'Feedback',
        'Braindates'
    ];
    public actionItemsForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        private _firestore: AngularFirestore
    ) { }

    ngOnInit(): void {
        this.actionItemsForm = this.fb.group({
            city: ['', Validators.required],
            actions: this.fb.array([
                this.fb.group({
                    type: ['', Validators.required],
                    amount: ['', Validators.required]
                })
            ], Validators.minLength(1))
        });
    }

    getActions(): any {
        return (this.actionItemsForm.controls['actions'] as FormArray).controls;
    }

    getTypes(i): any {
        const currentVals = this.getActions().map(control => control.controls.type.value);
        return this.types.filter(type => !currentVals.includes(type.toLowerCase()) || currentVals[i] === type.toLowerCase());
    }

    addAction(): void {
        (this.actionItemsForm.controls['actions'] as FormArray).push(this.fb.group({
            type: ['', Validators.required],
            amount: ['', Validators.required]
        }));
    }

    removeAction(index): void {
        (this.actionItemsForm.controls['actions'] as FormArray).removeAt(index);
    }

    submit(): void {
        this._firestore.doc('todos/' + this._firestore.createId()).set({
            city: this.actionItemsForm.controls['city'].value,
            timestamp: firestore.FieldValue.serverTimestamp(),
            todos: this.getActions().map(control => new Object({
                type: control.controls.type.value,
                amount: control.controls.amount.value
            }))
        }).then(_ => {
            Swal.fire('Success!', 'Your action items were submitted.', 'success');
        }).catch(_ => {
            Swal.fire('Oops!', 'That didn\'t work - please try again.', 'error');
        });

    }

}
