<div id="action-items" class="page-layout simple tabbed">

    <!-- HEADER -->
    <div class="header accent p-24 pb-0" fxLayout="column" fxLayoutAlign="space-between">

        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div class="hero-text">
                    <span class="mat-display-1 welcome-message">Action Items
                    </span>
                <mat-icon class="hero-icon noselect">list
                </mat-icon>
            </div>
        </div>

    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">

        <div class="p-24 resources-container">
            <!-- WIDGET GROUP -->
            <div class="widget-group widget-container" fxLayout="row wrap" fxFlex="100">

                <!-- WIDGET 11 -->
                <fuse-widget class="widget resources-widget"
                             fxLayout="row"
                             fxFlex="100">

                    <!-- Front -->
                    <div class="fuse-widget-front">
                        <div class="p-24 mb-8 border-bottom search-container" fxLayout="row"
                             fxLayoutAlign="space-between center">
                            <div class="filter-containers" fxLayout.gt-lg="row" fxLayout.gt-md="column"
                                 fxLayout.gt-sm="column" fxLayout.gt-xs="column" fxLayout.lt-xl="row"
                                 fxLayout.xs="column"
                                 fxLayoutAlign="space-between center">
                                <div class="filter-container">

                                    <form [formGroup]="actionItemsForm" fxFlex>
                                        <div class="form-section">
                                            <h2 class="section-title">
                                                <span>City</span>
                                            </h2>

                                            <mat-form-field appearance="fill" style="width: 100%; height: 100%">
                                                <mat-label></mat-label>
                                                <mat-select formControlName="city">
                                                    <mat-option *ngFor="let city of cities" [value]="city">{{city}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <div class="form-section" formArrayName="actions">
                                            <h2 class="section-title">
                                                <span>This week's objectives</span>
                                            </h2>

                                            <div class="section-row" *ngFor="let action of getActions(); let i = index" [formGroupName]="i">

                                                <button mat-icon-button *ngIf="i > 0" (click)="removeAction(i)">
                                                    <mat-icon>close</mat-icon>
                                                </button>

                                                <mat-form-field appearance="fill" style="width: 100%; height: 100%">
                                                    <mat-select formControlName="type">
                                                        <mat-option *ngFor="let option of getTypes(i)" [value]="option.toLowerCase()">{{option}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field appearance="fill" style="width: 100%; height: 100%">
                                                    <mat-select formControlName="amount">
                                                        <mat-option *ngFor="let n of nums" [value]="n">{{n}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                            </div>

                                            <button mat-button class="section-row" (click)="addAction()" [disabled]="getActions().length > 2">
                                                <span>Add goal</span>
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>

                                        <button mat-raised-button
                                                color="accent"
                                                (click)="submit()"
                                                [disabled]="!actionItemsForm.valid"
                                                class="save-button">
                                            SUBMIT
                                        </button>
                                    </form>

                                </div>

                            </div>


                        </div>

                    </div>
                    <!-- / Front -->
                </fuse-widget>
                <!-- / WIDGET 11 -->

            </div>
            <!-- / WIDGET GROUP -->
        </div>

    </div>
    <!-- / CONTENT -->

</div>
