<div class="dialog-content-wrapper" [class.loading]="getLoading()" (keydown.escape)="closeDialog()">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Update Login Email</span>
            <button mat-icon-button (click)="closeDialog()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content fxLayout="column" class="p-24 m-0" fusePerfectScrollbar *ngIf="newEmailForm">

        <form name="requestForm" [formGroup]="newEmailForm" class="request-form" fxLayout="column" fxFlex>

            <mat-form-field appearance="outline">
                <mat-label>New Email</mat-label>
                <input matInput autocomplete="off" name="newEmail"
                       formControlName="newEmail"
                       autocomplete="off"
                       type="email">
            </mat-form-field>

            <div class="group">
                <h2>Existing Credentials</h2>
                <mat-form-field appearance="outline">
                    <mat-label>Existing Email</mat-label>
                    <input matInput autocomplete="off" name="oldEmail"
                           formControlName="oldEmail"
                           type="email">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Existing Password</mat-label>
                    <input matInput autocomplete="off" name="oldPassword"
                           formControlName="oldPassword"
                           type="password">
                </mat-form-field>
            </div>


        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <button mat-raised-button
                    color="accent"
                    (click)="updateEmail()"
                    class="save-button"
                    [disabled]="newEmailForm.invalid"
                    aria-label="CREATE">
                UPDATE EMAIL
            </button>
        </div>
    </div>
</div>
<div class="loader" *ngIf="getLoading()">

</div>
