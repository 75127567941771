<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<fuse-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <fuse-theme-options></fuse-theme-options>
</fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->

<mat-menu #emojiPickerContainer class="emoji-picker custom-menu">
    <emoji-mart title="Pick a reaction"
                emoji="slightly-smiling-face"
                *ngIf="_auth.userProfileSnapshot"
                [darkMode]="_auth.userProfileSnapshot.theme.indexOf('dark') !== -1"
                [set]="'google'"
                (emojiClick)="_helper.callEmojiPickerCallback($event)"
                (click)="$event.stopPropagation()">
    </emoji-mart>
</mat-menu>

<div class="profile-viewer-helper" #profileViewerHelper
     [class.director]="_auth.userProfileSnapshot?.programInfo?.role === 'Director' || _auth.userProfileSnapshot?.programInfo?.role === 'Admin'">
    <profile-viewer *ngIf="_helper.profileViewerProfile" [profile]="_helper.profileViewerProfile" [handler]="_helper.currentProfileViewerTippy">
    </profile-viewer>
</div>