import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation
} from '@angular/core';
import {NgForm} from '@angular/forms';

import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {AngularFirestore} from '@angular/fire/firestore';
import {ChatService} from '../../../chat/chat.service';
import {HelperService} from '../../../helper.service';
import {AuthService} from '../../../auth/auth.service';

@Component({
    selector: 'chat-panel',
    templateUrl: './chat-panel.component.html',
    styleUrls: ['./chat-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatPanelComponent implements OnInit, AfterViewInit, OnDestroy {
    sidebarFolded: boolean;




    @ViewChildren(FusePerfectScrollbarDirective)
    private _fusePerfectScrollbarDirectives: QueryList<FusePerfectScrollbarDirective>;

    private directiveScroll: FusePerfectScrollbarDirective;
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseSidebarService: FuseSidebarService,
        private _auth: AuthService,
        public _chat: ChatService,
        private _firestore: AngularFirestore,
        private _helper: HelperService
    ) {
        this.sidebarFolded = true;
        this._unsubscribeAll = new Subject();

        // TODO: Disable chat panel on chat page
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        // Subscribe to the foldedChanged observable
        this._fuseSidebarService.getSidebar('chatPanel').foldedChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((folded) => {
                this.sidebarFolded = folded;
            });
    }

    ngAfterViewInit(): void {
        this.directiveScroll = this._fusePerfectScrollbarDirectives.find((directive) => {
            return directive.elementRef.nativeElement.id === 'messages';
        });
    }

    @HostListener('window:unload')
    ngOnDestroy(): any {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // This is necessary to disable the confirmation dialog upon session unload
        return;
    }


    foldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').foldTemporarily();
    }

    unfoldSidebarTemporarily(): void {
        this._fuseSidebarService.getSidebar('chatPanel').unfoldTemporarily();
    }

    toggleSidebarOpen(): void {
        this._fuseSidebarService.getSidebar('chatPanel').toggleOpen();
    }

    toggleChat(dialogId): void {
        // If the contact equals to the selectedContact,
        // that means we will deselect the contact and
        // unload the chat
        // if (this._chat.selectedDialogId === dialogId) {
            // this._chat.selectDialog(null);
        // }
        // Otherwise, we will select the contact, open
        // the sidebar and start the chat
        // else {
            // Unfold the sidebar temporarily
            // this.unfoldSidebarTemporarily();

            // Set the selected contact
            // this._chat.selectDialog(dialogId);
        // }
    }

    resetChat(): void {
        // this.inChannel = null;
        // this.selectedKey = null;
        // this.selectedDialog = [];
        // this.selectedContactKeys = [];
        // this.dialogState = null;
        // this.dir = null;
    }

    selectThread(messageId): void {
        // this._chat.onRightSidenavViewChanged.next('thread');

        // this._chat.selectThread(messageId).then(() => {
            // Do stuff
            // console.log(this._chat.threadMessages);
        // });
    }

    getObjLen(a): any {
        return Object.keys(a).length;
    }

}
