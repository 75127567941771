import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {navigation} from 'app/navigation/navigation';
import {AuthService} from '../../../auth/auth.service';
import {Router} from '@angular/router';
import {HelperService} from '../../../helper.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatSelect} from '@angular/material/select';
import {FuseSearchBarComponent} from '../../../../@fuse/components/search-bar/search-bar.component';
import {NotificationService} from "../../../notifications/notification.service";
import {fuseAnimations} from '../../../../@fuse/animations';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    notificationCount: number;
    selectedLanguage: any;
    userStatusOptions: any[];

    searchResults: any[];
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    @ViewChild('searchSelect') searchSelect: MatSelect;
    @ViewChild('fuseSearchBarComponent') fuseSearchBarComponent: FuseSearchBarComponent;
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public _helperService: HelperService,
        private _messagingService: NotificationService,
        public _auth: AuthService,
        private _router: Router
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'tr',
                title: 'Turkish',
                flag: 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        this._messagingService.areNotificationsReady()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(isReady => {
                if (isReady) {
                    this.notificationCount = Object.keys(this._messagingService.notificationsSnapshot).length;
                } else {
                    this.notificationCount = 0;
                }

            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        if (typeof value !== 'object') {
            if (value.length > 2) {
                const uids = Object.keys(this._helperService.profileLookupSnapshot);
                const results = [];
                for (const uid of uids) {
                    if (this._helperService.profileLookupSnapshot[uid] && this._helperService.profileLookupSnapshot[uid].name) {
                        const fullName = this._helperService.profileLookupSnapshot[uid].name.toLowerCase();
                        if (fullName.indexOf(value.toLowerCase()) !== -1) {
                            results.push(this._helperService.profileLookupSnapshot[uid]);
                        }
                    }
                }

                this.searchResults = results;
                if (this.searchResults.length) {
                    this.searchSelect.open();
                } else {
                    this.searchSelect.close();
                }
            } else {
                this.searchSelect.close();
            }
        }
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    navigateTo(student): void {
        this.fuseSearchBarComponent.collapse();
        this.fuseSearchBarComponent.clear();
        this._helperService.navigateToUser(student.uid);
    }

    logout(): void {
        this._auth.logout();
    }
}
