import {Component} from '@angular/core';
import {NotificationService} from '../../../notifications/notification.service';
import {AuthService} from '../../../auth/auth.service';

@Component({
    selector: 'custom-prompt-footer',
    templateUrl: './custom-prompt-footer.component.html',
    styleUrls: ['./custom-prompt-footer.component.scss']
})
export class CustomPromptFooterComponent {
    deferredPrompt;
    showInstallAppAlert = false;

    showAllowNotifications = false;
    notificationPermission;

    showAreYouSurePrompt = false;

    hideAll = false;

    constructor(private _notificationService: NotificationService,
                private _auth: AuthService) {
        const notificationResponse = localStorage.getItem('notification-prompt');
        if (notificationResponse) {
            if (notificationResponse === 'never' || Number(notificationResponse) < Date.now()) {
                this.hideAll = true;
                return;
            }
        } else {
            this._auth.isUserAccessReady().subscribe(isReady => {
                if (isReady) {
                    this.notificationPermission = Notification.permission;
                    this.showAllowNotifications = this.notificationPermission === 'default'; // other options: 'granted' or 'denied'

                    if (this.notificationPermission === 'granted') {
                        this.getNotificationPermission();
                    }
                }
            });
        }

        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent the mini-infobar from appearing on mobile
            console.log(e);
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;
            // Update UI notify the user they can install the PWA
            this.showInstallAppAlert = true;
        });
    }

    getDayFromNow(): string {
        return (Date.now() + 86400000).toString();
    }

    getNotificationPermission(): void {
        this._notificationService.getPermission();
    }

    installTKSLife(): void {
        this.deferredPrompt.prompt();

        this.deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                this.showInstallAppAlert = false;
                console.log('Install prompt accepted.');
            } else {
                console.log('Install prompt dimissed.');
            }
        });
    }

    closeAttempt(): void {
        this.showAreYouSurePrompt = true;
    }

    askLater(): void {
        localStorage.setItem('notification-prompt', this.getDayFromNow());
        this.hideAll = true;
    }

    neverAsk(): void {
        localStorage.setItem('notification-prompt', 'never');
        this.hideAll = true;
    }

    undoCloseAttempt(): void {
        this.showAreYouSurePrompt = false;
    }
}
