import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FuseSharedModule } from '@fuse/shared.module';

import { NotificationPanelComponent } from 'app/layout/components/notification-panel/notification-panel.component';
import {OrderModule} from "ngx-order-pipe";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {HtmlModule} from '../../../shared/html/html.module';

@NgModule({
    declarations: [
        NotificationPanelComponent
    ],
    imports: [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        OrderModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,

        FuseSharedModule,
        HtmlModule,
    ],
    exports: [
        NotificationPanelComponent
    ]
})
export class NotificationPanelModule
{
}
