import {ApplicationRef, ChangeDetectorRef, Injectable} from '@angular/core';

@Injectable()
export class ManualChangeDetection {
  public static STATIC_APPLICATION_REF: ApplicationRef;
  public static queueApplicationTick(): void {
    Util.debounce(() => {
      if (ManualChangeDetection.STATIC_APPLICATION_REF['_runningTick']) {
        return;
      }

      ManualChangeDetection.STATIC_APPLICATION_REF.tick();
    }, 5);
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

    public queueChangeDetection(): void {
        this.changeDetectorRef.markForCheck(); // marks self for change detection on the next cycle, but doesn't actually schedule a cycle
        ManualChangeDetection.queueApplicationTick();
    }
}

@Injectable()
export class Util {
  public static throttleTrailing(func, wait): any {
    let timeout;
    let _arguments;
    const result = function() {
      const _this = this;
      _arguments = arguments;

      if (timeout) {
        return;
      }

      if (wait <= 0) {
        func.apply(_this, _arguments);
      } else {
        timeout = setTimeout(() => {
          timeout = undefined;
          func.apply(_this, _arguments);
        }, wait);
      }
    };
    result['cancel'] = () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
      }
    };

    return result;
  }

  public static debounce(func, wait): any {
    const throttled = Util.throttleTrailing(func, wait);
    const result = () => {
      throttled['cancel']();
      throttled.apply(this, arguments);
    };
    result['cancel'] = () => {
      throttled['cancel']();
    };

    return result;
  }
}
