import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'progress',
        title    : 'Progress',
        translate: 'NAV.PROGRESS',
        type     : 'group',
        children : [
            {
                id       : 'profile',
                title    : 'Profile',
                translate: 'NAV.PROFILE.TITLE',
                type     : 'item',
                icon     : 'person',
                url      : '/profile'
            },
            {
                id       : 'dashboard',
                title    : 'Dashboard',
                translate: 'NAV.DASHBOARD.TITLE',
                type     : 'item',
                icon     : 'dashboard',
                url      : '/dashboard'
            }
        ]
    },
    {
        id       : 'growth',
        title    : 'Growth',
        translate: 'NAV.GROWTH',
        type     : 'group',
        children : [
            {
                id       : 'explore',
                title    : 'Explore',
                translate: 'NAV.EXPLORE.TITLE',
                type     : 'item',
                icon     : 'explore',
                url      : '/explore'
            },
            {
                id       : 'focus',
                title    : 'Focus',
                translate: 'NAV.FOCUS.TITLE',
                type     : 'item',
                icon     : 'my_location',
                url      : '/focus'
            },
            {
                id       : 'mentors',
                title    : 'Mentors',
                translate: 'NAV.MENTORS.TITLE',
                type     : 'item',
                icon     : 'person_pin',
                url      : '/mentors',
                disabled : true
            },
            {
                id       : 'internships',
                title    : 'Internships',
                translate: 'NAV.INTERNSHIPS.TITLE',
                type     : 'item',
                icon     : 'business_center',
                url      : '/internships',
                disabled : true
            }
        ]
    },
    {
        id       : 'resources',
        title    : 'Resources',
        translate: 'NAV.RESOURCES',
        type     : 'group',
        children : [
            {
                id       : 'playbooks',
                title    : 'Playbooks',
                translate: 'NAV.PLAYBOOKS.TITLE',
                type     : 'item',
                icon     : 'info',
                url      : 'playbooks'
            },
            {
                id       : 'powerups',
                title    : 'Power Ups',
                translate: 'NAV.POWERUPS.TITLE',
                type     : 'item',
                icon     : 'flash_on',
                url      : 'powerups',
                disabled : true
            },
            {
                id       : 'focus_resources',
                title    : 'Focus Resources',
                translate: 'NAV.FOCUS_RESOURCES.TITLE',
                type     : 'item',
                icon     : 'collections_bookmark',
                url      : '/resources'
            },
        ]
    },
    {
        id       : 'community',
        title    : 'Community',
        translate: 'NAV.COMMUNITY',
        type     : 'group',
        children : [
            {
                id       : 'activity',
                title    : 'Activity',
                translate: 'NAV.ACTIVITY.TITLE',
                type     : 'item',
                icon     : 'publish',
                url      : '/community/activity'
            },
            {
                id       : 'tks_maps',
                title    : 'TKS Maps',
                translate: 'NAV.TKS_MAPS.TITLE',
                type     : 'item',
                icon     : 'map',
                url      : '/community/maps'
            },
            {
                id       : 'events',
                title    : 'Events',
                translate: 'NAV.EVENTS.TITLE',
                type     : 'item',
                icon     : 'event',
                url      : '/events'
            },
            {
                id       : 'braindates',
                title    : 'Braindates',
                translate: 'NAV.BRAINDATES.TITLE',
                type     : 'item',
                icon     : 'people',
                url      : '/braindates',
                badge    : {
                    title    : '',
                    translate: 'NAV.BRAINDATES.BADGE',
                    bg       : '#00a0a7',
                    fg       : '#FFFFFF'
                }
            },
            {
                id       : 'chat',
                title    : 'Chat',
                translate: 'NAV.CHAT.TITLE',
                type     : 'item',
                icon     : 'chat',
                url      : '/chat',
                badge    : {
                    title    : '0',
                    translate: 'NAV.CHAT.BADGE',
                    bg       : '#D14B4E',
                    fg       : '#FFFFFF'
                }
            },
            {
                id       : 'cohorts',
                title    : 'Cohorts',
                translate: 'NAV.COHORTS.TITLE',
                type     : 'item',
                icon     : 'pageview',
                url      : '/cohorts',
                hidden   : true
            },
            {
                id       : 'search',
                title    : 'Search',
                translate: 'NAV.SEARCH.TITLE',
                type     : 'item',
                icon     : 'search',
                url      : '/search'
            },
            {
                id       : 'halloffame',
                title    : 'Hall of Fame',
                type     : 'item',
                icon     : 'stars',
                url      : '/halloffame'
            },
        ]
    },
    {
        id       : 'directors',
        title    : 'Directors',
        translate: 'NAV.DIRECTORS',
        type     : 'group',
        hidden : true,
        children : [
            {
                id       : 'director-dashboard',
                title    : 'Dashboard',
                translate: 'NAV.DIRECTOR-DASHBOARD.TITLE',
                type     : 'item',
                icon     : 'supervisor_account',
                url      : '/director/dashboard'
            },
            {
                id       : 'action-items',
                title    : 'Action Items',
                translate: 'NAV.ACTION-ITEMS.TITLE',
                type     : 'item',
                icon     : 'list',
                url      : '/director/action-items'
            },
            // {
            //     id       : 'opportunities',
            //     title    : 'Opportunities',
            //     translate: 'NAV.DIRECTORS.OPPORTUNITIES',
            //     type     : 'item',
            //     icon     : 'unarchive',
            //     url      : 'https://google.ca',
            //     externalUrl      : true,
            // }
        ]
    },
    {
        id       : 'admin',
        title    : 'Admin',
        translate: 'NAV.ADMIN',
        type     : 'group',
        hidden : true,
        children : [
            {
                id       : 'admin-dashboard',
                title    : 'Dashboard',
                translate: 'NAV.ADMIN-DASHBOARD.TITLE',
                type     : 'item',
                icon     : 'admin_panel_settings',
                url      : '/admin/dashboard'
            },
            // {
            //     id       : 'opportunities',
            //     title    : 'Opportunities',
            //     translate: 'NAV.DIRECTORS.OPPORTUNITIES',
            //     type     : 'item',
            //     icon     : 'unarchive',
            //     url      : 'https://google.ca',
            //     externalUrl      : true,
            // }
        ]
    }
];
