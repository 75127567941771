import {Directive, HostListener, Input, ElementRef} from '@angular/core';
import {HelperService} from '../../../../helper.service';
import {AuthService} from '../../../../auth/auth.service';

@Directive({
    selector: '[profileViewerTrigger]'
})
export class ProfileViewerTriggerDirectiveDirective {
    constructor(private el: ElementRef, private _helper: HelperService, private _auth: AuthService) {
    }

    @Input() profile;

    @HostListener('click')
    onClick(): void {
        if (this.profile && this.profile.uid && this.profile.uid !== 'system' && this._auth.isLoggedIn && this._auth.userProfileSnapshot.uid) {
            this._helper.setProfileViewerProfile(this.profile);
            this._helper.setProfileViewerTrigger(this.el, true);
        }
    }
}
