import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthService} from '../../../auth/auth.service';
import {NotificationService} from '../../../notifications/notification.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AngularFirestore} from "@angular/fire/firestore";
import {HelperService} from "../../../helper.service";
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import Swal from "sweetalert2";
import {FuseSidebarService} from "../../../../@fuse/components/sidebar/sidebar.service";

@Component({
    selector: 'notification-panel',
    templateUrl: './notification-panel.component.html',
    styleUrls: ['./notification-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationPanelComponent implements OnInit, OnDestroy {
    date: Date;
    notifications: any;
    notificationCount = 0;

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */
    constructor(private _auth: AuthService,
                private _messagingService: NotificationService,
                private _firestore: AngularFirestore,
                private _fuseSidebarService: FuseSidebarService,
                public _helper: HelperService) {
        // Set the defaults
        this._unsubscribeAll = new Subject();
        this.date = new Date();
    }

    ngOnInit(): void {
        this._messagingService.areNotificationsReady()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(isReady => {
                if (isReady) {
                    this.notifications = this._messagingService.notificationsSnapshot;
                    this.notificationCount = Object.keys(this.notifications).length;
                } else {
                    this.notifications = null;
                    this.notificationCount = 0;
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    clearAndNavigate(key, value): void {
        this.clearNotification(key);
        this._helper.navigateToPath(value.notification.click_action);
        this._fuseSidebarService.getSidebar('notificationPanel').toggleOpen();
    }

    clearAllNotifications(): void {
        Swal.fire({
            title: 'Clear all notifications?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                this._firestore.doc<any>('notifications/' + this._auth.userAccessSnapshot.uid).set({});
            }
        });

    }

    clearNotification(key): void {
        this._firestore.doc<any>('notifications/' + this._auth.userAccessSnapshot.uid).update({
            [key]: firebase.firestore.FieldValue.delete()
        });
    }
}
