import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef, EventEmitter,
    Input,
    OnDestroy,
    OnInit, Output,
    Renderer2, ViewChild,
    ViewEncapsulation
} from '@angular/core';

import {fuseAnimations} from '@fuse/animations';

import {Subject} from 'rxjs';
import Swal from 'sweetalert2/src/sweetalert2.js';
import {firestore} from 'firebase';
import {HelperService} from '../../../helper.service';
import {NgTippyService} from 'angular-tippy';
import {MatMenuTrigger} from '@angular/material/menu';
import {ManualChangeDetection} from '../../../change-detection.service';

@Component({
    selector: 'html-viewer',
    templateUrl: './html-viewer.component.html',
    styleUrls: ['./html-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlViewerComponent implements AfterViewInit {
    private manualChangeDetection: ManualChangeDetection;

    @Input()
    content;
    @Input()
    emojiFontSize = 19;
    @Input()
    emojiSheetSize: 16 | 20 | 32 | 64 = 64;
    @Input()
    emojiSheet: '' | 'apple' | 'facebook' | 'twitter' | 'emojione' | 'google' | 'messenger' = 'google';

    showMessageHelper = false;
    member;

    @ViewChild('contents') contents: ElementRef;

    constructor(
        public _helper: HelperService,
        public _tippy: NgTippyService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.manualChangeDetection = new ManualChangeDetection(changeDetectorRef);
    }

    ngAfterViewInit(): void {
        const mentionList = this.contents.nativeElement.querySelectorAll('.mention');
        if (mentionList.length) {
            mentionList.forEach((mention) => {

                const mentionRef = new ElementRef(mention);
                const mentionChildRef = new ElementRef(mention.children[0]);
                // mention.addEventListener('mouseover', () => {
                //
                // });
                const item = this._helper.getMemberInfo(mention['dataset'].id);

                if (!this._helper.isMobile()) {
                    this._tippy.init(mentionChildRef, {
                        content: '<div class="mention-list-item"><img class="avatar" src="' + (item.avatar || '/assets/images/avatars/profile.png') +
                            '"/><div class="name text-truncate">' + item.name + '</div><div class="info secondary-text text-truncate">' +
                            (item.programInfo && item.programInfo.program ? item.programInfo.program + (item.programInfo.role ? ' ' + item.programInfo.role : '')
                                + ' | ' : (item.programInfo && item.programInfo.role ? item.programInfo.role + ' | ' : '')) +
                            (item.programInfo && item.programInfo.city ? item.programInfo.city : '') + '</div></div>',
                        allowHTML: true
                    });
                }

                mention.addEventListener('click', () => {
                    this._helper.setProfileViewerProfile(item);
                    this._helper.setProfileViewerTrigger(mentionRef, true);
                });
            });
        }

        const emojiList = this.contents.nativeElement.querySelectorAll('.emoji-pipe-image');
        if (emojiList.length) {
            emojiList.forEach(emoji => {
                const emojiRef = new ElementRef(emoji);
                emoji.addEventListener('mouseover', () => {
                    if (emoji['dataset'] && emoji['dataset'].id) {
                        this._tippy.init(emojiRef, {
                            content: ':' + emoji['dataset'].id.toString() + ':'
                        });
                    }
                });
            });
        }

    }
}
