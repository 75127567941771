import {fuseAnimations} from '../../../../@fuse/animations';
import {Component, Input, ViewEncapsulation} from '@angular/core';
import {HelperService} from '../../../helper.service';
import {MatDialog} from '@angular/material/dialog';
import {RequestBraindateDialogComponent} from '../request-braindate-dialog/request-braindate.component';
import {ChatService} from '../../../chat/chat.service';
import {AuthService} from '../../../auth/auth.service';

@Component({
    selector: 'profile-viewer',
    templateUrl: './profile-viewer.component.html',
    styleUrls: ['./profile-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ProfileViewerComponent{
    @Input()
    profile;

    @Input()
    handler;

    constructor(
        public _helper: HelperService,
        public _auth: AuthService,
        public _matDialog: MatDialog,
        private _chat: ChatService
    ) {

    }

    message(): void {
        this._chat.createOrGetChat([this.profile.uid]).then(chatId => {
            this.handler.hide();
            this._helper.navigateToChat(chatId);
        });
    }

    closeHandlerThenNavigateToUser(): void {
        this.handler.hide();
        this._helper.navigateToUser(this.profile.uid);
    }

    requestBraindate(): void {
        this.handler.hide();
        this._matDialog.open(RequestBraindateDialogComponent, {
            disableClose: true,
            panelClass: 'braindate-request-dialog',
            data: {
                requestee: this.profile
            }
        });
    }

    goToStudentDashboard(id): void {
        this.handler.hide();
        this._helper.navigateToPath('/director/dashboard/' + id);
    }
}
